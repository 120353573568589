import { SET_PRIMARY_COLOR, SET_SECONDARY_COLOR, SET_FONT, SET_LOGO, RESET_THEME, SET_INITIAL_THEME, SET_LANGUAGE, SET_VENDOR_NAME, SET_TEXT_SECONDARY_COLOR, SET_PRODUCTS_ORDER, SET_ORDER_BUTTON_LABEL, SET_ORDER_BUTTON_ACTION } from "./settingsActionTypes";
import * as _ from "lodash"
import { defaultFonts, defaultLogo, defaultPrimaryColor, defaultSecondaryColor, defaultTextSecondaryColor } from "../../../theme";

interface Theme {
    primaryColor: string
    secondaryColor: string
    textSecondaryColor: string
    font: string
    logo: string,
    language: string,
    vendorName: string
    orderButtonLabel: string
    orderButtonAction: string
}

export interface GeneralState {
    initialTheme?: Theme
    theme?: Theme
    productsOrder: string
}

const initialState: GeneralState = {
    theme: {
        primaryColor: defaultPrimaryColor,
        secondaryColor: defaultSecondaryColor,
        textSecondaryColor: defaultTextSecondaryColor,
        font: defaultFonts,
        logo: defaultLogo,
        language: "en",
        vendorName: "ArDealy",
        orderButtonLabel: "Go to product",
        orderButtonAction: "link"
    },
    productsOrder: "DATE+1"
};

const settingsTempReducer = (state = initialState, action): GeneralState => {
    switch (action.type) {
        case SET_INITIAL_THEME:
            return {
                ...state,
                initialTheme: { ...state.theme }
            }
        case SET_TEXT_SECONDARY_COLOR:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    textSecondaryColor: action.payload
                }
            }
        case SET_PRIMARY_COLOR:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    primaryColor: action.payload
                }
            }
        case SET_SECONDARY_COLOR:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    secondaryColor: action.payload
                }
            }
        case SET_FONT:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    font: action.payload
                }
            }
        case SET_LOGO:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    logo: action.payload
                }
            }
        case SET_LANGUAGE:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    language: action.payload
                }
            }
        case SET_VENDOR_NAME:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    vendorName: action.payload
                }
            }
        case RESET_THEME:
            return {
                ...state,
                theme: { ...state.initialTheme }
            }
        case SET_PRODUCTS_ORDER:
            return {
                ...state,
                productsOrder: action.payload
            }
        case SET_ORDER_BUTTON_LABEL:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    orderButtonLabel: action.payload
                }
            }
        case SET_ORDER_BUTTON_ACTION:
            return {
                ...state,
                theme: {
                    ...state.theme,
                    orderButtonAction: action.payload
                }
            }
        default:
            return state
    }
};

export default settingsTempReducer;
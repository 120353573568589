export class HexagonalRequirements {
    files
    lengthL1
    lengthL2
    lengthL3
    textureWidth
    textureHeight
    groutLineWidth
    groutLineColor

    setFiles(files){
        this.files = files
        return this
    }

    setLengthL1(lengthL1){
        this.lengthL1 = lengthL1
        return this
    }

    setLengthL2(lengthL2){
        this.lengthL2 = lengthL2
        return this
    }

    setLengthL3(lengthL3){
        this.lengthL3 = lengthL3
        return this
    }

    setTextureWidth(textureWidth){
        this.textureWidth = textureWidth
        return this
    }

    setTextureHeight(textureHeight){
        this.textureHeight = textureHeight
        return this
    }

    setGroutLineWidth(groutWidth){
        this.groutLineWidth = groutWidth
        return this
    }

    setGroutLineColor(color){
        this.groutLineColor = color
        return this
    }
}
import { Download } from "@mui/icons-material"
import { Box, Chip, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { base64ToBlob, getBase64 } from "../../../../../common/utils/utils"
import UploadIcon from "../../../../../assets/icons/upload-icon.png"
import LoadingImage from "../../../../../assets/loader.gif"
import translate from "translate";
import * as _ from "lodash"
import { ImageDialog } from "../../components/step-3/upload-methods/product-images.component"
import { useTranslation } from "react-i18next"
import { useTheme } from "@emotion/react"
import { EditProduct } from "../../models/edit-product.model"
import { ProductVariant } from "../../models/variant.model"
import FadeIn from "react-fade-in/lib/FadeIn"
import { extractColors } from "extract-colors"
import Cropper from 'react-easy-crop';
import { Slider } from "@mui/material";
import { PhotoCamera } from '@mui/icons-material';
import HeightIcon from '@mui/icons-material/Height';
import WidthIcon from '@mui/icons-material/Height'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ColorLens, Straighten, Texture } from "@mui/icons-material";

interface VariantRepresentationProps {
    editProduct: EditProduct,
    variant: ProductVariant,
    activeStep: number,
    setEditProduct,
    elRefs
}

export const VariantRepresentation = (props: VariantRepresentationProps) => {
    const { editProduct, variant, setEditProduct, elRefs, activeStep } = props
    const [t] = useTranslation()
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>()
    const [isLoading, setIsLoading] = useState<Boolean>(false)

    const [link, setLink] = useState(variant.getLink() || '');
    const [addToCartLink, setAddToCartLink] = useState(variant.getAddToCartLink() || '');
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const handleLinkChange = (value, id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id);
        if (selectedVariantIndex === -1) {
            console.error(`Variant with id ${id} not found.`);
            return;
        }

        editProduct.variants[selectedVariantIndex].setLink(value);
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)));
    };

    const handleAddToCartLinkChange = (value, id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id);
        if (selectedVariantIndex === -1) {
            console.error(`Variant with id ${id} not found.`);
            return;
        }

        editProduct.variants[selectedVariantIndex].setAddToCartLink(value);
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)));
    };

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const handleCropChange = (newCrop) => {
        setCrop(newCrop);
        variant.setCrop(newCrop);
    };

    const handleZoomChange = (newZoom) => {
        setZoom(newZoom);
        variant.setZoom(newZoom);
    };

    const lastUpdateTimeRef = useRef(0);
    const throttleTimeout = 100;

    const [fields, setFields] = useState({
        sku: {
            isTouched: false,
            label: `${t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SKU")}*`,
            name: "sku"
        },
        seamlessHeight: {
            isTouched: false,
            label: `${t("PATTERNS.PARTIALS.TEXTURE_HEIGHT")}*`,
            name: "seamlessHeight"
        },
        seamlessWidth: {
            isTouched: false,
            label: `${t("PATTERNS.PARTIALS.TEXTURE_WIDTH")}*`,
            name: "seamlessWidth"
        }
    })

    const handleImageChange = async (event, id) => {
        const files = event.target.files;
        if (files.length === 0) {
            console.error("No file selected.");
            return;
        }

        try {
            const path: string = await getBase64(files[0]);
            const base64: string = path.split(',')[1];

            const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id);
            if (selectedVariantIndex === -1) {
                console.error(`Variant with id ${id} not found.`);
                return;
            }

            editProduct.variants[selectedVariantIndex].setSeamless64URL(base64);
            editProduct.variants[selectedVariantIndex].setImageChanged(true);

            const img = new Image();
            img.src = `data:image/jpeg;base64,${base64}`;
            img.onload = () => {
                const originalWidth = img.naturalWidth;
                const originalHeight = img.naturalHeight;

                setCroppedAreaPixels({
                    x: 0,
                    y: 0,
                    width: originalWidth,
                    height: originalHeight,
                });

                setZoom(1);
                setCrop({ x: 0, y: 0 });

                setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)));

                createThumbnail();
                getSeamlessColors();
            };
        } catch (error) {
            console.error("Error handling image change:", error);
        }
    };

    const handleEditClick = (event, fileInputRef) => {
        handleOnProductClick(variant.getId())
        event.stopPropagation()
        fileInputRef.current.focus();
        fileInputRef.current.click();
    };

    const handleOnProductClick = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setIsSelected(!editProduct.variants[selectedVariantIndex].getIsSelected())
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const handleSeamlessColorsChange = (value, id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id);

        if (selectedVariantIndex === -1) {
            console.error(`Variant with id ${id} not found.`);
            return;
        }

        editProduct.variants[selectedVariantIndex].setProductColors(value);

        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)));
    };

    const handleSKUChange = (value, id) => {
        setFields({
            ...fields, sku: {
                ...fields.sku,
                isTouched: true
            }
        })
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        editProduct.variants[selectedVariantIndex].setSku(value)
        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
    }

    const handleSeamlessWidthChange = (value, id) => {
        setFields({
            ...fields, seamlessWidth: {
                ...fields.seamlessWidth,
                isTouched: true
            }
        })
        const reg = new RegExp('^$|^[0-9]+(\.[0-9]{0,2})?$')
        if (reg.test(value)) {
            const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
            editProduct.variants[selectedVariantIndex].setSeamlessWidth(value)
            setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
        }
    }

    const handleSeamlessHeightChange = (value, id) => {
        setFields({
            ...fields, seamlessHeight: {
                ...fields.seamlessHeight,
                isTouched: true
            }
        })
        const reg = new RegExp('^$|^[0-9]+(\.[0-9]{0,2})?$')
        if (reg.test(value)) {
            const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
            editProduct.variants[selectedVariantIndex].setSeamlessHeight(value)
            setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)))
        }
    }

    const isNotValidSKU = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.sku.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSku() === "" || !editProduct.variants[selectedVariantIndex].getSku())
    }
    const isNotValidSeamlessWidth = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.seamlessWidth.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSeamlessWidth() === "0" || !editProduct.variants[selectedVariantIndex].getSeamlessWidth())
    }
    const isNotValidSeamlessHeight = (id) => {
        const selectedVariantIndex = editProduct.variants.findIndex(variant => variant.getId() === id)
        return fields.seamlessHeight.isTouched && editProduct.variants[selectedVariantIndex].getIsSelected() && (editProduct.variants[selectedVariantIndex].getSeamlessHeight() === "0" || !editProduct.variants[selectedVariantIndex].getSeamlessHeight())
    }

    const closeDialog = () => {
        setIsOpen(false)
    }

    const getSeamlessColors = () => {
        translate.engine = "google";

        const seamless64URL = variant.getSeamless64URL();

        if (!seamless64URL) {
            console.error("Invalid base64 URL: The input is null or undefined.");
            return;
        }

        const reader = new FileReader();
        reader.onload = async () => {
            const imageUrl = reader.result;
            const colorsArray = await extractColors(imageUrl as string, { distance: 0.2, saturationDistance: 0.5, lightnessDistance: 0.1 });
            const hexCodes = await Promise.all(colorsArray.map(async (e) => e.hex));
            handleSeamlessColorsChange(hexCodes, variant.getId());
        };

        try {
            reader.readAsDataURL(base64ToBlob(seamless64URL));
        } catch (error) {
            console.error("Error while converting base64 to Blob:", error);
        }
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        if (!croppedAreaPixels) {
            console.error("Cropped area pixels are not valid.");
            return;
        }

        const currentTime = Date.now();

        if (currentTime - lastUpdateTimeRef.current > throttleTimeout) {
            lastUpdateTimeRef.current = currentTime;

            setCroppedAreaPixels(croppedAreaPixels);

            variant.setCrop(crop);
            variant.setZoom(zoom);

            createThumbnail();
        }
    };

    const createThumbnail = async () => {
        try {
            let area = croppedAreaPixels;

            if (!croppedAreaPixels) {
                const img = new Image();
                img.src = `data:image/jpeg;base64,${variant.getSeamless64URL()}`;

                await new Promise<void>((resolve, reject) => {
                    img.onload = () => {
                        area = {
                            x: 0,
                            y: 0,
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                        };
                        resolve();
                    };
                    img.onerror = (error) => {
                        console.error("Failed to load image for full dimensions.", error);
                        reject(error);
                    };
                });
            }

            if (area) {
                const croppedImage = await getCroppedImage(variant.getSeamless64URL(), area);
                if (croppedImage) {
                    const selectedVariantIndex = editProduct.variants.findIndex(v => v.getId() === variant.getId());
                    if (selectedVariantIndex !== -1) {
                        editProduct.variants[selectedVariantIndex].setThumbnail64URL(croppedImage as string);
                        editProduct.variants[selectedVariantIndex].setThumbnailChanged(true);
                        setEditProduct(_.cloneDeep(editProduct.setVariants(editProduct.variants)));
                    } else {
                        console.error("Variant index not found during createThumbnail.");
                    }
                } else {
                    console.error('Failed to create cropped image.');
                }
            } else {
                console.error('No valid cropped area found.');
            }
        } catch (error) {
            console.error('Failed to create thumbnail:', error);
        }
    };

    useEffect(() => {
        if (variant.getSeamless64URL()) {
            const img = new Image();
            img.src = `data:image/jpeg;base64,${variant.getSeamless64URL()}`;
            img.onload = () => {
                setCroppedAreaPixels({
                    x: 0,
                    y: 0,
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                });
            };
        }
    }, [variant.getSeamless64URL()]);

    useEffect(() => {
        if (croppedAreaPixels) {
            createThumbnail();
        }
    }, [croppedAreaPixels]);

    useEffect(() => {
        if (variant) {
            setCrop(variant.getCrop() || { x: 0, y: 0 });
            setZoom(variant.getZoom() || 1);
        }
    }, [variant]);

    return <Grid item xs={12} sm={12} md={6} >
        <Box display={"flex"} bgcolor={"primary.main"} borderRadius={2} sx={{ cursor: "pointer", border: variant.getIsSelected() ? "2px solid black" : "", borderColor: "secondary.main", position: "relative" }} onClick={() => handleOnProductClick(variant.getId())}>
            {variant.getIsSelected() && <Typography fontSize={12} color={"white"} letterSpacing={0} position={"absolute"} right={0} top={0} bgcolor={"secondary.main"} paddingInline={2} paddingBlock={0.5} sx={{ borderTopRightRadius: 2 }}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.MESSAGES.SELECTED")}</Typography>}

            <Box padding={1} display={"flex"}>
                <Box display={"flex"} flex={0} flexDirection={"column"} marginRight={1}>{variant.getProductColors().map((hex, index) => (
                    <Box bgcolor={hex} width={"20px"} height={"20px"} key={index}></Box>
                ))}</Box>

                {isLoading && <Box zIndex={10000}
                    display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                    sx={{ userSelect: "none" }} borderRadius={"5px"}
                    width={"120px"} height={"120px"} bgcolor={"#00000060"} top={0} left={0}>
                    <img src={LoadingImage} width={"30px"} />
                    <Typography fontSize={14} letterSpacing={-0.5}
                        color={"primary.light"} fontWeight={"bolder"} textTransform={'capitalize'}>Generating...</Typography>
                </Box>}

                {!isLoading && variant.getSeamless64URL() && (
                    <FadeIn>
                        <Box position="relative" width="150px" margin="auto">
                            {/* Cropper */}
                            <Box width="100%" height="150px" position="relative" onClick={(e) => e.stopPropagation()}>
                                <Cropper
                                    image={`data:image/png;base64,${variant.getSeamless64URL()}`}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    showGrid={false}
                                    maxZoom={10}
                                    onCropChange={handleCropChange}
                                    onZoomChange={handleZoomChange}
                                    onCropComplete={onCropComplete}
                                    onInteractionEnd={() => onCropComplete(null, croppedAreaPixels)}
                                />

                                {/* Change Image Icon Button */}
                                <IconButton
                                    component="label"
                                    sx={{
                                        position: 'absolute',
                                        top: 6,
                                        right: 6,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                        zIndex: 10,
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                    }}
                                    size="small"
                                >
                                    <PhotoCamera fontSize="small" />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={(event) => handleImageChange(event, variant.getId())}
                                    />
                                </IconButton>

                                {/* Download Icon Button */}
                                <IconButton
                                    component="a"
                                    href={variant.getSeamless64URL() ? `data:image/jpeg;base64,${variant.getSeamless64URL()}` : undefined}
                                    download="seamless_image.jpg"
                                    sx={{
                                        position: 'absolute',
                                        top: 'calc(2px + 32px + 6px)',
                                        right: 6,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                        zIndex: 10,
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        },
                                        pointerEvents: variant.getSeamless64URL() ? 'auto' : 'none',
                                        opacity: variant.getSeamless64URL() ? 1 : 0.5,
                                    }}
                                    size="small"
                                >
                                    <Download fontSize="small" />
                                </IconButton>
                            </Box>

                            {/* Slider */}
                            <Box marginTop={2} marginRight={1} marginLeft={1}>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={10}
                                    step={0.1}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e, newZoom) => setZoom(newZoom as number)}
                                    onChangeCommitted={() => {
                                        onCropComplete(null, croppedAreaPixels);
                                    }}
                                    color="primary"
                                    sx={{
                                        color: '#808080',
                                        '& .MuiSlider-thumb': {
                                            backgroundColor: 'secondary.main',
                                        },
                                        '& .MuiSlider-track': {
                                            backgroundColor: 'secondary.main',
                                            boxShadow: 'none',
                                            border: 'none',
                                        },
                                        '& .MuiSlider-rail': {
                                            opacity: 0.3,
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </FadeIn>
                )}

                {!isLoading && !variant.getSeamless64URL() && <Box zIndex={10000}
                    display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                    sx={{ userSelect: "none" }} borderRadius={"5px"}
                    onClick={(event) => handleEditClick(event, elRefs[editProduct.variants.findIndex(v => v === variant)])}
                    width={"120px"} height={"120px"} bgcolor={"#00000060"} top={0} left={0}>
                    <img src={UploadIcon} style={{
                        width: "30px", padding: "10px",
                        //@ts-ignore
                        filter: (theme.palette.mode !== 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)" : ""
                    }}
                    />
                    <input
                        type="file"
                        onChange={(event) => handleImageChange(event, variant.getId())}
                        multiple
                        accept="image/*"
                        ref={elRefs[editProduct.variants.findIndex(v => v === variant)]}
                        style={{ display: 'none' }}
                    />
                    <Typography fontSize={14} letterSpacing={-0.5} textAlign={"center"} marginTop={-1.5}
                        color={"primary.light"} fontWeight={"bolder"} textTransform={'capitalize'}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.LABELS.UPLOAD_SEAMLESS")}</Typography>
                </Box>
                }
            </Box>

            <Box padding={1}>
                <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} textTransform={'capitalize'} marginRight={3}>{editProduct.brand} </Typography>
                <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} marginBlock={0.2}>{variant.getTitle()}</Typography>

                <Box display="flex" flexWrap="wrap" gap={1} marginBottom={2} marginTop={1}>
                    {variant.getColor() !== "default" && (
                        <Chip
                            label={variant.getColor()}
                            icon={<ColorLens />}
                            sx={{ backgroundColor: '#f0f2f6', textTransform: 'capitalize' }}
                            size="small"
                        />
                    )}
                    {variant.getSize() && (
                        <Chip
                            label={`${variant.getSize().width}${variant.getSize().unit} x ${variant.getSize().height}${variant.getSize().unit}`}
                            icon={<Straighten />}
                            sx={{ backgroundColor: '#f0f2f6' }}
                            size="small"
                        />
                    )}
                    {variant.getPattern() && (
                        <Chip
                            label={variant.getPattern()}
                            icon={<Texture />}
                            sx={{ backgroundColor: '#f0f2f6', textTransform: 'capitalize' }}
                            size="small"
                        />
                    )}
                </Box>

                <Divider sx={{ my: 2, bgcolor: "black", opacity: "5%", marginLeft: 4, marginRight: 4 }} />

                {/* SKU, Seamless Height, and Width inputs */}
                <Box>
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <Typography
                                        fontSize={12}
                                        color={"primary.text"}
                                        letterSpacing={0}
                                        fontWeight={"bold"}
                                        textTransform={'capitalize'}
                                        marginRight={3}
                                    >
                                        {fields.sku.label}
                                    </Typography>
                                </td>
                                <td>
                                    <TextField
                                        error={isNotValidSKU(variant.getId())}
                                        name="SKU"
                                        fullWidth
                                        size='small'
                                        color='secondary'
                                        autoComplete='off'
                                        value={variant.getSku() || ''}
                                        onChange={(e) => handleSKUChange(e.target.value, variant.getId())}
                                        onClick={(e) => e.stopPropagation()}
                                        InputProps={{
                                            sx: { backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "150px" }
                                        }}
                                        helperText={isNotValidSKU(variant.getId()) ? "SKU is required" : ""}
                                        inputProps={{
                                            style: {
                                                padding: 0,
                                                paddingLeft: 4,
                                                border: "0",
                                                borderRadius: 0
                                            }
                                        }}
                                        FormHelperTextProps={{ style: { marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                                    />
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Box>

                <Box display="flex" alignItems="center" gap={2} flexWrap="wrap" marginTop={2}>
                    <Box display="flex" alignItems="center" gap={1} flex={1}>
                        <HeightIcon sx={{ color: 'primary.text' }} />
                        <TextField
                            error={isNotValidSeamlessHeight(variant.getId())}
                            name="seamlessHeight"
                            fullWidth
                            size='small'
                            color='secondary'
                            autoComplete='off'
                            type="string"
                            value={variant.getSeamlessHeight() || ''}
                            onChange={(e) => handleSeamlessHeightChange(e.target.value, variant.getId())}
                            onClick={(e) => e.stopPropagation()}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} >m</Typography>
                                </InputAdornment>,
                                sx: { backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "100px" }
                            }}
                            helperText={isNotValidSeamlessHeight(variant.getId()) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.FIELD_IS_REQUIRED") : ""}
                            inputProps={{
                                style: {
                                    padding: 0,
                                    paddingLeft: 4,
                                    border: "0",
                                    borderRadius: 0
                                }
                            }}
                            FormHelperTextProps={{ style: { marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                        />
                    </Box>

                    <Box display="flex" alignItems="center" gap={1} flex={1}>
                        <WidthIcon sx={{ color: 'primary.text', transform: 'rotate(90deg)' }} />
                        <TextField
                            error={isNotValidSeamlessWidth(variant.getId())}
                            name="seamlessWidth"
                            fullWidth
                            size='small'
                            color='secondary'
                            autoComplete='off'
                            type="string"
                            value={variant.getSeamlessWidth() || ''}
                            onChange={(e) => handleSeamlessWidthChange(e.target.value, variant.getId())}
                            onClick={(e) => e.stopPropagation()}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Typography fontSize={12} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} >m</Typography>
                                </InputAdornment>,
                                sx: { backgroundColor: "primary.main", fontSize: "9pt", color: "primary.contrastText", width: "100px" }
                            }}
                            helperText={isNotValidSeamlessWidth(variant.getId()) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.FIELD_IS_REQUIRED") : ""}
                            inputProps={{
                                style: {
                                    padding: 0,
                                    paddingLeft: 4,
                                    border: "0",
                                    borderRadius: 0,
                                }
                            }}
                            FormHelperTextProps={{ style: { marginTop: 0, marginLeft: 0, fontSize: "8pt", color: "primary.contrastText" } }}
                        />
                    </Box>
                </Box>

                <Box marginTop={1}>
                    <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ boxShadow: 'none', marginTop: 0 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="links-content"
                            id="links-header"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Typography>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.LABELS.UPDATE_LINKS")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display="flex" flexDirection="column" gap={2}>
                                <TextField
                                    label={t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.LINK")}
                                    fullWidth
                                    size='small'
                                    color='secondary'
                                    autoComplete='off'
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value);
                                        handleLinkChange(e.target.value, variant.getId());
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: "primary.main",
                                            fontSize: "9pt",
                                            color: "primary.contrastText"
                                        }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        style: {
                                            padding: 8,
                                            border: "0",
                                            borderRadius: 0
                                        }
                                    }}
                                />
                                <TextField
                                    label={t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.ADD_TO_CART")}
                                    fullWidth
                                    size='small'
                                    color='secondary'
                                    autoComplete='off'
                                    value={addToCartLink}
                                    onChange={(e) => {
                                        setAddToCartLink(e.target.value);
                                        handleAddToCartLinkChange(e.target.value, variant.getId());
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    InputProps={{
                                        sx: {
                                            backgroundColor: "primary.main",
                                            fontSize: "9pt",
                                            color: "primary.contrastText"
                                        }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        style: {
                                            padding: 8,
                                            border: "0",
                                            borderRadius: 0
                                        }
                                    }}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </Box>

        <ImageDialog base64Image={selectedImage} image={null} isOpen={isOpen} closeDialog={closeDialog} />
    </Grid>
}

const ensureBase64Prefix = (base64String) => {
    if (!base64String.startsWith('data:image')) {
        return `data:image/jpeg;base64,${base64String}`;
    }
    return base64String;
};

const getCroppedImage = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = ensureBase64Prefix(imageSrc);

        image.onload = () => {
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            const cropWidth = crop.width * scaleX;
            const cropHeight = crop.height * scaleY;

            const aspectRatio = cropWidth / cropHeight;
            let finalWidth = cropWidth;
            let finalHeight = cropHeight;

            if (cropWidth > cropHeight) {
                finalWidth = Math.min(350, cropWidth);
                finalHeight = finalWidth / aspectRatio;
            } else {
                finalHeight = Math.min(350, cropHeight);
                finalWidth = finalHeight * aspectRatio;
            }

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = finalWidth;
            canvas.height = finalHeight;

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                finalWidth,
                finalHeight
            );

            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.onerror = (error) => {
            console.error("Image load failed", error);
            reject(new Error("Image load failed"));
        };
    });
};
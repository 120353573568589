import { imageUrlToBase64, logAccess } from "../../../../common/utils/utils";
import { APPLICATION_TYPE, Product, Size } from "../../my-products/models/product.model"
import { v4 as uuidv4 } from 'uuid';

export class ProductVariant {
  private _id: string;
  private _title: string;
  private _link: string;
  private _addToCartLink: string;
  private _size: Size;
  private _pattern: string;
  private _color: string;
  private _isDefault: boolean;
  private _isSelected: boolean;
  private _isSeamless: boolean;
  private _image: string;
  private _sku: string;
  private _productId?: string
  private _productUuid?: string
  private _seamless64Url?: string
  private _seamlessHeight?: string
  private _seamlessWidth?: string
  private _httpImageUrl?: string
  private _brand?: string
  private _application_types: APPLICATION_TYPE[]
  private _type: string
  private _productColors: string[]
  private _collections: string[];

  private _status?: string

  private isImageChanged: boolean = false
  private isThumbnailChanged: boolean = false

  private _thumbnail64Url?: string;

  private _crop?: { x: number; y: number } = { x: 0, y: 0 };
  private _zoom?: number = 1;

  public async mapExistingProductVariant(product: Product, isSelected: boolean) {
    const base64Image = await imageUrlToBase64(product.visualization?.data[0].materialPath);
    const thumbnailBase64Image = await imageUrlToBase64(product.thumbnailPath);

    return this.setId(uuidv4())
      .setTitle(product.name)
      .setLink(product.link)
      .setAddToCartLink(product.addToCartLink)
      .setSize(product.length, product.width, product.unit)
      .setPattern(product.pattern ?? "bricks")
      .setColor(product.color ?? "default")
      .setProductId(product.id)
      .setProductUuid(product.uuid)
      .setIsDefault(product.isDefault)
      .setIsSeamless(true)
      .setSku(product.sku)
      .setHttpImageURL(product.visualization?.data[0].materialPath)
      .setIsSelected(isSelected)
      .setSeamless64URL(base64Image)
      .setThumbnail64URL(thumbnailBase64Image)
      .setBrand(product.brand)
      .setApplicationTypes(product.application_types)
      .setType(product.type)
      .setSeamlessHeight(product.visualization?.data[0].height)
      .setSeamlessWidth(product.visualization?.data[0].width)
      .setProductColors(product.specifics?.colors ?? [])
      .setCollections(product.specifics?.collections ?? [])
  }

  public get status(): string | undefined {
    return this._status;
  }

  @logAccess
  public setStatus(value: string): this {
    this._status = value;
    return this;
  }

  public getIsImageChanged(): boolean {
    return this.isImageChanged
  }

  public setImageChanged(value: boolean): this {
    this.isImageChanged = value
    return this
  }

  public getIsThumbnailChanged(): boolean {
    return this.isThumbnailChanged
  }

  public setThumbnailChanged(value: boolean): this {
    this.isThumbnailChanged = value
    return this
  }

  public getSeamlessHeight(): string | undefined {
    return this._seamlessHeight;
  }

  @logAccess
  public setSeamlessHeight(seamlessHeight): this {
    this._seamlessHeight = seamlessHeight;
    return this;
  }

  public getSeamlessWidth(): string | undefined {
    return this._seamlessWidth;
  }

  @logAccess
  public setSeamlessWidth(seamlessWidth): this {
    this._seamlessWidth = seamlessWidth;
    return this;
  }

  public getSeamless64URL(): string | undefined {
    return this._seamless64Url;
  }

  @logAccess
  public setSeamless64URL(URL): this {
    this._seamless64Url = URL;
    return this;
  }

  public getThumbnail64URL(): string | undefined {
    return this._thumbnail64Url;
  }

  @logAccess
  public setThumbnail64URL(URL: string): this {
    this._thumbnail64Url = URL;
    this.setThumbnailChanged(true)
    return this;
  }

  public getProductColors(): string[] | undefined {
    return this._productColors ?? []
  }

  @logAccess
  public setProductColors(colors): this {
    this._productColors = colors;
    return this;
  }

  public getCollections(): string[] | undefined {
    return this._collections ?? []
  }

  @logAccess
  public setCollections(collections): this {
    this._collections = collections;
    return this;
  }

  public getLink(): string | undefined {
    return this._link ?? ''
  }

  @logAccess
  public setLink(link): this {
    this._link = link;
    return this;
  }

  public getAddToCartLink(): string | undefined {
    return this._addToCartLink ?? ''
  }

  @logAccess
  public setAddToCartLink(link): this {
    this._addToCartLink = link;
    return this;
  }

  public getHttpImageURL(): string | undefined {
    return this._httpImageUrl;
  }

  @logAccess
  public setHttpImageURL(URL): this {
    this._httpImageUrl = URL;
    return this;
  }

  public get productId(): string | undefined {
    return this._productId;
  }

  @logAccess
  public setProductId(productId): this {
    this._productId = productId;
    return this;
  }


  public get productUuid(): string | undefined {
    return this._productUuid;
  }

  @logAccess
  public setProductUuid(productUuid): this {
    this._productUuid = productUuid;
    return this;
  }

  public getId(): string {
    return this._id;
  }

  @logAccess
  public setId(id: string): this {
    this._id = id;
    return this;
  }

  public getTitle(): string {
    return this._title;
  }

  @logAccess
  public setTitle(title: string): this {
    this._title = title;
    return this;
  }

  public getSize(): Size {
    return this._size;
  }

  @logAccess
  public setApplicationTypes(application_types: APPLICATION_TYPE[]): this {
    this._application_types = application_types;
    return this;
  }

  public getApplicationTypes(): APPLICATION_TYPE[] {
    return this._application_types;
  }

  @logAccess
  public setBrand(brand: string): this {
    this._brand = brand;
    return this;
  }

  public getBrand(): string {
    return this._brand;
  }

  @logAccess
  public setType(type: string): this {
    this._type = type;
    return this;
  }

  public getType(): string {
    return this._type;
  }

  @logAccess
  public setSize(height: number, width: number, unit: string): this {
    const size = new Size()
    this._size = size.setHeight(height).setWidth(width);
    return this;
  }

  public getPattern(): string {
    return this._pattern;
  }

  @logAccess
  public setPattern(pattern: string): this {
    this._pattern = pattern;
    return this;
  }

  public getColor(): string {
    return this._color;
  }

  @logAccess
  public setColor(color: string): this {
    this._color = color;
    return this;
  }

  public getIsDefault(): boolean {
    return this._isDefault;
  }

  @logAccess
  public setIsDefault(isDefault: boolean): this {
    this._isDefault = isDefault;
    return this;
  }

  public getIsSelected(): boolean {
    return this._isSelected;
  }

  @logAccess
  public setIsSelected(isSelected: boolean): this {
    this._isSelected = isSelected;
    return this;
  }

  public getIsSeamless(): boolean {
    return this._isSeamless;
  }

  @logAccess
  public setIsSeamless(isSeamless: boolean): this {
    this._isSeamless = isSeamless;
    return this;
  }

  public getImage(): string {
    return this._image;
  }

  @logAccess
  public setImage(image: string): this {
    this._image = image;
    return this;
  }

  public getSku(): string {
    return this._sku;
  }

  @logAccess
  public setSku(sku: string): this {
    this._sku = sku;
    return this;
  }

  public getCrop() {
    return this._crop;
  }
  public setCrop(crop: { x: number; y: number }) {
    this._crop = crop;
  }

  public getZoom() {
    return this._zoom;
  }
  public setZoom(zoom: number) {
    this._zoom = zoom;
  }
}
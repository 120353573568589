import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';

import { useNavigate, useParams } from 'react-router-dom';
import * as _ from "lodash";
import { User } from '../../../common/models/user.model';
import { UserGeneralInformation } from '../../../modules/superadmin/edit-user/components/user-general-information.form';
import { UserChangePassword } from '../../../modules/superadmin/edit-user/components/user-password-change.form';
import { useUsersApi } from '../../../common/hooks/use-users-api.hook';

export const EditUser = () => {
    const [t,] = useTranslation();
    const [user, setUser] = useState<User>()
    let { userId } = useParams();
    const [refreshKey, setRefreshKey] = useState(0);
    const [shouldCheckForFormValidation, setShouldCheckForFormValidation] = useState(false)
    const navigate = useNavigate()
    const usersAPI = useUsersApi()

    useEffect(() => {
        if(userId){
            setTimeout(() => {
                setUserForEdit()
            }, 1000);
        } else {
            setUser({
                roles: ["VENDOR_ADMIN"]
            })
        } 
        handleRefresh()
    }, [userId])

    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    const setUserForEdit = async () => {
        const user = await usersAPI.findOneUser(userId)
        setUser(user)
    }

    const handleSave = async() => {
        let body: any = {...user}
        body.vendor = user.vendor?._id
        if(user.firstname && user.lastname && user.email && user.roles && 
            user.roles[0] && 
            ((user.roles[0] === "VENDOR_ADMIN" && user.vendor && user.vendor._id) || user.roles[0] === "SUPER_ADMIN")
        )
            if(userId){
                await usersAPI.updateUser(body)
                navigate('/users', {replace: true})
            } else {
                if(user.password){
                    await usersAPI.createUser(body)
                    navigate('/users', {replace: true})
                }
            }
        setShouldCheckForFormValidation(true)
    }

    return (
        <PageContainer title={t(userId ? 'Edit':'Add') +  ` user ${userId? " : " + (user?.firstname??"") + " " + (user?.lastname??""):""}`}>
            <Grid container spacing={2} flex={2} padding={2} key={refreshKey}>
                <Grid item md={12} xs={12}>
                    <UserGeneralInformation user={user} setUser={setUser} check={shouldCheckForFormValidation} />
                    {userId && <UserChangePassword user={user} setUser={setUser} check={shouldCheckForFormValidation}/>}
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end" mb={2} marginBlock={1}>
                <Box display={"flex"} alignItems={"center"} 
                    onClick={() => handleSave()}
                    sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{`${userId ? "Save changes":"Create user"}`}</Typography>
                </Box>
            </Box>
        </PageContainer>
    );
};


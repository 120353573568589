import { useDispatch } from "react-redux";
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { buildQueryParams } from "../utils/utils";
import { useAuthContext } from "./use-auth-context.hook";
import { User } from "../models/user.model";

export const useVendorsApi = () => {
    const { sendAuthGuardedRequest } = useAuthContext();

    const findAllVendors = async (
        limit?: number,
        offset?: number,
      ): Promise<any> => {
        const queryString = buildQueryParams([
          { key: "limit", value: limit?.toString()??"0" },
          { key: "offset", value: offset?.toString()??"600" },
        ]);
    
        const response: any = await sendAuthGuardedRequest(
          ApiMethod.GET,
          routes.vendors.findAll + queryString,
        )
        return response
    };

    const findOneVendor = async (id): Promise<any> => {
      return await sendAuthGuardedRequest(
        ApiMethod.GET, 
        routes.vendors.findOne(id),
      )
  };

  const findVendorAreas = async (): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.GET, 
      routes.vendors.findAreas,
    )
  };

    const findOneVendorAndDelete = async (id: string): Promise<any> => {
        await sendAuthGuardedRequest(
          ApiMethod.DELETE, 
          routes.vendors.deleteOne(id)
        )
    };

    const createVendor = async (vendor): Promise<any> => {
      if(vendor.spaces){
        //@ts-ignore
        vendor.spaces = vendor.spaces.map(space => space._id)
      }
      return await sendAuthGuardedRequest(
        ApiMethod.POST, 
        routes.vendors.create,
        vendor
      )
    }; 

    const updateVendor = async (id, vendor): Promise<any> => {
      if(vendor.spaces){
        //@ts-ignore
        vendor.spaces = vendor.spaces.map(space => space._id)
      }
      return await sendAuthGuardedRequest(
        ApiMethod.PATCH, 
        routes.vendors.patch(id),
        vendor
      )
    }; 
    
    return { 
      findAllVendors,
      findOneVendor,
      findOneVendorAndDelete,
      createVendor,
      updateVendor,
      findVendorAreas
    };

};
  
import { Alert, Autocomplete, Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import WallsIcon from "../../../../../assets/icons/products/walls-icon.png"
import FloorIcon from "../../../../../assets/icons/products/floor-icon.png"
import RugIcon from "../../../../../assets/icons/products/rug-icon.png"
import FadeIn from "react-fade-in/lib/FadeIn";
import { ApplicationType } from "../../components/step-1/application-type.component";
import { useDispatch, useSelector } from "react-redux";
import { setEditProductStepToValidate } from "../../../../../redux/vendor/products/productsActions";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { EditProduct } from "../../models/edit-product.model";
import { APPLICATION_TYPE } from "../../../my-products/models/product.model";
import * as _ from "lodash"
import { isValidURL } from "../../../../../common/utils/utils";
import { useProductsApi } from "../../../../../common/hooks/use-products-api.hook";
import { areas, useSettingsApi } from "../../../../../common/hooks/use-settings-api.hook";

interface EditProductProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

const staticAreas = [APPLICATION_TYPE.WALLS, APPLICATION_TYPE.FLOOR, APPLICATION_TYPE.CEILING];
const interactiveAreas = [APPLICATION_TYPE.INTERACTIVE_FLOOR];

export const ProductGeneralInformationForm = (props: EditProductProps) => {
    const [t] = useTranslation();
    const { editProductStepToValidate, products } = useSelector((state: VendorState) => state.products);
    const dispatch = useDispatch()
    const [vendorAreas, setVendorAreas] = useState<any>([])
    const [collectionsOptions, setCollectionsOptions] = useState([])
    const productsAPI = useProductsApi()
    const settingsAPI = useSettingsApi()
    const [showInput, setShowInput] = useState(false);
    const [newCollection, setNewCollection] = useState('');

    useEffect(() => {
        dispatch(setEditProductStepToValidate(-1))
        if (staticAreas.find(area => vendorAreas?.includes(area.toLowerCase()))) {
            handleIsStaticChange()
        }
    }, [vendorAreas])


    useEffect(() => {
        if (!products || products.length === 0) {
            productsAPI.refreshProductsList()
        } else {
            setCollectionsOptions([
                ...new Set(
                    products
                        .filter(product => product.specifics && product.specifics.collections)
                        .flatMap(product => product.specifics.collections)
                )
            ])
        }
    }, [products])

    useEffect(() => {
        if (areas) {
            setVendorAreas(areas)
        }
    }, [areas])

    const [fields, setFields] = useState({
        name: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.PRODUCT_NAME"),
            name: "_name"
        },
        description: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.DESCRIPTION"),
            name: "_description"
        },
        brand: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.BRAND"),
            name: "_brand"
        },
        collections: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLLECTIONS"),
            name: "_collections"
        },
        link: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.LINK"),
            name: "_link"
        },
        addToCartLink: {
            isTouched: false,
            label: t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.ADD_TO_CART"),
            name: "_addToCartLink"
        }
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({
            ...fields, [name]: {
                ...fields[name],
                isTouched: true
            }
        })
        props.setEditProduct(_.cloneDeep(props.editProduct.setProperty(name, value)))
    }

    const handleAutocompleteChange = (event, newValue) => {
        if (typeof newValue === "string") {
            setCollectionsOptions([...collectionsOptions, newValue]);
        } else if (newValue && newValue.inputValue) {
            setCollectionsOptions([...collectionsOptions, newValue.inputValue]);
        }
        props.setEditProduct(_.cloneDeep(props.editProduct.setProperty("_collections", newValue)));
    };

    const isValidInput = (name, field) => {
        return (editProductStepToValidate === props.activeStep && props.editProduct && !props.editProduct[field]) ||
            (fields && fields[name].isTouched && !props.editProduct[field])
    }

    const handleIsStaticChange = () => {
        props.setEditProduct(_.cloneDeep(props.editProduct.toggleIsStatic()))
    }

    const handleAddNewClick = () => {
        setShowInput(true);
    };

    const handleInputChange = (event, newValue) => {
        setNewCollection(newValue);
    };

    const handleKeyPress = (event) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && newCollection.trim()) {
            addNewCollection(newCollection.trim());
            event.preventDefault(); // Prevent default behavior of Tab key
        }
    };

    const handleDeleteCollection = (collectionToDelete) => {
        if (props.editProduct?.collections) {
            const updatedCollections = props.editProduct.collections.filter(
                (collection) => collection !== collectionToDelete
            );
            handleAutocompleteChange(null, updatedCollections);
        }
    };

    const handleSelectSuggestion = (event, newValue) => {
        if (newValue && typeof newValue === 'string') {
            addNewCollection(newValue);
        }
    };

    const addNewCollection = (collection) => {
        if (collection) {
            handleAutocompleteChange(null, [...(props.editProduct.collections || []), collection]);
            setNewCollection('');
            setShowInput(false);
        }
    };

    return <Box bgcolor={"primary.light"} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
        <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE")}</Typography>
        <Divider />
        <Grid container columnSpacing={3} rowSpacing={1} >
            <Grid item md={8} xs={12}>
                <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} marginTop={2}>{fields.name.label}</Typography>
                <TextField
                    error={isValidInput("name", "name")}
                    name={fields.name.name}
                    value={props.editProduct.name || ''}
                    onChange={handleChange}
                    color="secondary"
                    fullWidth
                    autoComplete='off'
                    required
                    size='small'
                    InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography fontSize={14} letterSpacing={0} marginTop={2} color={"primary.contrastText"}>{fields.brand.label}</Typography>
                <TextField
                    error={isValidInput("brand", "brand")}
                    name={fields.brand.name}
                    value={props.editProduct.brand || ''}
                    onChange={handleChange}
                    autoComplete='off'
                    color="secondary"
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"}>{fields.description.label}</Typography>
                <TextField
                    error={isValidInput("description", "description")}
                    name={fields.description.name}
                    value={props.editProduct.description || ''}
                    onChange={handleChange}
                    color="secondary"
                    fullWidth
                    autoComplete='off'
                    multiline
                    rows={4}
                    size='small'
                    InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.link.label}</Typography>
                <TextField
                    error={(props.editProduct.link && !isValidURL(props.editProduct.link))}
                    name={fields.link.name}
                    value={props.editProduct.link || ''}
                    onChange={handleChange}
                    type="url"
                    color="secondary"
                    fullWidth
                    autoComplete='off'
                    required
                    size='small'
                    helperText={(props.editProduct.link && !isValidURL(props.editProduct.link)) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NOT_A_LINK") : ""}
                    InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
            </Grid>

            <Grid item md={6} xs={12}>
                <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.addToCartLink.label}</Typography>
                <TextField
                    error={(props.editProduct.addToCartLink && !isValidURL(props.editProduct.addToCartLink))}
                    name={fields.addToCartLink.name}
                    value={props.editProduct.addToCartLink || ''}
                    onChange={handleChange}
                    type="url"
                    color="secondary"
                    fullWidth
                    autoComplete='off'
                    required
                    size='small'
                    helperText={(props.editProduct.addToCartLink && !isValidURL(props.editProduct.addToCartLink)) ? t("PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NOT_A_LINK") : ""}
                    InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
            </Grid>

            <Grid item md={12} xs={12}>
                <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} marginBottom={1} marginTop={1}>
                    {t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLLECTIONS")}
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1} alignItems="center">
                    {(props.editProduct.collections || []).map((collection, index) => (
                        <Chip
                            key={index}
                            label={collection}
                            onDelete={() => handleDeleteCollection(collection)}
                            color="secondary"
                            sx={{
                                backgroundColor: "primary.main",
                                color: "primary.contrastText",
                                "& .MuiChip-deleteIcon": {
                                    color: "#8d8d8d",
                                    transition: "color 0.3s ease-in-out",
                                    "&:hover": {
                                        color: "black",
                                    }
                                }
                            }}
                        />
                    ))}

                    {showInput ? (
                        <Autocomplete
                            freeSolo
                            options={collectionsOptions.filter(option => !props.editProduct.collections?.includes(option))}
                            value={newCollection}
                            onChange={handleSelectSuggestion}
                            inputValue={newCollection}
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onKeyDown={handleKeyPress}
                                    color="secondary"
                                    size="medium"
                                    autoFocus
                                    sx={{
                                        backgroundColor: "primary.main",
                                        fontSize: "12pt",
                                        color: "primary.contrastText",
                                        width: '200px'
                                    }}
                                    onBlur={() => setShowInput(false)}
                                />
                            )}
                        />
                    ) : (
                        <Chip
                            label={`${t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.ADD_NEW_COLLECTION")} +`}
                            onClick={handleAddNewClick}
                            sx={{
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                                color: "primary.contrastText",
                                cursor: 'pointer',
                                borderRadius: '16px',
                                padding: '4px 8px'
                            }}
                        />
                    )}
                </Box>
            </Grid>

            <Grid item md={8} xs={12}>
                <Typography fontSize={14} letterSpacing={0} color={"primary.contrastText"} marginTop={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.APPLICATION")}</Typography>
                <Box display={"flex"} marginTop={0.5}>
                    {vendorAreas?.find(area => staticAreas.includes(area.toLowerCase()))
                        && <Box bgcolor={props.editProduct.isStatic ? "secondary.main" : "primary.main"} paddingInline={2} paddingBlock={1} marginInline={1} borderRadius={2} border={"1px solid gray"}
                            borderColor={props.editProduct.isStatic ? "transparent" : "gray"} sx={{ cursor: "pointer" }}
                            onClick={handleIsStaticChange}>
                            <Typography fontSize={14} color={props.editProduct.isStatic ? "white" : "primary.text"} letterSpacing={0} >{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.STATIC")}</Typography>
                        </Box>}
                    {vendorAreas?.find(area => interactiveAreas.includes(area.toLowerCase()))
                        && <Box bgcolor={!props.editProduct.isStatic ? "secondary.main" : "primary.main"} paddingInline={2} paddingBlock={1} marginInline={1} borderRadius={2} border={"1px solid gray"}
                            borderColor={!props.editProduct.isStatic ? "transparent" : "gray"} sx={{ cursor: "pointer" }}
                            onClick={handleIsStaticChange}>
                            <Typography fontSize={14} color={!props.editProduct.isStatic ? "white" : "primary.text"} letterSpacing={0} >{t("PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.INTERACTIVE")}</Typography>
                        </Box>}
                </Box>
            </Grid>

            <Grid item md={12} xs={12} marginBlock={2} overflow={"auto"}>
                {props.editProduct.isStatic && <FadeIn >
                    <Box display={"flex"} justifyContent={"center"} >
                        {vendorAreas?.map((area, index) => (<Fragment key={index}>
                            {area.toLowerCase() === APPLICATION_TYPE.FLOOR && <ApplicationType {...props}
                                application_type={APPLICATION_TYPE.FLOOR}
                                icon={FloorIcon}
                            />}
                            {area.toLowerCase() === APPLICATION_TYPE.WALLS && <ApplicationType {...props}
                                application_type={APPLICATION_TYPE.WALLS}
                                icon={WallsIcon}
                            />}
                            {/* {area.toLowerCase() === APPLICATION_TYPE.CEILING && <ApplicationType {...props}
                                                        application_type={APPLICATION_TYPE.CEILING}
                                                        icon={CeilingIcon}
                                                        />} */}
                        </Fragment>
                        ))}

                    </Box>
                </FadeIn>}
                {!props.editProduct.isStatic && <FadeIn >
                    <Box display={"flex"} justifyContent={"center"} >
                        {vendorAreas?.map((area, index) => (<Fragment key={index}>
                            {area.toLowerCase() === APPLICATION_TYPE.INTERACTIVE_FLOOR && <ApplicationType {...props}
                                application_type={APPLICATION_TYPE.INTERACTIVE_FLOOR}
                                icon={RugIcon}
                            />}
                        </Fragment>))}
                    </Box>
                </FadeIn>}
            </Grid>
        </Grid>
        {props.editProduct.application_types.length === 0 && <Alert severity="error" sx={{ marginTop: 2 }}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NO_APPLICATION_SELECTED')}</Alert>}
    </Box>
}
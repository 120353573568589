import { SET_EDIT_PRODUCT_STEP_VALIDITY, 
    SET_PRODUCTS, VALIDATE_EDIT_PRODUCT_STEP, 
    SET_EDIT_PRODUCT_INITIAL_VARIANTS } from "./productsActionTypes";

export const setProducts = (products) => {
    return {
        type: SET_PRODUCTS,
        payload: products,
    };
};

export const setEditProductStepToValidate = (step) => {
    return {
        type: VALIDATE_EDIT_PRODUCT_STEP,
        payload: step,
    };
};

export const setEditProductStepValidity = (step, stepValidity) => {
    return {
        type: SET_EDIT_PRODUCT_STEP_VALIDITY,
        payload: {
            step,
            stepValidity
        },
    };
};

export const setEditProductInitialVariants = (variants) => {
    return {
        type: SET_EDIT_PRODUCT_INITIAL_VARIANTS,
        payload: variants
    };
};
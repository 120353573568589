
import { Circle, Close, Delete, Edit } from "@mui/icons-material";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Space, Surface } from "../../../vendor/my-spaces/models/space.model";
import * as _ from "lodash"
import { base64ToBlob, getBase64 } from "../../../../common/utils/utils";
import ConfirmDialog from "../../../../common/components/ConfirmModal";
import { toggleConfirmModal } from "../../../../redux/vendor/ui/uiActions";
import { useDispatch } from "react-redux";
import { useSpacesApi } from "../../../../common/hooks/use-spaces-api.hook";

export const SpaceSurface = ({surfaces, setSurfaces, surface, space, setSpace, setIsValid, submitted}) => {
    const [imageScale, setImageScale] = useState(0);
    const maskInputRef = useRef(null)
    const dispatch = useDispatch()
    const spacesAPI = useSpacesApi()

    const [fields, setFields] = useState({
        XMarker: {
            label: "X Marker*",
            name: "x"
        },
        YMarker: {
            label: "Y Marker*",
            name: "y"
        },
        DefaultOrientation: {
            label: "Orientation",
            name: "orientation"
        },
        APlane: {
            label: "Plan A",
            name: "0"
        },
        BPlane: {
            label: "Plan B",
            name: "1"
        },
        CPlane: {
            label: "Plan C",
            name: "2"
        },
        DPlane: {
            label: "Plan D",
            name: "3"
        },
    })

    const handleMarkerChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
        }})
        const surfaceIndex = surfaces.findIndex(sf => sf === surface)
        const tempSurfaces: Surface[] = [...surfaces]
        tempSurfaces[surfaceIndex] = _.cloneDeep(surface).setMarker({
            ...tempSurfaces[surfaceIndex].markers,
            [name]: Number.parseInt(value)
        })

        reassembleAndUpdateSpace(tempSurfaces, space)
        setSurfaces(tempSurfaces)
    }

    const handleDefaultOrientationChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
        }})
        const surfaceIndex = surfaces.findIndex(sf => sf === surface)
        const tempSurfaces: Surface[] = [...surfaces]
        tempSurfaces[surfaceIndex] = _.cloneDeep(surface).setDefaultOrientation(value)
        reassembleAndUpdateSpace(tempSurfaces, space)
        setSurfaces(tempSurfaces)
    }

    const handlePlaneChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
        }})
        const surfaceIndex = surfaces.findIndex(sf => sf === surface)
        const tempSurfaces: Surface[] = [...surfaces]
        tempSurfaces[surfaceIndex] = _.cloneDeep(surface).setPlanes((() => {
            if(tempSurfaces[surfaceIndex].planes)
                return tempSurfaces[surfaceIndex].planes.map((plane, index) => (index == name ? value: plane) )
            else {
                return [name == "0" ? value:0, name == "1" ? value:0, name == "2" ? value:0, name == "3" ? value:0]
            }
        })())
        reassembleAndUpdateSpace(tempSurfaces, space)
        setSurfaces(tempSurfaces)
    }

    const handleImageLoad = (event) => {
        setImageScale(event.target.clientWidth / space.width);
    };

    const reassembleAndUpdateSpace = (surfacesArray: Surface[], originalSpace: Space) => {
        const reassembledSpace: Space = { ...originalSpace };
    
        // Reset surfaceMarkers, defaultOrientation, and mask fields
        reassembledSpace.surfaceMarkers = {};
        reassembledSpace.defaultOrientation = {};
        reassembledSpace.mask = [];
        reassembledSpace.planes = {};
    
        // Process each surface from the surfacesArray
        surfacesArray.forEach((surface) => {
            if (surface.surface.startsWith("walls_")) {
                // Handle walls separately
                const wallIndex = parseInt(surface.surface.split("_")[1], 10);
    
                if (!reassembledSpace.surfaceMarkers.walls) {
                    reassembledSpace.surfaceMarkers.walls = [];
                }
                reassembledSpace.surfaceMarkers.walls[wallIndex] = surface.markers;

                if (!reassembledSpace.planes.walls && surface.planes) {
                    reassembledSpace.planes.walls = [];
                }
                if(surface.planes)
                    reassembledSpace.planes.walls[wallIndex] = surface.planes.join(',');
    
                if (!reassembledSpace.defaultOrientation.walls && surface.defaultOrientation) {
                    reassembledSpace.defaultOrientation.walls = [];
                }
                if(surface.defaultOrientation)
                    (reassembledSpace.defaultOrientation.walls as number[])[wallIndex] = Number.parseInt(surface.defaultOrientation as unknown as string)
    
                reassembledSpace.mask.push(surface.mask);
                
            } else {
                // Handle non-wall surfaces
                reassembledSpace.surfaceMarkers[surface.surface] = [surface.markers];
                if(surface.defaultOrientation)
                    reassembledSpace.defaultOrientation[surface.surface] = Number.parseInt(surface.defaultOrientation as unknown as string)
                if(surface.planes)
                    reassembledSpace.planes[surface.surface] = surface.planes?.join(',');
                reassembledSpace.mask.push(surface.mask);
            }
        });

        if (Object.keys(reassembledSpace.defaultOrientation).length === 0) {
            delete reassembledSpace.defaultOrientation;
        }
        if (Object.keys(reassembledSpace.planes).length === 0) {
            delete reassembledSpace.planes;
        }
        setSpace(reassembledSpace)
    }

    const checkValidity = () => {
        setIsValid(!surfaces.some((surface: Surface) => !surface.markers?.x || surface.markers?.x < 0 || !surface.markers?.y || surface.markers?.y < 0));
    };

    const pickMaskImageHandler = (event) => {
        maskInputRef.current.click();
    };

    useEffect(() => {
        checkValidity()
    }, [space])

    const handleMaskChangeChange = async(event) => {
        const file = event.target.files[0];
        const path = await getBase64(file);
        const surfaceIndex = surfaces.findIndex(sf => sf === surface)
        const tempSurfaces: Surface[] = [...surfaces]
        tempSurfaces[surfaceIndex] = _.cloneDeep(surface).setMask(path)
        reassembleAndUpdateSpace(tempSurfaces, space)
        setSurfaces(tempSurfaces)
    };

    useEffect(() => {
        if(submitted){
            saveMask()
        }
    }, [submitted])

    const saveMask = async () => {
        if(surface.mask.includes('base64')){
            const blob = base64ToBlob(surface.mask.split(',')[1])

            const fileName = surface.surface
            const file = new File([blob], `${fileName}.png`, { type: 'image/png' });
            await spacesAPI.updateMasks(space, file)
        }
    }

    const handleDeleteMask = () => {
        const tempSurfaces: Surface[] = [...surfaces].filter((existingSurface: Surface) => existingSurface !== surface)
        reassembleAndUpdateSpace(tempSurfaces, space)
        setSurfaces(tempSurfaces)
    }

    return <Box bgcolor={"primary.light"} padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginBottom={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{surface.surface.replace('_', ' ')} </Typography>
                    <Delete sx={{cursor: "pointer"}} onClick={() => dispatch(toggleConfirmModal())}/>
                </Box>
                <Divider />
                <Grid container columnSpacing={1} rowSpacing={1} >
                    <Grid item md={7} container columnSpacing={1} rowSpacing={1} alignContent={"center"}>
                        <Grid item md={12} xs={12}><Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Surface Markers* </Typography></Grid>
                        <Grid item md={12} xs={12}>
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.XMarker.label}</Typography>
                            <TextField
                                error={!surface.markers?.x || surface.markers?.x <0}
                                helperText={(!surface.markers?.x || surface.markers?.x <0) && "Incorrect entry. Must be set and greater or equal to 0."} 
                                name={fields.XMarker.name}
                                value={surface.markers?surface.markers.x || '':''}
                                onChange={handleMarkerChange}
                                fullWidth
                                color="secondary"
                                type="number"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.YMarker.label}</Typography>
                            <TextField
                                error={!surface.markers?.y || surface.markers?.y <0}
                                helperText={(!surface.markers?.y || surface.markers?.y <0) && "Incorrect entry. Must be set and greater or equal to 0."} 
                                name={fields.YMarker.name}
                                value={surface.markers?surface.markers.y || '':''}
                                onChange={handleMarkerChange}
                                fullWidth
                                color="secondary"
                                type="number"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}><Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginTop={4} >Default orientation </Typography></Grid>
                        <Grid item md={12} xs={12} >
                                <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.DefaultOrientation.label}</Typography>
                                <TextField
                                    name={fields.DefaultOrientation.name}
                                    value={surface.defaultOrientation || ''}
                                    onChange={handleDefaultOrientationChange}
                                    fullWidth
                                    color="secondary"
                                    type="number"
                                    required
                                    size='small'
                                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                                />
                        </Grid>

                        <Grid item md={12} xs={12}><Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'}  marginTop={4} >Surface plane </Typography></Grid>
                        <Grid item md={3} xs={12} >
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.APlane.label}</Typography>
                            <TextField
                                name={fields.APlane.name}
                                value={surface.planes?surface.planes[0] || '':''}
                                onChange={handlePlaneChange}
                                fullWidth
                                color="secondary"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.BPlane.label}</Typography>
                            <TextField
                                name={fields.BPlane.name}
                                value={surface.planes?surface.planes[1]|| '':''}
                                onChange={handlePlaneChange}
                                fullWidth
                                color="secondary"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.CPlane.label}</Typography>
                            <TextField
                                name={fields.CPlane.name}
                                value={surface.planes?surface.planes[2]|| '':''}
                                onChange={handlePlaneChange}
                                fullWidth
                                color="secondary"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.DPlane.label}</Typography>
                            <TextField
                                name={fields.DPlane.name}
                                value={surface.planes?surface.planes[3]|| '':''}
                                onChange={handlePlaneChange}
                                fullWidth
                                color="secondary"
                                required
                                size='small'
                                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Box position={"relative"} >
                            <img src={space.thumbnailPath} width={"100%"} onLoad={handleImageLoad} style={{borderRadius: "16px"}}/>
                            {surface.mask && <img src={surface.mask} width={"100%"} style={{position: "absolute", top: 0, right: 0, opacity: 0.6, borderRadius: "16px"}}/> } 
                            {surface.mask && <Circle style={{position: "absolute", width: "20px",
                                left: surface.markers?.x * imageScale - 10, 
                                top: surface.markers?.y * imageScale - 10,
                                color: "red"}} />}
                                 <input
                                    type="file"
                                    ref={maskInputRef}
                                    name="image"
                                    onChange={handleMaskChangeChange}
                                    style={{ display: 'none' }}
                                />
                            <Box bgcolor={"secondary.light"} padding={2} position={"relative"} marginTop={-2}
                                    display={"flex"} 
                                    onClick={pickMaskImageHandler}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    sx={{cursor: "pointer"}}
                                    // onClick={() => handleEditMask("ceiling")}
                                    flexDirection={"row"} marginBottom={2}>
                                <Edit sx={{ color: "primary.text" }} />
                                <Typography fontSize={14} color={"primary.text"} marginLeft={1} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Edit mask</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <ConfirmDialog  description={'Are you sure you wan\'t to delete this mask?'}
                                title={'Delete mask'}
                                handleAccepted={handleDeleteMask}
                />
            </Box>
}
import { Lock, ReorderRounded } from "@mui/icons-material";
import { Divider, MenuItem, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { theme as providerTheme } from '../../../theme';
import { AnalyticType } from "../analytics/components/analytic-type.component";

import MigrationsIcon from "../../../assets/icons/migrations-icon.png"
import DesignIcon from "../../../assets/icons/design-icon.png"
import LeadsIcon from "../../../assets/icons/leads-icon.png"
import AnalyticsIcon from "../../../assets/icons/analytics-icon.png"
import SelectionsIcon from "../../../assets/icons/selections-icon.png"
import AddProductIcon from "../../../assets/icons/add-product-icon.png"
import ImportProductsIcon from "../../../assets/icons/import-products-icon.png"
import BrickBondIcon from "../../../assets/icons/products/patterns/brick-bond-icon.png"

import CatalogIcon from "../../../assets/icons/catalog-icon.png"
import DashboardIcon from "../../../assets/icons/dashboard-icon.png"
import SubscriptionsIcon from "../../../assets/icons/subscriptions-icon.png"
import { MenuSectionTitle } from "../../../common/Header/SideBar";
import { MenuCustomItem } from "../../../common/Header/MenuCustomItem";
import { toggleDrawer } from "../../../redux/vendor/ui/uiActions";

export const VendorMenu = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));

  return (<Box sx={{ display: "flex", flexDirection: "column", flex: 2 }} >
    <Box>
      {isMobileAndDown && <MenuItem sx={{ paddingLeft: 1 }}>
        <ReorderRounded sx={{ color: "black", paddingTop: 1, fontSize: "25pt", marginLeft: 0, paddingLeft: 0 }} onClick={() => dispatch(toggleDrawer(!isDrawerOpen))} />
      </MenuItem>
      }
      {isMobileAndDown && <Divider />}

      <MenuCustomItem route={"/dashboard"} title={t('SIDEBAR.DASHBOARD.TITLE')} Icon={DashboardIcon} />

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.PRODUCTS.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }} >
        <MenuCustomItem route={"/products/catalog"} title={t('SIDEBAR.PRODUCTS.CATALOG')} Icon={CatalogIcon} />
        <MenuCustomItem route={"/products/new"} title={t('SIDEBAR.PRODUCTS.NEW_PRODUCT')} Icon={AddProductIcon} />
        <Box display={"flex"} alignItems={"center"}>
          <MenuCustomItem route={null} title={t('SIDEBAR.PRODUCTS.IMPORT_PRODUCTS')} Icon={ImportProductsIcon} />
          <Lock sx={{ color: "secondary.main", fontSize: "12pt" }} />
        </Box>
      </Box>

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.SPACES.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }}>
        <MenuCustomItem route={"/spaces/my-selection"} title={t('SIDEBAR.SPACES.MY_SELECTION')} Icon={SelectionsIcon} />
      </Box>

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.ENGAGEMENT.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }}>
        <MenuCustomItem route={"/engagement/analytics"} title={t('SIDEBAR.ENGAGEMENT.ANALYTICS')} Icon={AnalyticsIcon} >
          <Box borderRadius={8}>
            <AnalyticType type={"engagement"} />
            <AnalyticType type={"visits"} />
            <AnalyticType type={"product usage"} />
          </Box>
        </MenuCustomItem>
        <Box display={"flex"} alignItems={"center"}>
          <MenuCustomItem route={null} title={t('SIDEBAR.ENGAGEMENT.LEADS')} Icon={LeadsIcon} />
          <Lock sx={{ color: "secondary.main", fontSize: "12pt" }} />
        </Box>
      </Box>

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.CONFIGURATION.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }}>
        <MenuCustomItem route={"/configuration/design"} title={t('SIDEBAR.CONFIGURATION.DESIGN')} Icon={DesignIcon} />
        <MenuCustomItem route={"/products/seamless-generator"} title={t('SIDEBAR.PRODUCTS.SEAMLESS_GENERATOR')} Icon={BrickBondIcon} />
      </Box>

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.SETTINGS.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }} >
        <Box display={"flex"} alignItems={"center"}>
          <MenuCustomItem route={null} title={t('SIDEBAR.SETTINGS.MIGRATIONS')} Icon={MigrationsIcon} />
          <Lock sx={{ color: "secondary.main", fontSize: "12pt" }} />
        </Box>
      </Box>

      {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={t('SIDEBAR.BILLING.TITLE')} />}
      <Box sx={{ paddingLeft: isMobileAndDown ? 0 : 2 }} display={"flex"} alignItems={"center"}>
        <MenuCustomItem route={null} title={t('SIDEBAR.BILLING.SUBSCRIPTION')} Icon={SubscriptionsIcon} />
        <Lock sx={{ color: "secondary.main", fontSize: "12pt" }} />
      </Box>
    </Box>
  </Box>)
}
import { useContext, useEffect, useState } from 'react';
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { Space } from "../models/space.model";
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SpaceDetails } from './space-details.component';
import { AuthContext } from '../../../../providers/AuthProvider';

export const SpacesAsGrid = (props) => {
    const [selectedSpaceForDetails, setSelectedSpaceForDetails] = useState(undefined);
    const { isSuperAdmin } = useContext(AuthContext);

    const toggleSelect = (space) => {
        if (props.selectedSpaces.includes(space)) {
            props.setSelectedSpaces(props.selectedSpaces.filter(item => item._id !== space._id));
        } else {
            if (props.selectedSpaces.length < props.maxSpaces) {
                props.setSelectedSpaces([...props.selectedSpaces, space]);
            }
        }
    };

    useEffect(() => {
        props.setSelectedSpaces([...props.selectedSpaces, ...props.spaces.filter((item: Space) => item.isAssigned)]);
    }, [props.spaces])

    const isSelected = (space): boolean => {
        return props.selectedSpaces.findIndex(selectedSpace => selectedSpace._id === space._id) !== -1
    }

    const isDisabled = (space): boolean => {
        return props.selectedSpaces.length >= props.maxSpaces && !isSelected(space)
    }

    return (
        <Grid container spacing={1}>
            {selectedSpaceForDetails && <SpaceDetails space={selectedSpaceForDetails} onClose={() => setSelectedSpaceForDetails(undefined)}/>}

            {props.filteredSpaces.filter((space:Space) => !space.uploadedByVisitor).map((space: Space, index) => {
                return (
                    <Grid  item xs={12} sm={6} md={4} key={index} padding={1}>
                        <Paper 
                            sx={{ 
                                borderRadius: "8px",
                                position: 'relative', 
                                border: isSelected(space) ? '3px solid black' : 'none',
                                borderColor: "secondary.main",
                                transition: 'transform 0.2s ease',
                            }} 
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', height: '100%' }}>
                                <Box style={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10,
                                }}>
                                    <Visibility style={{
                                        background: "white",
                                        padding: 5,
                                        borderRadius: 5,
                                        border: "1px solid black",
                                        cursor: "pointer",
                                        color: "black"
                                    }}
                                        onClick={() => {
                                            setSelectedSpaceForDetails(space)
                                        }}
                                    />
                                    {isSuperAdmin && <Delete style={{
                                        background: "white",
                                        padding: 5,
                                        borderRadius: 5,
                                        border: "1px solid black",
                                        marginLeft: 5,
                                        cursor: "pointer",
                                        color: "black"
                                    }}
                                        onClick={() => {
                                            props.onDelete(space)
                                        }}
                                    />}

                                    {isSuperAdmin && <Edit style={{
                                        background: "white",
                                        padding: 5,
                                        borderRadius: 5,
                                        border: "1px solid black",
                                        marginLeft: 5,
                                        cursor: "pointer",
                                        color: "black"
                                    }}
                                        onClick={() => {
                                            props.handleEditSpace(space)
                                        }}
                                    />}
                                </Box>
                                
                                
                                <img src={space.thumbnailPath}  alt={space.name} 
                                                                style={{ width: '100%', height: '150px', objectFit: 'cover', 
                                                                    cursor: isDisabled(space) ? 'not-allowed' : 'pointer',
                                                                    borderRadius: "4px"}} 
                                                                onClick={() => isDisabled(space) ? null : toggleSelect(space)}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../redux/vendor/rootReducer";
import UploadIcon from "../../../../assets/icons/upload-icon.png"
import { Check, Close, Edit } from "@mui/icons-material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SketchPicker } from 'react-color'
import { useTranslation } from "react-i18next";
import { OpusRequirements } from "../models/OpusRequirements.model";
import { useProductsApi } from "../../../../common/hooks/use-products-api.hook";
import { setLoading } from "../../../../redux/vendor/ui/uiActions";

export const OpusPatternForm = ({ seamlessResult, setSeamlessResult }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const hiddenFile1Input = useRef(null);
    const hiddenFile2Input = useRef(null);
    const hiddenFile3Input = useRef(null);
    const hiddenFile4Input = useRef(null);
    const { language } = useSelector((state: VendorState) => state.settings.theme);
    const [image1, setImage1] = useState<any>()
    const [image2, setImage2] = useState<any>()
    const [image3, setImage3] = useState<any>()
    const [image4, setImage4] = useState<any>()
    const [isOnEditGroutColor, setIsOnEditGroutColor] = useState(false)
    const productsAPI = useProductsApi()
    const dispatch = useDispatch()
    const [fields, setFields] = useState({
        textureWidth: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.TEXTURE_WIDTH')}(m)*`,
            name: "textureWidth",
            value: ""
        },
        textureHeight: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.TEXTURE_HEIGHT')}(m)*`,
            name: "textureHeight",
            value: ""
        },
        groutLineWidth: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_WIDTH')}(mm)`,
            name: "groutLineWidth",
            value: ""
        },
        groutLineColor: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_COLOR')}`,
            name: "groutLineColor",
            value: ""
        },
    })

    const handleClick = (event, number) => {
        if (number === 1)
            hiddenFile1Input.current.click();
        else if (number === 2)
            hiddenFile2Input.current.click();
        else if (number === 3)
            hiddenFile3Input.current.click();
        else if (number === 4)
            hiddenFile4Input.current.click();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({
            ...fields, [name]: {
                ...fields[name],
                value: value,
                isTouched: true
            }
        })
    }

    const isValidStep = () => {
        return fields.textureWidth.value && fields.textureHeight.value && image1 && image2 && image3 && image4
    }

    const removeImage = (indexOfImageToRemove) => {
        if (indexOfImageToRemove === 1) {
            setImage1(null)
            hiddenFile1Input.current.value = ""
        }
        if (indexOfImageToRemove === 2) {
            setImage2(null)
            hiddenFile2Input.current.value = ""
        }
        if (indexOfImageToRemove === 3) {
            setImage3(null)
            hiddenFile3Input.current.value = ""
        }
        if (indexOfImageToRemove === 4) {
            setImage4(null)
            hiddenFile4Input.current.value = ""
        }
    }

    const handleGenerateSeamless = async () => {
        if (isValidStep()) {
            dispatch(setLoading(true))
            const body = new OpusRequirements()
            body.setImageA(image1)
                .setImageB(image2)
                .setImageC(image3)
                .setImageD(image4)
                .setTextureWidth(fields.textureWidth.value)
                .setTextureHeight(fields.textureHeight.value)
                .setGroutLineColor(fields.groutLineColor.value ?? "#FFFFFF")
                .setGroutLineWidth(fields.groutLineWidth.value)
            let data = await productsAPI.generateSeamlessForPattern(body, "opus");
            setSeamlessResult(data)
            dispatch(setLoading(false))
        }
    }

    const handleGroutColorChange = (color) => {
        setFields({
            ...fields,
            groutLineColor: {
                ...fields.groutLineColor,
                value: color.hex
            }
        })
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid container spacing={1} xs={12} md={4} sx={{ backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2 }} >
                    <Grid item xs={12} md={12}>
                        <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.IMAGES')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, 1)}
                            bgcolor={"#F5E6E8"}
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{
                                height: "45px", padding: "10px",
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)" : ""
                            }} />
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_1')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_1')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => setImage1(event.target.files[0])}
                                accept="image/*"
                                ref={hiddenFile1Input}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {image1 && <Close sx={{ position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer" }} onClick={() => removeImage(1)} />}
                        {image1 && <LazyLoadImage src={URL.createObjectURL(image1)} style={{ cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px" }} />}
                    </Grid>

                    <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, 2)}
                            bgcolor={"#F5E6E8"}
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{
                                height: "45px", padding: "10px",
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)" : ""
                            }} />
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_2')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_2')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => setImage2(event.target.files[0])}
                                accept="image/*"
                                ref={hiddenFile2Input}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {image2 && <Close sx={{ position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer" }} onClick={() => removeImage(2)} />}
                        {image2 && <LazyLoadImage src={URL.createObjectURL(image2)} style={{ cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px" }} />}
                    </Grid>

                    <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, 3)}
                            bgcolor={"#F5E6E8"}
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{
                                height: "45px", padding: "10px",
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)" : ""
                            }} />
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_3')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_3')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => setImage3(event.target.files[0])}
                                accept="image/*"
                                ref={hiddenFile3Input}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {image3 && <Close sx={{ position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer" }} onClick={() => removeImage(3)} />}
                        {image3 && <LazyLoadImage src={URL.createObjectURL(image3)} style={{ cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px" }} />}
                    </Grid>

                    <Grid item xs={12} md={4} display={"flex"} alignItems={"center"}>
                        <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{ cursor: 'pointer' }} onClick={(e) => handleClick(e, 4)}
                            bgcolor={"#F5E6E8"}
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{
                                height: "45px", padding: "10px",
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)" : ""
                            }} />
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_4')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_IMAGE_4')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => setImage4(event.target.files[0])}
                                accept="image/*"
                                ref={hiddenFile4Input}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {image4 && <Close sx={{ position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer" }} onClick={() => removeImage(4)} />}
                        {image4 && <LazyLoadImage src={URL.createObjectURL(image4)} style={{ cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px" }} />}
                    </Grid>
                </Grid>


                <Grid item xs={12} md={7} sx={{ backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2 }} >
                    <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.REQUIREMENTS')}</Typography>

                    <Grid item xs={12}>
                        <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.textureWidth.label}</Typography>
                        <Select
                            error={fields.textureWidth.isTouched && fields.textureWidth.value === ""}
                            name={fields.textureWidth.name}
                            value={fields.textureWidth.value}
                            onChange={handleChange}
                            type="number"
                            variant="outlined"
                            color='secondary'
                            fullWidth
                            size='small'
                            inputProps={{ inputProps: { min: 1, max: 4 }, sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.textureHeight.label}</Typography>
                        <Select
                            error={fields.textureHeight.isTouched && fields.textureHeight.value === ""}
                            name={fields.textureHeight.name}
                            value={fields.textureHeight.value}
                            onChange={handleChange}
                            type="number"
                            variant="outlined"
                            color='secondary'
                            fullWidth
                            size='small'
                            inputProps={{ inputProps: { min: 1, max: 4 }, sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutLineWidth.label}</Typography>
                        <TextField
                            error={fields.groutLineWidth.isTouched && fields.groutLineWidth.value === ""}
                            name={fields.groutLineWidth.name}
                            value={fields.groutLineWidth.value}
                            onChange={handleChange}
                            type="number"
                            variant="outlined"
                            color='secondary'
                            fullWidth
                            size='small'
                            InputProps={{ sx: { backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutLineColor.label}</Typography>
                        <Box bgcolor={fields.groutLineColor.value} padding={2} height={12} borderRadius={2} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} border={"1px solid lightgray"}>
                            <Edit sx={{ backgroundColor: "primary.main", padding: 0.5, borderRadius: 20, cursor: "pointer", color: "primary.text", border: "1px solid lightgray" }} onClick={() => setIsOnEditGroutColor(true)} />
                        </Box>
                        {isOnEditGroutColor && <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <SketchPicker sx={{ width: '100%' }} color={fields.groutLineColor.value}
                                onChange={handleGroutColorChange} />
                            <Box>
                                <Button variant="outlined" sx={{ margin: 1, color: "primary.text", borderColor: "primary.text" }} onClick={() => {
                                    setIsOnEditGroutColor(false)
                                }} ><Check />
                                </Button>
                            </Box>
                        </Box>}

                    </Grid>

                    <Grid item xs={12}>
                        <Box display={"flex"} alignItems={"center"} onClick={handleGenerateSeamless}
                            sx={{ cursor: `${isValidStep() ? "pointer" : "not-allowed"}`, bgcolor: `${isValidStep() ? "primary.text" : "gray"}`, float: "right", paddingInline: 2, borderRadius: 2, marginTop: 2, width: "fit-content" }}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{t('PATTERNS.PARTIALS.GENERATE')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { toggleConfirmModal } from '../../redux/vendor/ui/uiActions';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { VendorState } from '../../redux/vendor/rootReducer';

export default function ConfirmDialog(props) {
    const [t, i18n] = useTranslation();

    const { isConfirmModalOpen } = useSelector((state: VendorState) => state.ui);
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(toggleConfirmModal());
    };

    const handleAccept = () => {
        props.handleAccepted()
        dispatch(toggleConfirmModal());
    };

    return (
        <React.Fragment>
        <Dialog
            open={isConfirmModalOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography fontSize={17} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.text"} padding={0}>{props.title}</Typography>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Typography fontSize={14} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.text"} padding={0}>{props.description}</Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box display={"flex"} alignItems={"center"} onClick={handleAccept}
                    sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, width: "fit-content"}} padding={1}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.main"} >{t('MODALS.CONFIRM.VALIDATE')}</Typography>
                </Box>

                <Box display={"flex"} alignItems={"center"} onClick={handleClose}
                    sx={{cursor: "pointer", bgcolor: "primary.main", border: "1px solid transparent", borderColor: "primary.text", paddingInline: 2, borderRadius: 2, width: "fit-content"}} padding={1}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.text"} padding={0}>{t('MODALS.CONFIRM.CLOSE')}</Typography>
                </Box>

            </DialogActions>
        </Dialog>
        </React.Fragment>
    );
}
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./vendor/rootReducer";
import { persistReducer, persistStore } from 'redux-persist';

import localforage from 'localforage';

const persistConfig = {
    key: 'root',
    storage: localforage
  };

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({ reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
 });

export const persistor = persistStore(store)

import { useDispatch } from "react-redux";
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { buildQueryParams } from "../utils/utils";
import { useAuthContext } from "./use-auth-context.hook";
import { User } from "../models/user.model";

export const useSpacesApi = () => {
  const { sendAuthGuardedRequest } = useAuthContext();

  const findAllSpaces = async (
    limit?: number,
    offset?: number,
  ): Promise<any> => {
    const queryString = buildQueryParams([
      { key: "limit", value: limit?.toString()??"0" },
      { key: "offset", value: offset?.toString()??"600" },
    ]);

    const response: any = await sendAuthGuardedRequest(
      ApiMethod.GET,
      routes.spaces.findAll + queryString,
    )
    return response
  };

  const findOneSpace = async (id): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.GET,
      routes.spaces.findOne(id),
    )
  };

  const findOneSpaceAndDelete = async (id: string): Promise<any> => {
    await sendAuthGuardedRequest(
      ApiMethod.DELETE,
      routes.spaces.deleteOne(id)
    )
  };

  const createSpace = async (space): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.POST,
      routes.spaces.create,
      space
    )
  };

  const updateSpace = async (space): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.PATCH,
      routes.spaces.patch,
      space
    )
  };

  const runAnalysis = async (spaceFile): Promise<any> => {
    let formData = new FormData();
    formData.append('image', spaceFile);

    return await sendAuthGuardedRequest(
      ApiMethod.POST,
      routes.spaces.analysis,
      formData,
      {
        headers: { 'Device-Token': "111" }
      }
    )
  };

  const updateMasks = async (spaceId, maskFile): Promise<any> => {
    let formData = new FormData();
    formData.append('mask', maskFile);

    return await sendAuthGuardedRequest(
      ApiMethod.PATCH,
      routes.spaces.patchMasks(spaceId),
      maskFile
    )
  };

  const findAllSpaceTypes = async (
    limit?: number,
    offset?: number,
  ): Promise<any> => {
    const queryString = buildQueryParams([
      { key: "limit", value: limit?.toString() },
      { key: "offset", value: offset?.toString() },
    ]);

    const response: any = await sendAuthGuardedRequest(
      ApiMethod.GET,
      routes.spaces.findAllSpaceTypes + queryString,
    )
    return response
  };

  const assignSpaces = async (spaces): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.POST,
      routes.spaces.assignSpaces,
      spaces.map(space => space.id)
    )
  };


  return {
    findAllSpaces,
    findOneSpace,
    findOneSpaceAndDelete,
    createSpace,
    updateSpace,
    runAnalysis,
    updateMasks,
    findAllSpaceTypes,
    assignSpaces
  };

};

import { Delete, Edit } from '@mui/icons-material';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, TablePagination, Chip, TableSortLabel, useMediaQuery, Box } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../common/models/user.model';

export const UsersAsList = (props) => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [selectedUserIdForView, setSelectedUserIDForView] = useState<any>()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property) => {
        const isAsc = sortBy === property && sortOrder === 'asc';
        setSortBy(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
        const sortedUsers = [...props.filteredUsers].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        props.setFilteredUsers(sortedUsers);
    };

    return (<> {props.filteredUsers && props.filteredUsers.length>0 && <>
            <TableContainer component={Paper} sx={{borderRadius: 4}}>
                <Table size="small" >
                    <TableHead sx={{bgcolor: "primary.light"}}>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Name</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Email</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Vendor</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Role</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{bgcolor: "primary.light"}}>
                        {props.filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user: User, index) => (
                            <Fragment key={index}><TableRow sx={{ bgcolor: `${user.roles[0] === "SUPER_ADMIN" ? "gray":"transparent"}` }}>
                                <TableCell >
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{user.firstname} {user.lastname}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{user.email}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        {user.vendor?.config?.logo && <img src={user.vendor?.config?.logo} width={"30px"} style={{borderRadius: 8, marginRight: 6}}></img>}
                                        <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{user.vendor?.name}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{user.roles.map(role => <Typography variant="caption" key={role}><Chip size='small' label={role.replace('_',' ').toLowerCase()} sx={{fontSize: "8pt", textTransform: "capitalize"}}/></Typography>)}</Typography>
                                </TableCell>
                                <TableCell>
                                    {user.roles[0] !== "SUPER_ADMIN" && <Edit onClick={() => props.handleEditUser(user)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/>}
                                    {user.roles[0] !== "SUPER_ADMIN" && <Delete onClick={() => props.onDelete(user)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> }
                                </TableCell>
                            </TableRow>
                        </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {selectedUserIdForView && <UserView selectedUserForViewID={selectedUserIdForView} setSelectedUserForView={setSelectedUserIDForView} />} */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>}
    </>)
}
import { useEffect, useState } from 'react';
import { Typography, Grid, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';
import { PRODUCT_TYPES_PATTERNS } from '../../../modules/vendor/edit-product/models/types.enums';
import * as _ from "lodash"
import { HerringbonePatternForm } from '../../../modules/vendor/seamless-generator/components/HerringbonePattern.form';
import { BricksPatternForm } from '../../../modules/vendor/seamless-generator/components/BricksPattern.form';
import { ChevronPatternForm } from '../../../modules/vendor/seamless-generator/components/ChevronPattern.form';
import { StackBondPatternForm } from '../../../modules/vendor/seamless-generator/components/StackBondPattern.form';
import { OpusPatternForm } from '../../../modules/vendor/seamless-generator/components/OpusPattern.form';
import { CabochonPatternForm } from '../../../modules/vendor/seamless-generator/components/CabochonPattern.form';
import { HexagonalPatternForm } from '../../../modules/vendor/seamless-generator/components/HexagonalPattern.form';
import { SeamlessResultDialog } from '../../../modules/vendor/seamless-generator/modals/seamless-result.modal';

export const SeamlessGenerator = () => {
    const { t, i18n } = useTranslation();
    const [patternList, setPatternList] = useState([])
    const theme = useTheme();
    const [seamlessResult, setSeamlessResult] = useState<any>()
    const [isSeamlessResultViewerOpen, setIsSeamlessResultViewerOpen] = useState<any>(false)
    const [selectedPattern, setSelectedPattern] = useState<any>()

    const isSelected = (pattern) => {
        return pattern.name === selectedPattern?.name
    }

    useEffect(() => {
        const patterns = _.uniqBy([...(PRODUCT_TYPES_PATTERNS.floor.map(e => e.patterns).flat()), ...(PRODUCT_TYPES_PATTERNS.walls.map(e => e.patterns).flat())], 'name')
        setPatternList(patterns)
    }, [])

    useEffect(() => {
        setIsSeamlessResultViewerOpen(false)
        setSeamlessResult(null)
    }, [selectedPattern])

    useEffect(() => {
        if(seamlessResult){
            setIsSeamlessResultViewerOpen(true)
        }
    }, [seamlessResult])

    return ( 
        <PageContainer title={t('SIDEBAR.PRODUCTS.SEAMLESS_GENERATOR')}>
            <Grid container marginLeft={1}>
                <Grid item md={2} xs={6} sx={{backgroundColor: "primary.light", borderRadius: 4}}>
                    <Box display={"flex"} flexDirection={"column"} padding={2} justifyContent={"center"} >
                        {patternList.map((pattern, index) => (
                            <Box key={index} borderRadius={2} paddingBlock={1} border={"1px solid gray"} onClick={() => setSelectedPattern(pattern)} sx={{cursor: "pointer", marginBottom: 1}}
                                bgcolor={isSelected(pattern)?"secondary.main":"primary.main"} 
                                display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                                <img src={pattern.icon} style={{height: "45px", padding: "10px", 
                                    filter: (theme.palette.mode === 'dark' || isSelected(pattern)) ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                                {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} color={isSelected(pattern)?"primary.main":"primary.contrastText"}>{t(`PATTERNS.${pattern.name.toUpperCase().replace(' ','_')}`)}</Typography>}
                                {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} color={"primary.text"}>{t(`PATTERNS.${pattern.name.toUpperCase().replace(' ','_')}`)}</Typography>}
                            </Box>
                        ))}
                    </Box>
                </Grid>
                {selectedPattern && <Grid item md={10} xs={6} paddingLeft={2}>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontSize={26} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{t(`PATTERNS.PARTIALS.TITLE`)}: {t(`PATTERNS.${selectedPattern.name.toUpperCase().replace(' ','_')}`)}</Typography>
                        {selectedPattern.name === "bricks" && <BricksPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "herringbone" && <HerringbonePatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "chevron" && <ChevronPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "stack bond" && <StackBondPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "opus" && <OpusPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "cabochon" && <CabochonPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                        {selectedPattern.name === "hexagonal" && <HexagonalPatternForm seamlessResult={seamlessResult} setSeamlessResult={setSeamlessResult}/>}
                    </Box>
                    {seamlessResult && <Box padding={2} bgcolor={"primary.light"} borderRadius={2} sx={{width: "300px",  cursor: "pointer"}} onClick={() => setIsSeamlessResultViewerOpen(true)}>
                        <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.RESULT')}</Typography>
                        <img src={`data:image/png;base64,${seamlessResult}`} style={{objectFit: "contain", maxWidth: "300px", maxHeight: "300px"}} />
                        </Box>
                    }
                </Grid>}
            </Grid>
            {isSeamlessResultViewerOpen && <SeamlessResultDialog isOpen={isSeamlessResultViewerOpen} setIsOpen={(value) => setIsSeamlessResultViewerOpen(value)} seamlessResult={seamlessResult} /> }
        </PageContainer>
    );
};

import {TOGGLE_DRAWER, TOGGLE_CONFIRM_MODAL, SET_CONFIRM_MODAL_CONTENT, SET_LOADING, SET_BREADCRUMBS} from "./uiActionTypes";

export const toggleDrawer = (payload) => {
    return {
        type: TOGGLE_DRAWER,
        payload: payload
    };
};

export const toggleConfirmModal = () => {
    return {
        type: TOGGLE_CONFIRM_MODAL
    };
};

export const setConfirmModalContent = (payload) => {
    return {
        type: SET_CONFIRM_MODAL_CONTENT,
        payload: payload
    };
};

export const setBreadcrumbs = (payload) => {
    return {
        type: SET_BREADCRUMBS,
        payload: payload
    };
};

export const setLoading = (payload) => {
    return {
        type: SET_LOADING,
        payload: payload
    };
};


import { useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';
import { theme as providerTheme } from '../../../theme';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';


export const Dashboard = () => {
    const [t] = useTranslation();
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));

    return (
        <PageContainer title={t('PAGES.DASHBOARD.TITLE')}>
            <Grid container spacing={1} flex={2} padding={isMobileAndDown?0:2}>

            </Grid>

        </PageContainer>
    );
};


export default Dashboard;

import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';
import VendorStats from '../../../modules/vendor/settings/models/stats.model';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { get30DaysAgo } from '../../../common/utils/utils';
import { VisitsAnalytics } from '../../../modules/vendor/analytics/components/visits-analytics.components';
import FadeIn from 'react-fade-in';
import { ProductUsageAnalytics } from '../../../modules/vendor/analytics/components/product-usage-analytics.components';
import { useParams } from 'react-router-dom';
import { EngagementsAnalytics } from '../../../modules/vendor/analytics/components/engagement-analytics.components';
import AnalyticsIcon from "../../../assets/icons/dashboard-analytics-icon.png"
import ProductsIcon from "../../../assets/icons/dashboard-products-icon.png"
import { DashboardStatBox } from '../dashboard/dashboard.page';
import { useAnalyticsApi } from '../../../common/hooks/use-analytics-api.hook';

export const Analytics = () => {
    const [t] = useTranslation();
    const {analyticType} = useParams()
    const [startDate, setStartDate] = useState(get30DaysAgo())
    const [endDate, setEndDate] = useState(dayjs(new Date(new Date().setHours(23,59,59,59))))
    const [selectedState, setSelectedState] = useState<string>(analyticType)
    const [engagement, setEngagement] = useState("")
    const [vendorStats, setVendorStats] = useState<VendorStats>(undefined)

    const analyticsAPI = useAnalyticsApi()

    useEffect(() => {
        setSelectedState(analyticType)
    }, [analyticType])

    useEffect(() => {
        getEngagementsAnalytics()
        getVendorStats()
    }, [])

    useEffect(() => {
        const initStats = async () => {
        }
        initStats()
    }, []);

    const getEngagementsAnalytics = async () => {
        let data = await analyticsAPI.queryAnalytics({
            statType: "EVENT_USAGE_COUNT",
            startDate: startDate,
            endDate: endDate
        })
        data = data.map(e => e.count).reduce((acc, item) => acc += item)
        setEngagement(data)
    }

    const getVendorStats = async () => {
        setVendorStats(await analyticsAPI.findAllAnalytics())
    }

    return (
        <PageContainer title={t('PAGES.ANALYTICS.TITLE')}>
            {!selectedState && <Box>
                <Grid container spacing={1} flex={2} >
                    <Grid item md={4} xs={12}>
                        <DashboardStatBox 
                            title={"Engagement"}
                            data={engagement}
                            icon={AnalyticsIcon}
                            iconBackground={"#e36809"}
                            actionTitle={"View Details"}
                            actionRoute={"/engagement/analytics/engagement"}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <DashboardStatBox 
                            title={"Number of products"}
                            data={vendorStats?.products.activeProducts}
                            icon={ProductsIcon}
                            iconFilter={"invert(59%) sepia(16%) saturate(724%) hue-rotate(180deg) brightness(89%) contrast(88%)"}
                            actionTitle={"View Usage"}
                            actionRoute={"/engagement/analytics/product%20usage"}
                            />
                    </Grid>
                </Grid>
            </Box>}

            {selectedState && <FadeIn>
                    <Box display={"flex"} justifyContent={"space-evenly"} padding={2} borderRadius={4} 
                                sx={{backgroundColor: "primary.light"}} >
                        <DateField
                            label="Search start date"
                            value={dayjs(startDate)}
                            onChange={(newValue) => setStartDate(newValue)}
                            format="DD-MM-YYYY"
                            maxDate={endDate}
                            color='secondary'
                            variant='filled'
                            size='small'
                        />

                        <DateField
                            label="Search end date"
                            value={dayjs(endDate)}
                            onChange={(newValue) => setEndDate(newValue)}
                            format="DD-MM-YYYY"
                            minDate={startDate}
                            color='secondary'
                            variant='filled'
                            size='small'
                        />
                    </Box>
                    
                    <Box padding={2} borderRadius={4} marginTop={1}
                                sx={{backgroundColor: "primary.light"}} >
                            
                            {selectedState === "visits" && <VisitsAnalytics startDate={startDate} endDate={endDate} />}
                            {selectedState === "product usage" && <ProductUsageAnalytics startDate={startDate} endDate={endDate} />}
                            {selectedState === "engagement" && <EngagementsAnalytics startDate={startDate} endDate={endDate} />}
                    </Box>
                </FadeIn>}


        </PageContainer>
    );
};



export default Analytics;

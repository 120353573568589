import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as EN from "./en.json"
import * as ES from "./es.json"
import * as AR from "./ar.json"
import * as FR from "./fr.json"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      fr: {
        translation: FR,
      },
      ar: {
        translation: AR,
      },
      es: {
        translation: ES,
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
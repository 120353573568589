import { EditProduct } from "../../edit-product/models/edit-product.model";
import { ProductVariant } from "../../edit-product/models/variant.model";

export class Product {
  private _id?: string;
  private _uuid?: string;
  private _name?: string;
  private _brand?: string;
  private _application_types?: APPLICATION_TYPE[];
  private _type?: string;
  private _productTypeName?: string;
  private _color?: string;
  private _pattern?: string;
  private _productTypeId?: number;
  private _vendor_code?: string;
  private _sku?: string;
  private _description?: string;
  private _sizeName?: string;
  private _isVisible?: boolean;
  private _thumbnailPath?: string;
  private _width?: number;
  private _length?: number;
  private _unit?: string;
  private _link?: any;
  private _addToCartLink?: any;
  private _specifics?: Specifics;
  private _formats?: Format[];
  private _visualization?: VisualizationWrapper;
  private _siblings?: Product[];
  private _size?: Size;
  private _isDefault?: boolean;
  private _productColors?: string[];
  private _order?: number;

  public prepareProduct = (product): this => {
    return this .setId(product._id)
                .setUuid(product.id)
                .setName(product.name)
                .setBrand(product.brand)
                .setApplication_types(product.application_types)
                .setType(product.type)
                .setProductTypeName(product.productTypeName)
                .setPattern(product.pattern)
                .setColor(product.color)
                .setProductTypeId(product.productTypeId)
                .setVendor_code(product.vendor_code)
                .setSku(product.sku)
                .setDescription(product.description)
                .setSizeName(product.sizeName)
                .setIsVisible(product.isVisible)
                .setThumbnailPath(product.thumbnailPath)
                .setWidth(product.width)
                .setLength(product.length)
                .setUnit(product.unit)
                .setLink(product.link)
                .setAddToCartLink(product.addToCartLink)
                .setSpecifics(product.specifics)
                .setFormats(product.formats)
                .setVisualization(product.visualization)
                .setSiblings(product.siblings?.map(sibling => {return (new Product()).prepareProduct(sibling)}))
                .setSize(product.size)
                .setIsDefault(product.isDefault)
                .setProductColors(product.productColors)
  }

  public mapVariantIntoProduct = (variant: ProductVariant, product: EditProduct) => {
    return this.setName(variant.getTitle())
        .setApplication_types(product.application_types)
        .setType(product.type.charAt(0).toUpperCase() + product.type.slice(1))
        .setBrand(product.brand)
        .setSku(variant.getSku())
        .setDescription(product.description)
        .setSizeName(`${variant.getSize().width}${variant.getSize().unit} x ${variant.getSize().height}${variant.getSize().unit}`)
        .setIsVisible(true)
        .setWidth(variant.getSize().width)
        .setLength(variant.getSize().height)
        .setUnit("cm")
        .setLink(variant.getLink())
        .setAddToCartLink(variant.getAddToCartLink())
        .setColor(variant.getColor())
        .setPattern(variant.getPattern())
        .setSpecifics({
          colors: variant.getProductColors(),
          collections: variant.getCollections()
        })
        .setFormats([{
          type: "size",
          name: this.sizeName
        }])
        .setIsDefault(variant.getIsDefault())
        .setVisualization({data: [{
          width: Number.parseFloat(variant.getSeamlessWidth()),
          height: Number.parseFloat(variant.getSeamlessHeight())
        }]})
        .setId(variant.productId)
        .setUuid(variant.productUuid)
  }

  public toPlainObject(): object {
    const plainObject: any = {};
    for (const key in this) {
      if (this.hasOwnProperty(key) && key.startsWith('_')) {
        const plainKey = key.substring(1);
        plainObject[plainKey] = (this as any)[key];
      }
    }
    return plainObject;
  }

  public get uuid(): string | undefined {
    return this._uuid;
  }

  public setUuid(uuid: string): this {
    this._uuid = uuid;
    return this;
  }

  public get id(): string | undefined {
    return this._id;
  }

  public setId(id: string): this {
    this._id = id;
    return this;
  }

  public get name(): string | undefined {
    return this._name;
  }

  public setName(name: string): this {
    this._name = name;
    return this;
  }

  public get order(): number | undefined {
    return this._order;
  }

  public setOrder(order: number): this {
    this._order = order;
    return this;
  }

  public get brand(): string | undefined {
    return this._brand;
  }

  public setBrand(brand: string): this {
    this._brand = brand;
    return this;
  }

  public get productColors(): string[] | undefined {
    return this._productColors;
  }

  public setProductColors(colors: string[]): this {
    this._productColors = colors;
    return this;
  }

  public get application_types(): APPLICATION_TYPE[] | undefined {
    return this._application_types;
  }

  public setApplication_types(application_types: APPLICATION_TYPE[]): this {
    this._application_types = application_types;
    return this;
  }

  public get type(): string | undefined {
    return this._type;
  }

  public setType(type: string): this {
    this._type = type;
    return this;
  }

  public get productTypeName(): string | undefined {
    return this._productTypeName;
  }

  public setProductTypeName(productTypeName: string): this {
    this._productTypeName = productTypeName;
    return this;
  }

  public get color(): string | undefined {
    return this._color;
  }

  public setColor(color: string): this {
    this._color = color;
    return this;
  }

  public get pattern(): string | undefined {
    return this._pattern;
  }

  public setPattern(pattern: string): this {
    this._pattern = pattern;
    return this;
  }

  public get productTypeId(): number | undefined {
    return this._productTypeId;
  }

  public setProductTypeId(productTypeId: number): this {
    this._productTypeId = productTypeId;
    return this;
  }

  public get vendor_code(): string | undefined {
    return this._vendor_code;
  }

  public setVendor_code(vendor_code: string): this {
    this._vendor_code = vendor_code;
    return this;
  }

  public get sku(): string | undefined {
    return this._sku;
  }

  public setSku(sku: string): this {
    this._sku = sku;
    return this;
  }

  public get description(): string | undefined {
    return this._description;
  }

  public setDescription(description: string): this {
    this._description = description;
    return this;
  }

  public get sizeName(): string | undefined {
    return this._sizeName;
  }

  public setSizeName(sizeName: string): this {
    this._sizeName = sizeName;
    return this;
  }

  public get isVisible(): boolean | undefined {
    return this._isVisible;
  }

  public setIsVisible(isVisible: boolean): this {
    this._isVisible = isVisible;
    return this;
  }

  public get thumbnailPath(): string | undefined {
    return this._thumbnailPath;
  }

  public setThumbnailPath(thumbnailPath: string): this {
    this._thumbnailPath = thumbnailPath;
    return this;
  }

  public get width(): number | undefined {
    return this._width;
  }

  public setWidth(width: number): this {
    this._width = width;
    return this;
  }

  public get length(): number | undefined {
    return this._length;
  }

  public setLength(length: number): this {
    this._length = length;
    return this;
  }

  public get unit(): string | undefined {
    return this._unit;
  }

  public setUnit(unit: string): this {
    this._unit = unit;
    return this;
  }

  public get link(): any {
    return this._link;
  }

  public setLink(link: any): this {
    this._link = link;
    return this;
  }

  public get addToCartLink(): any {
    return this._addToCartLink;
  }

  public setAddToCartLink(link: any): this {
    this._addToCartLink = link;
    return this;
  }

  public get specifics(): Specifics | undefined {
    return this._specifics;
  }

  public setSpecifics(specifics: Specifics): this {
    this._specifics = specifics;
    return this;
  }

  public get formats(): Format[] | undefined {
    return this._formats;
  }

  public setFormats(formats: Format[]): this {
    this._formats = formats;
    return this;
  }

  public get visualization(): VisualizationWrapper | undefined {
    return this._visualization;
  }

  public setVisualization(visualization: VisualizationWrapper): this {
    this._visualization = visualization;
    return this;
  }

  public get siblings(): Product[] | undefined {
    return this._siblings;
  }

  public setSiblings(siblings: Product[]): this {
    this._siblings = siblings;
    return this;
  }

  public get size(): Size | undefined {
    return this._size;
  }

  public setSize(size: Size): this {
    this._size = size;
    return this;
  }

  public get isDefault(): boolean | undefined {
    return this._isDefault;
  }

  public setIsDefault(isDefault: boolean): this {
    this._isDefault = isDefault;
    return this;
  }
}

export enum APPLICATION_TYPE {
  FLOOR="floor",
  WALLS="walls",
  CEILING="ceiling",
  RUGS="rugs",
  INTERACTIVE_FLOOR="interactive_floor",
}

export interface Specifics {
  styles?: string[]
  collections?: string[]
  categories?: string[]
  colors?: any[]
  features?: string[]
}

export class Size {
  width?: number
  height?: number
  unit = "cm"
  
  public setWidth(width: number): this {
    this.width = width;
    return this;
  }
  public setHeight(height: number): this {
    this.height = height;
    return this;
  }
}

export interface Format {
  type: string
  name: string
}

export interface VisualizationWrapper {
  data: Visualization[]
}

export interface Visualization {
  type?: string
  code?: string
  is_default?: boolean
  material?: string
  materialPath?: string
  width?: number
  height?: number
}

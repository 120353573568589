import { Delete, Edit } from "@mui/icons-material"
import { Box, Chip, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Vendor } from "../models/vendor.model"

export const VendorsAsGrid = (props) => {

    return <Grid container spacing={2}>
        {props.filteredVendors.map((vendor: Vendor, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', position: 'relative' }}>
                <IconButton aria-label="edit" onClick={() => props.handleEditVendor(vendor)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                    <Edit />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => props.onDelete(vendor)} style={{ position: 'absolute', top: '5px', left: '5px' }}>
                    <Delete />
                </IconButton>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                    <Box sx={{ maxWidth: '100%', height: '150px', display: "flex", alignItems: "center", justifyContent:"center" }}>
                        <img src={vendor.config?.logo} alt={vendor.name} style={{ maxWidth: '100%', maxHeight: '150px'}} />
                    </Box>
                    <Typography variant="subtitle1"><b>{vendor.name}</b></Typography>
                    <Typography variant="caption">#{vendor.alias}</Typography>
                    <Typography variant="caption">{vendor.email}</Typography>
                    {vendor.areas.map(area => <Typography variant="caption"><Chip size='small' label={area.name} /></Typography>)}
                </div>
            </Paper>
        </Grid>
        ))}
    </Grid>
}
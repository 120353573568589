import { useEffect, useState } from 'react';
import { Typography,  TextField, Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/components/ConfirmModal';
import { toggleConfirmModal } from '../../../redux/vendor/ui/uiActions';
import { ViewList, ViewModule } from '@mui/icons-material';
import { PageContainer } from '../../../common/components/PageContainer';
import { Vendor } from '../../../modules/superadmin/our-vendors/models/vendor.model';
import { VendorsAsList } from '../../../modules/superadmin/our-vendors/components/vendors-as-list.component';
import { VendorsAsGrid } from '../../../modules/superadmin/our-vendors/components/vendor-as-grid.component';
import { useVendorsApi } from '../../../common/hooks/use-vendors-api.hook';

export const OurVendors = () => {
    const [t] = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [displayedVendors, setDisplayedVendors] = useState([]);
    const [filteredVendors, setFilteredVendors] = useState(displayedVendors);
    const [filter, setFilter] = useState('');
    const [viewMode, setViewMode] = useState<'list' | 'grid'>('list'); // Track view mode
    const [modalProps, setModalProps] = useState({})
    const vendorsAPI = useVendorsApi()

    useEffect(() => {
        fetchVendors()
    }, [])

    const fetchVendors = async () => {
        const vendors = await vendorsAPI.findAllVendors()
        setDisplayedVendors(vendors)
    }

    const onDelete = (vendor) => {
        dispatch(toggleConfirmModal())
        setModalProps({
            title: "Remove vendor",
            description: "Do you really wan't to remove this vendor?",
            handleAccepted: () => handleRemoveVendor(vendor)
        })
    }

    const handleRemoveVendor = async(vendor) => {
        await vendorsAPI.findOneVendorAndDelete(vendor.uuid)
        await vendorsAPI.findAllVendors();
    }

    useEffect(() => {
        setFilteredVendors(displayedVendors);
    }, [displayedVendors]);


    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilter(value);
        const filteredVendors = displayedVendors.filter(vendor =>
            vendor._id.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            vendor.uuid.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            vendor.name.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            vendor.alias.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            vendor.email.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            vendor.areas.some(area => area.name.replace(/ {2,}/g, " ").replace('_',' ').toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredVendors(filteredVendors);
    };

    const handleEditVendor = (vendor: Vendor) => {
        // dispatch(setSelectedVendorForEdit(vendor))
        navigate(`/vendors/${vendor._id}/edit`)
    }


    const toggleViewMode = (mode: 'list' | 'grid') => {
        setViewMode(mode === 'list' ? 'grid' : 'list');
    };

    return (
        <PageContainer title={"Vendors list"}>
                <Box display="flex">
                    <TextField
                            label={
                                <Typography variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}> Filter by name, alias, email or areas </Typography>
                              }
                            variant="outlined"
                            color='secondary'
                            value={filter}
                            onChange={handleFilterChange}
                            sx={{marginBottom: 2, marginRight: 2, height: "40px", fontSize: "10pt", letterSpacing: -0.5}}
                            fullWidth
                            size='small'
                        />
                        
                    <ToggleButtonGroup
                            value={viewMode}
                            exclusive
                            aria-label="text alignment"
                            sx={{marginBottom: 2, height: "40px"}}
                        >
                            <ToggleButton value="list" aria-label="list" onClick={(mode) => toggleViewMode('grid')}>
                                <ViewList />
                            </ToggleButton>
                            <ToggleButton value="grid" aria-label="grid" onClick={(mode) => toggleViewMode('list')}>
                                <ViewModule />
                            </ToggleButton>


                    </ToggleButtonGroup>
                    
                </Box>
                
                {viewMode === 'list' ? (
                        <VendorsAsList vendors={displayedVendors}
                                        filteredVendors={filteredVendors}
                                        setFilteredVendors={setFilteredVendors}
                                        handleEditVendor={handleEditVendor}
                                        onDelete={onDelete}
                        />
                ) : (
                        <VendorsAsGrid vendors={displayedVendors}
                                        filteredVendors={filteredVendors}
                                        handleEditVendor={handleEditVendor}
                                        onDelete={onDelete}
                        />
                )}
                <ConfirmDialog {...modalProps} />
            
        </PageContainer>
    );
};

export default OurVendors;

export class Space {
  _id?: string
  id: string
  createdAt: string
  name: string
  surfaces: string[]
  surfaceMarkers: SurfaceMarkers
  thumbnailPath: string
  uncroppedThumbnailPath: string
  width: number
  height: number
  spaceType: string
  spaceTypeId: number
  spaceTags: any[]
  space3dModel: string
  isAssigned?: boolean
  uploadedByVisitor?: boolean
  defaultOrientation?: DefaultOrientations
  mask?: string[]
  planes?: Planes

  constructor(){

  }
}

export interface SurfaceMarkers {
  floor?: Floor[]
  walls?: Wall[]
  ceiling?: Ceiling[]
}

export interface DefaultOrientations {
  floor?: number
  walls?: number[]
  ceiling?: number
}

export interface Planes {
  floor?: string[]
  walls?: string[]
  ceiling?: string[]
}


export interface Floor {
  x?: number
  y?: number
}

export interface Wall {
  id?: number
  x?: number
  y?: number
}

export interface Ceiling {
  x?: number
  y?: number
}


export class Surface {
  surfaceType: string
  index: number
  maskIndex: number

  surface: string;
  markers: Floor | Wall | Ceiling;
  defaultOrientation: number;
  mask: string;
  planes: Array<number>;

  constructor(surfaceType: string, index?: number){
    this.surfaceType = surfaceType
    this.index = index
  }

  public parseExistingNonWallSurface(space, surface): this{
    this.surface = surface
    this.markers = space.surfaceMarkers[this.surface] ? space.surfaceMarkers[this.surface][0] : undefined
    this.defaultOrientation = space.defaultOrientation ? space.defaultOrientation[this.surface] as number : undefined
    this.mask = space.mask?.find(maskPath => maskPath.includes(this.surface)) || ""
    this.maskIndex = space.mask?.findIndex(maskPath => maskPath.includes(this.surface)) 
    this.planes = space.planes?space.planes[this.surface]:undefined
    return this
  }

  public parseExistingWallSurface(space, surface, marker): this {
    const planes = space.planes?space.planes.walls:undefined;
    const wallOrientations = space.defaultOrientation?.walls as number[];
    this.surface = `walls_${this.index}`
    this.markers = marker
    this.defaultOrientation = wallOrientations ? wallOrientations[this.index]:undefined
    this.mask = space.mask?.find(maskPath => maskPath?.includes(`walls_${this.index}`)) || ""
    this.maskIndex = space.mask?.findIndex(maskPath => maskPath?.includes(`walls_${this.index}`))
    this.planes = planes && planes[this.index] ? planes[this.index] : undefined
    return this
  }

  public setIndex(index): this {
    this.index = index
    return this
  }

  public setSurface(surface): this{
    if(this.surfaceType === "wall")
          this.surface = `${surface}_${this.index}`
    else this.surface = surface
    return this
  }

  public setMarker(marker): this{
    this.markers = marker
    return this
  }

  public setDefaultOrientation(orientation): this{
    this.defaultOrientation = orientation
    return this
  }

  public setMask(mask): this{
    this.mask = mask
    return this
  }

  public setPlanes(planes): this{
    this.planes = planes
    return this
  }


}
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Vendor } from "../models/vendor.model";
import { useVendorsApi } from "../../../../common/hooks/use-vendors-api.hook";

export const VendorView = ({selectedVendorForViewID, setSelectedVendorForView}) => {
    const [vendor, setVendor] = useState<Vendor>()
    const vendorsAPI = useVendorsApi()

    useEffect(() => {
        const getVendor = async () => {
            const result = await vendorsAPI.findOneVendor(selectedVendorForViewID)
            setVendor(result)
        }
        getVendor()
    }, [selectedVendorForViewID])

    return (
        <Fragment>
            <Dialog
                onClose={() => setSelectedVendorForView(null)}
                open={vendor !== null}
                fullScreen={true}

            >
                {vendor && <DialogTitle sx={{ m: 0, p: 2 }} >
                    <Typography color={"primary.text"} fontSize={20} fontWeight={"bolder"} letterSpacing={0}>
                        {vendor.name} - {vendor.alias}
                    </Typography>
                </DialogTitle>}
                <IconButton
                aria-label="close"
                onClick={() => setSelectedVendorForView(null)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <Close />
                </IconButton>
                {vendor && <DialogContent dividers >
                    <Grid container spacing={10}>
                        <Grid item md={6} xs={12} sm={12}>
                            <img src={vendor.email} width={"100%"} style={{borderRadius: 6}}></img>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                           <table>
                                <tbody>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Number of products: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{vendor.products.length}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Number of spaces: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{vendor.spaces.length}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Email: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{vendor.email}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Areas: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} textTransform={"capitalize"} marginLeft={2}>{vendor.areas.map(e => e.name).join(',')}</Typography></td>
                                    </tr>
                                </tbody>
                           </table>

                        </Grid>
                    </Grid>
                    

                    
                   
                </DialogContent>}
            </Dialog>
        </Fragment>
    );
}
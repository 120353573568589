// TYPES
import HardwoodIcon from        "../../../../assets/icons/products/hardwood-icon.png"
import LaminateIcon from        "../../../../assets/icons/products/laminate-icon.png"
import TileIcon from            "../../../../assets/icons/products/tile-icon.png"
import CarpetingIcon from       "../../../../assets/icons/products/carpeting-icon.png"
import VinylIcon from           "../../../../assets/icons/products/vinyl-icon.png"
import StonesIcon from          "../../../../assets/icons/products/stones-icon.png"
// PATTERNS
import HerringboneIcon              from "../../../../assets/icons/products/patterns/herringbone-icon.png"
import DoubleHerringboneIcon        from "../../../../assets/icons/products/patterns/double-herringbone-icon.png"
import ChevronIcon                  from "../../../../assets/icons/products/patterns/chevron-icon.png"
import WoodStripIcon                from "../../../../assets/icons/products/patterns/wood-strip-icon.png"
import BrickBondIcon                from "../../../../assets/icons/products/patterns/brick-bond-icon.png"
import StackBondIcon                from "../../../../assets/icons/products/patterns/stack-bond-icon.png"
import CheckerboardIcon             from "../../../../assets/icons/products/patterns/checkerboard-icon.png"
import MosaicIcon                   from "../../../../assets/icons/products/patterns/mosaic-icon.png"
import OpusIcon                   from "../../../../assets/icons/products/patterns/opus-icon.png"
import CabochonIcon                   from "../../../../assets/icons/products/patterns/cabochon-icon.png"
import HexagonIcon                   from "../../../../assets/icons/products/patterns/hexagon-icon.png"

class Pattern {
    name: string
    icon
    constructor(name, icon){
        this.name = name
        this.icon = icon
    }
}

class Type {
    name: string
    icon
    patterns: Pattern[]
    constructor(name: string, icon){
        this.name = name
        this.icon = icon
        this.patterns = []
    }

    addPattern = (patternName: string, patternIcon) => {
        this.patterns.push(new Pattern(patternName, patternIcon))
        return this
    }
}

export const PRODUCT_TYPES_PATTERNS = {
    floor: [
        (new Type("hardwood", HardwoodIcon))
                                            .addPattern("bricks", BrickBondIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            .addPattern("herringbone", HerringboneIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            // .addPattern("double herringbone", DoubleHerringboneIcon)
                                            // .addPattern("wood strip", WoodStripIcon)
                                            .addPattern("chevron", ChevronIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            .addPattern("stack bond", StackBondIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) grout(mm) groutColor
                                            // .addPattern("mosaic", MosaicIcon)
                                            // .addPattern("checkerboard", CheckerboardIcon)
                                            ,
        (new Type("tile", TileIcon))
                                            .addPattern("bricks", BrickBondIcon)  // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            .addPattern("herringbone", HerringboneIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            .addPattern("chevron", ChevronIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) 
                                            .addPattern("opus", OpusIcon) // lengthOpus(cm) (4) textureWidth(m) ==>>> Max 4 pictures
                                            .addPattern("cabochon", CabochonIcon) // plankCenter(cm) plankCorner(cm) textureWidth(m) grout(mm) groutColor =>>> upload corner + center
                                            //.addPattern("mosaic", MosaicIcon)
                                            .addPattern("stack bond", StackBondIcon) // plankWidth(cm) plankHeight(cm) textureWidth(m) textureHeight(m) grout(mm) groutColor
                                            .addPattern("hexagonal", HexagonIcon), // lengthHexagonal(cm) textureWidth(m) textureHeight(m) grout(mm) groutColor
        // (new Type("carpeting", CarpetingIcon))
    ],
    walls: [
        (new Type("hardwood", HardwoodIcon))
                                            .addPattern("bricks", BrickBondIcon)
                                            .addPattern("herringbone", HerringboneIcon)
                                            // .addPattern("double herringbone", DoubleHerringboneIcon)
                                            // .addPattern("wood strip", WoodStripIcon)
                                            .addPattern("chevron", ChevronIcon)
                                            .addPattern("stack bond", StackBondIcon),
        (new Type("tile", TileIcon))
                                            .addPattern("bricks", BrickBondIcon)
                                            .addPattern("herringbone", HerringboneIcon)
                                            .addPattern("chevron", ChevronIcon)
                                            .addPattern("opus", OpusIcon)
                                            .addPattern("cabochon", CabochonIcon)
                                            //.addPattern("mosaic", MosaicIcon)
                                            .addPattern("stack bond", StackBondIcon)
                                            .addPattern("hexagonal", HexagonIcon),
        //(new Type("vinyl", VinylIcon)),
        (new Type("stones", StonesIcon))
                                            .addPattern("bricks", BrickBondIcon)
    ]

} 


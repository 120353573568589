
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { Divider, ListItemIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Lock, ReorderRounded } from '@mui/icons-material';
import { Link, useLocation, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VendorState } from '../../redux/vendor/rootReducer';
import { theme as providerTheme } from '../../theme';
import { toggleDrawer } from '../../redux/vendor/ui/uiActions';
import ardealyBlackLogo from "../../assets/ardealy-black-logo.png"
import LogoutIcon from "../../assets/icons/logout-icon.png"
import FadeIn from 'react-fade-in/lib/FadeIn';
import { AuthContext } from '../../providers/AuthProvider';
import { VendorMenu } from '../../modules/vendor/side-bar/vendor.sidebar-menu';
import { MenuCustomItem } from './MenuCustomItem';
import { SuperAdminMenu } from '../../modules/superadmin/side-bar/super-admin.sidebar-menu';
import { useAuthContext } from '../hooks/use-auth-context.hook';


export function SideBar() {
  const { isAuthenticated, isSuperAdmin, login, logout, me } = useAuthContext();

  const { isDrawerOpen } = useSelector((state: VendorState) => state.ui);
  const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const logOut = async () => {
    try {
      logout()
      //checkAccess()
      navigate('/')
    } catch (err){
    }
  };

  useEffect(() => {
    dispatch(toggleDrawer(false))
  }, [])

  return (<>
    {!isMobileAndDown && 
      <Box sx={{ flexGrow: 1, paddingLeft: 1, paddingRight: 1, width: "25rem", height: '96vh', borderRadius: "8px", borderTopRightRadius: 0, borderBottomRightRadius: 0, color: "white", display: "flex", flexDirection: "column", bgcolor: 'primary.light'}}>
        <Box sx={{padding: 3, display: "flex", height: "50px"}}>
          <img src={ardealyBlackLogo} style={{width: "50px", height: "50px"}}/>
          <Box sx={{paddingLeft: 1, alignItems: "baseline", display: "flex", flexDirection: "column", justifyContent: "end"}}>
            <Typography color={"primary.contrastText"} lineHeight={1} fontSize={12}>powered by </Typography>
            <Typography color={"primary.contrastText"} fontWeight={"bolder"} lineHeight={1} fontSize={14}>ardealy</Typography>
          </Box>
        </Box>
        {isSuperAdmin && <SuperAdminMenu isDrawerOpen={isDrawerOpen}/>}
        {!isSuperAdmin && <VendorMenu isDrawerOpen={isDrawerOpen}/>}
        <Box marginBottom={2} onClick={() => logOut()}>
          <Divider sx={{marginBlock: 1}}/>
          <MenuCustomItem route={"/logout"} title={t('SIDEBAR.LOGOUT')} Icon={LogoutIcon}  />
        </Box>
      </Box>    
    }
    {isMobileAndDown &&
      <Box sx={{  flexGrow: 1, width: !isDrawerOpen ? "50px": 'calc(100vw - 40px)', height: 'calc(100vh - 40px)', transition: "width 0.5s ease-in-out",
                  color: "white", position: isDrawerOpen ? "absolute":"relative", 
                  zIndex: 1000, backgroundColor: "#F7F8F9", borderRadius: "15px"}}>
        <FadeIn>
          {isSuperAdmin && <SuperAdminMenu isDrawerOpen={isDrawerOpen}/>}
          {!isSuperAdmin && <VendorMenu isDrawerOpen={isDrawerOpen}/>}
        </FadeIn>
      </Box>}
    </>
  );
}


export const MenuSectionTitle = ({title}) => {
  return <Typography textTransform={"uppercase"} color={"primary.contrastText"} paddingTop={2} paddingBottom={1} fontWeight={"bolder"} sx={{paddingLeft: 2}} fontSize={"10pt"}>{title}</Typography>
}


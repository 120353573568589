
import { Add } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Surface } from "../../../vendor/my-spaces/models/space.model";
import * as _ from "lodash"
import { SpaceSurface } from "./space-surface.form";

export const SpacesSurfaces = ({space, setSpace, submitted, setIsValid}) => {
    const [t,] = useTranslation();
    const [surfaces, setSurfaces] = useState([])

    const prepareSurfaces = () => {
        if(surfaces.length === 0){
            const surfacesArray: Surface[] = [];
            space.surfaces?.forEach((surface) => {
                if (surface === "walls") {
                    // Handle each wall separately
                    const wallMarkers = space.surfaceMarkers.walls;
                    wallMarkers?.forEach((marker, index) => {
                        const preparedSurface = new Surface("wall", index)
                        surfacesArray.push(preparedSurface.parseExistingWallSurface(space, surface, marker))
                    });
                } else {
                    const preparedSurface = new Surface(surface)
                    surfacesArray.push(preparedSurface.parseExistingNonWallSurface(space, surface))
                }
            });
            setSurfaces(surfacesArray)
        }
    }

    useEffect(() => {
        if(space){
            prepareSurfaces()
        }
    }, [space])

    const handleSurfaceAdd = (surfaceType: string) => {
        const newSurface = new Surface(surfaceType)
        if(surfaceType === "wall"){
            newSurface.setIndex(space.surfaceMarkers?.walls.length)
        }
        newSurface.setSurface(surfaceType)
        setSurfaces([...surfaces, newSurface])
    }

    return <Box marginBottom={7}>
                {surfaces.map((surface, index) => (
                    <SpaceSurface   key={index} 
                                    surface={surface} 
                                    surfaces={surfaces}
                                    setSurfaces={setSurfaces}
                                    space={space} 
                                    setSpace={setSpace}
                                    setIsValid={setIsValid}
                                    submitted={submitted}  />
                ))}
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <Box bgcolor={"secondary.main"} padding={2} borderRadius={3} position={"relative"} 
                                display={"flex"} 
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{cursor: "pointer"}}
                                onClick={() => handleSurfaceAdd("floor")}
                                flexDirection={"row"} marginBottom={2}>
                            <Add sx={{ color: "white" }} />
                            <Typography fontSize={14} color={"white"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Add new floor</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box bgcolor={"secondary.main"} padding={2} borderRadius={3} position={"relative"} 
                                display={"flex"} 
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{cursor: "pointer"}}
                                onClick={() => handleSurfaceAdd("wall")}
                                flexDirection={"row"} marginBottom={2}>
                            <Add sx={{ color: "white" }} />
                            <Typography fontSize={14} color={"white"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Add new wall</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box bgcolor={"secondary.main"} padding={2} borderRadius={3} position={"relative"} 
                                display={"flex"} 
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{cursor: "pointer"}}
                                onClick={() => handleSurfaceAdd("ceiling")}
                                flexDirection={"row"} marginBottom={2}>
                            <Add sx={{ color: "white" }} />
                            <Typography fontSize={14} color={"white"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} >Add new ceiling</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
}
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2"
import { fillMissingDates, randomHexColorCode } from "../../../../common/utils/utils";
import FadeIn from "react-fade-in";
import { useProductsApi } from "../../../../common/hooks/use-products-api.hook";
import { useAnalyticsApi } from "../../../../common/hooks/use-analytics-api.hook";

export const EngagementsAnalytics = ({startDate, endDate}) => {
    const [engagementsData, setEngagements] = useState([])
    const [labels, setLabels] = useState()
    const chartRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [productsToDisplay, setProductsToDisplay] = useState([])
    const [fullProductsToDisplay, setFullProductsToDisplay] = useState([])
    const productsAPI = useProductsApi()
    const analyticsAPI = useAnalyticsApi()

    const getEngagementsAnalytics = async () => {
        let data = await analyticsAPI.queryAnalytics({
            statType: "EVENT_USAGE_COUNT",
            startDate: startDate,
            endDate: endDate
        })
        data = fillMissingDates(data)
        setEngagements(data)
        setLabels(data.map(e => e._id))
    }

    const getProducts = async () => {
      if(productsToDisplay && productsToDisplay.length > 0){
        const promises = []
        productsToDisplay.forEach(async(product) => {
            promises.push(productsAPI.findOneProduct(product.productId))
        })
        const data = await Promise.all(promises)
        setFullProductsToDisplay(data)
      } else {
        setFullProductsToDisplay([])
      }
    }

    useEffect(() => {
        getEngagementsAnalytics()
    }, [startDate, endDate])

    useEffect(() => {
      getProducts()
    }, [productsToDisplay])

    useEffect(() => {
      const chartInstance = chartRef.current;
      if (chartInstance) {
        const canvas = chartInstance.canvas;
        const handleClick = (event) => {
          const points = chartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
          if (points.length) {
            const firstPoint = points[0];
            setHighlightedIndex(firstPoint.index);
          } else {
            setHighlightedIndex(null);
          }
        };
        canvas.addEventListener('click', handleClick);
        return () => {
          canvas.removeEventListener('click', handleClick);
        };
      }
    }, []);
  
    useEffect(() => {
      const chartInstance = chartRef.current;
      if (chartInstance) {
        setProductsToDisplay(highlightedIndex >=0 ? engagementsData[highlightedIndex]?.products:[])
        chartInstance.update();
      }
    }, [highlightedIndex]);

    const getEngagementsCount = (product) => {
      return engagementsData[highlightedIndex]?.products?.find(p => p.productId === product.id)?.usageCount
    }

    return <FadeIn>
      <Chart 
        type="line"
        ref={chartRef}
        style={{"width": "100%"}}
        data={{
            labels: labels,
            datasets: [
                {
                  label: 'Space selection',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "SpaceSelectedEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                  borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Product change',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "ProductUsageEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Uploads',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "SpaceUploadClickedEvent" || event.eventType === "SpaceUploadQRCodeClickedEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Search',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "SearchedProductsEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Rotation',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "ProductRotatedEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Compare',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "ProductCompareClickedEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                },
                {
                  label: 'Download',
                  data: engagementsData.map(usage => ({
                      ...usage,
                      events: usage.events?.filter(event => event.eventType === "ProductResultDownloadedEvent")
                    })).map(usage => usage.events ? (usage.events[0]?usage.events[0].count:0) : 0),
                    backgroundColor: randomHexColorCode(),
                    borderWidth: 0,
                  type: 'bar',
                  stack: 'Stack 0',
                }
              ],
        }} 
        options={{
            responsive: true,
            plugins: {
                tooltip: {
                    intersect: false
                },
                title: {
                    display: true,
                    text: 'Engagements by day'
                }
                },
                hover: {
                    mode: 'nearest',
                },
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Day'
                        }
                    },
                    y: {
                      stacked: true,
                      title: {
                          display: true,
                          text: 'Usages'
                      },
                      min: 0,
                      ticks: {
                          stepSize: 1
                      }
                    }
                }
        }}/>
      {/* <Box>
        {fullProductsToDisplay && fullProductsToDisplay.map(product => 
          <Box marginBottom={2} display={"flex"} sx={{backgroundColor: "primary.main", padding: 1, borderRadius: 4}}>
            <img src={product.thumbnailPath} width={"100px"} style={{border: "2px solid lightgray", borderRadius: "8px", cursor: "pointer"}}
              onClick={() => setSelectedProductForView(product.id)}
            />
            <Box flex={2} paddingLeft={3}>

              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "9pt", letterSpacing: -0.5}}>{product.brand}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}} fontWeight={"bolder"}>{product.name}</Typography>

              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Color: {product.color}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Size: {product.sizeName}</Typography>
              <Typography color='primary.text' textTransform="capitalize" 
                sx={{fontSize: "10pt", letterSpacing: -0.5}}>Pattern: {product.pattern}</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"}>
              <Typography color='primary.text' 
                sx={{fontSize: "10pt", letterSpacing: -0.5, marginBottom: -1.5}}>
                  Used
              </Typography>
              <Typography color='primary.text' fontWeight={"bolder"}
                sx={{fontSize: "28pt", letterSpacing: -0.5}}>
                  {getEngagementsCount(product)}
              </Typography>
              <Typography color='primary.text' 
                sx={{fontSize: "10pt", letterSpacing: -0.5, marginTop: -1.5}}>
                  times
              </Typography>
            </Box>
            {selectedProductForView && <ProductView selectedProductForViewID={selectedProductForView} setSelectedProductForView={setSelectedProductForView} />}
          </Box>
        )}
      </Box> */}
      </FadeIn>
}
import React, { useEffect, useState } from 'react';
import { Typography,  Box, Grid, Divider, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../common/components/PageContainer';
import { useNavigate } from 'react-router-dom';
import { EastRounded, ExpandLessRounded, ExpandMoreRounded, VideocamOutlined } from '@mui/icons-material';
import PlaceholderLoading from 'react-placeholder-loading'
import FadeIn from "react-fade-in";
import Carousel from 'react-material-ui-carousel'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { theme as providerTheme } from '../../../theme';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import { format } from 'date-fns';

import VendorStats from '../../../modules/vendor/settings/models/stats.model';
import ProductsIcon from "../../../assets/icons/dashboard-products-icon.png"
import AnalyticsIcon from "../../../assets/icons/dashboard-analytics-icon.png"
import AddProductIcon from "../../../assets/icons/dashboard-add-product-icon.png"
import { ProductUsageAnalytics } from '../../../modules/vendor/analytics/components/product-usage-analytics.components';
import { useAnalyticsApi } from '../../../common/hooks/use-analytics-api.hook';

export const Dashboard = () => {
    const [t] = useTranslation();
    const [vendorStats, setVendorStats] = useState<VendorStats>(undefined)
    const [vendorSessions, setVendorSessions] = useState<any>("")
    const [vendorEngagement, setVendorEngagement] = useState<any>("")
    const [selectedDayStart, setSelectedDayStart] = useState<Date>(new Date())
    const [selectedDayEnd, setSelectedDayEnd] = useState<Date>(new Date())
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
    const theme = useTheme()
    const analyticsAPI = useAnalyticsApi()

    useEffect(() => {
        const initStats = async () => {
            setVendorStats(await analyticsAPI.findAllAnalytics())
            const fiveYearsAgo = new Date();
            fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
            const sessions = await analyticsAPI.queryAnalytics({
                statType: "VISITS_COUNT",
                startDate: fiveYearsAgo.toISOString(),
                endDate: (new Date()).toISOString()
            })
            const productUsage = await analyticsAPI.queryAnalytics({
                statType: "PRODUCT_USAGE_COUNT",
                startDate: fiveYearsAgo.toISOString(),
                endDate: (new Date()).toISOString()
            })
            setVendorSessions(sessions.map(e => e.count).reduce((acc, e) => acc + e, 0))
            setVendorEngagement(productUsage.map(e => e.count).reduce((acc, e) => acc + e, 0))
        }
        initStats()
        selectedDayStart?.setHours(0, 0, 0, 0)
        selectedDayEnd?.setHours(23, 59, 59, 59)
    }, []);

    var items = [
        {
            title: "The UK Flooring",
            description: "We are pleased to announce that ARdealy successfully participated in The UK Flooring 2024, a key event that brings together industry leaders, innovators, and professionals."
        }
    ]

    const renderDay = (date, selectedDates, pickersDayProps) => {
        // Customize the format here (e.g., 'dd' for day of the month)
        const formattedDay = format(date, 'dd');
    
        return (
          <div style={{
            backgroundColor: pickersDayProps.selected ? 'blue' : 'transparent',
            color: pickersDayProps.selected ? 'white' : 'black',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {formattedDay}
          </div>
        );
      };

    return (
        <PageContainer title={t('PAGES.DASHBOARD.TITLE')}>
            <Grid container spacing={1} flex={2} padding={isMobileAndDown?0:2}>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={1} flex={2} >
                        <Grid item md={4} xs={12}>
                            {!vendorStats && <PlaceholderLoading shape="rect" width={100} height={60} />}
                            {vendorStats && <DashboardStatBox 
                                title={"Products"}
                                data={vendorStats.products.activeProducts}
                                icon={ProductsIcon}
                                iconFilter={"invert(59%) sepia(16%) saturate(724%) hue-rotate(180deg) brightness(89%) contrast(88%)"}
                                actionTitle={"View Catalog"}
                                actionRoute={"/products/catalog"}
                            />}
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <DashboardStatBox 
                                title={"Sessions"}
                                data={vendorSessions}
                                icon={AnalyticsIcon}
                                iconFilter={"invert(79%) sepia(10%) saturate(894%) hue-rotate(356deg) brightness(86%) contrast(92%)"}
                                actionTitle={"View Analytics"}
                                actionRoute={"/engagement/analytics"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <DashboardStatBox 
                                title={"Engagement"}
                                data={vendorEngagement}
                                icon={AnalyticsIcon}
                                iconBackground={"#e36809"}
                                actionTitle={"View Analytics"}
                                actionRoute={"/engagement/analytics"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <DashboardActionBox 
                                title={"Add new product"}
                                data={"20"}
                                icon={AddProductIcon}
                                iconBackground={"#e36809"}
                                actionTitle={"View Analytics"}
                                actionRoute={"/products/new"}
                            />
                        </Grid>
                        <Grid item md={8} xs={12} >
                            <Box bgcolor={'primary.light'} padding={2} borderRadius={3} position={"relative"}>
                                <Typography fontSize={18} letterSpacing={-0.5} fontWeight={"Bolder"} color={"primary.contrastText"} textTransform={'capitalize'} marginBottom={1}>{"Analytics"}</Typography>
                                <Divider />
                                <ProductUsageAnalytics startDate={selectedDayStart} endDate={selectedDayEnd}/>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid item md={12}>
                        <Box sx={{      background: theme.palette.mode === "light" && "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)",
                                        backgroundColor: theme.palette.mode === "dark" && "secondary.light"
                        }} padding={2} borderRadius={3} height={"100px"} position={"relative"}>
                            <Typography fontSize={12} letterSpacing={-0.5} fontWeight={"lighter"} color={"white"} textTransform={'capitalize'}>{"Recent news"}</Typography>
                            <Carousel indicatorContainerProps={{
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                >
                                {
                                    items.map( (item, i) => (
                                        <Box height={"65px"} key={i}>
                                            <Typography fontSize={22} letterSpacing={-0.5} color="white" textTransform={'capitalize'}>{item.title}</Typography>
                                            <Typography fontSize={12} letterSpacing={-0.5} color="white">{item.description}</Typography>
                                        </Box>
                                    ) )
                                }
                            </Carousel>
                            
                            
                        </Box>
                    </Grid>
                    <Grid item md={12} bgcolor={"primary.light"} padding={1} marginTop={1} borderRadius={3}>
                        <Typography fontSize={18} letterSpacing={-0.5} fontWeight={"Bolder"} color={"primary.contrastText"} textTransform={'capitalize'} marginBottom={1}>{"Agenda"}</Typography>
                        <Divider />
                        {/* <Calendar value={new Date()} showNavigation={false} goToRangeStartOnSelect={false} allowPartialRange={false} onClickDay={() => {}} showNeighboringMonth={false} /> */}
                        <DateCalendar disableFuture onChange={(value) => setSelectedDayStart(value)} />
                    </Grid>
                    <Grid item md={12} marginTop={1}>
                        <Box bgcolor={"#F7F8F9"} borderRadius={3} height={"120px"} display={"flex"}>
                            <Box flex={1} display="flex" justifyContent={"center"} alignItems={"center"}>
                                <VideocamOutlined sx={{ color: "white", width: "40px", height: "40px", background: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", padding: 2, borderRadius: 3}}/>
                            </Box>
                            <Box flex={2} display="flex"  justifyContent={"center"} flexDirection={"column"}>
                                <Typography fontSize={14} letterSpacing={-0.5}  >Need an assistance?</Typography>
                                <Typography fontSize={12} letterSpacing={-0.5} color="gray">Schedule a call with our support team</Typography>
                                <Box display={"flex"} alignItems={"center"} sx={{cursor: "pointer", bgcolor: "#003ca9", paddingInline: 1, borderRadius: 2, marginTop: 0.5, width: "fit-content", height: "25px"}}>
                                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} marginRight={1} color={"white"} padding={0}>Go to calendar</Typography>
                                    <EastRounded style={{fontSize: "13pt", color: "white", padding: 0}}/>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </PageContainer>
    );
};

export const DashboardStatBox = (props) => {
    const {title, data, icon, iconFilter, iconBackground, actionTitle, actionRoute} = props
    const navigate = useNavigate()

    return <Box bgcolor={'primary.light'} padding={2} borderRadius={3} height={"100px"} position={"relative"}>
        <Typography fontSize={12} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.contrastText"} textTransform={'capitalize'}>{title}</Typography>
        <Typography fontSize={40} letterSpacing={-0.5} color={"primary.text"} textTransform={'capitalize'}>
            <FadeIn>{`${data}`}</FadeIn>
        </Typography>
        <Box style={{
            position: "absolute",
            right: 20,
            top: 20,
            background: iconBackground ?? "none",
            padding: iconBackground ? "5px":0,
            width: 45, 
            height: 45,
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <img src={icon} style={{
                filter: iconFilter ?? "invert(100%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(107%) contrast(101%)",
                width: iconBackground ? 35: 45, 
                height: iconBackground ? 35: 45,
            }}/>
        </Box>
        <Divider />
        <Box display={"flex"} alignItems={"center"} sx={{cursor: "pointer"}} onClick={() => navigate(actionRoute)}>
            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} color={"primary.contrastText"} textTransform={'capitalize'} marginBlock={1} marginRight={1}>{actionTitle}</Typography>
            <EastRounded sx={{fontSize: "13pt", color: "primary.contrastText"}}/>
        </Box>
        
    </Box>
}

const DashboardActionBox = (props) => {
    const {icon, actionRoute} = props
    const [selectedValue, setSelectedValue] = useState()
    const navigate = useNavigate()

    return <Box style={{background: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.80) 11.0%, rgb(63, 61, 61) 78.9%)"}} padding={2} borderRadius={3} height={"100px"} position={"relative"} overflow={"hidden"} display={"flex"} flexDirection={"column"}>
        <Typography fontSize={18} letterSpacing={-0.5} color="white" >
            <FadeIn>Add new product</FadeIn>
        </Typography>
        <Box style={{
            padding: 0,
            width: "100%", 
            height: 120,
            borderRadius: "5px",
            display: "flex",
            alignItems: "end",
            flexDirection: "row",
            zIndex: 1
        }}>

            {/* <DropDown 
                width={"180px"}
                height={"30px"}
                bgColor={"white"}
                color={"black"}
                labelText={"Select product type"}
                selected={selectedValue}
                handleValueChange={(value) => {setSelectedValue(value)}}
                items={[{
                    text: "Floor"
                }, {
                    text: "Walls"
                }, {
                    text: "Ceiling"
                }]} /> */}
            <Box  display={"flex"} alignItems={"center"} justifyContent={"center"} onClick={() => navigate(actionRoute)}
                sx={{cursor: "pointer", bgcolor: "black", border: "2px solid white", paddingInline: 1, marginInline: 1, borderRadius: 2, width: "fit-content", height: "25px"}}>
                    <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} color={"white"} padding={0}>Go</Typography>
            </Box>
            
        </Box>
        <img src={icon} style={{
            filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(107%) contrast(101%)",
            width: "50%", 
            position: "absolute",
            right: -15,
            bottom: -10,
            opacity: 14/100,
            zIndex: 0
        }}/>
       
        
    </Box>
}

const DropDown = ({selected, items, width, height, bgColor, color, labelText, handleValueChange}) => {
    const [isOpen, setIsOpen] = useState(false)

    const [selectedValue, setSelectedValue] = useState(selected)

    useEffect(() => {
        setSelectedValue(selected)
    }, [selected])

    const useOutsideClick = (callback) => {
        const ref = React.useRef();
      
        React.useEffect(() => {
          const handleClick = (event) => {
            //@ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            }
          };
          document.addEventListener('click', handleClick);
      
          return () => {
            document.removeEventListener('click', handleClick);
          };
        }, [ref]);
      
        return ref;
      };

    const ref = useOutsideClick(() => setIsOpen(false));

    return <Box bgcolor={bgColor} height={height} width={"fit-content"} borderRadius={50} display={"flex"} alignItems={"center"} flexDirection={"row"} position={"relative"}>
                <Box ref={ref} display={"flex"} alignItems={"center"} flexDirection={"row"} position={"relative"} sx={{ cursor: "pointer" }} width={width} onClick={() => setIsOpen(!isOpen)} >
                    <Typography fontSize={12} letterSpacing={-0.5} flex={2} zIndex={2} paddingInline={1} >{selectedValue ?? labelText}</Typography>
                    {!isOpen && <ExpandMoreRounded style={{zIndex: 2}}/>}
                    {isOpen && <ExpandLessRounded style={{zIndex: 2}}/>}

                </Box>
                {isOpen && <Box bgcolor={bgColor} width={width} 
                        display={"flex"} justifyContent={"center"} paddingTop={height} flexDirection={"column"} 
                        border={`1px solid ${color}`} borderRadius={"15px"}
                        sx={{ cursor: "pointer", position: "absolute", top: "0px", zIndex: 1 }}>
                            {items.map((item, index) => {
                                return <Box onClick={() => handleValueChange(item.text)} key={index}
                                            sx={{
                                                backgroundColor: "white",
                                                borderBottomLeftRadius: index !== items.length - 1 ? 0 : "15px",
                                                borderBottomRightRadius: index !== items.length - 1 ? 0 : "15px",
                                                //@ts-ignore
                                                "&:hover": {
                                                    backgroundColor: "#F7F8F9"
                                                }
                                            }}>
                                                <Divider />
                                                <Typography fontSize={12} letterSpacing={-0.5} color={color} marginBlock={0.7} paddingInline={1}>{item.text}</Typography>
                                            </Box>
                            })}
                </Box>}
            </Box>
}



export default Dashboard;

import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import { v4 } from 'uuid';

export const SeamlessResultDialog = ({isOpen, seamlessResult, setIsOpen}) => {
    return (
        <Fragment>
            <Dialog
                onClose={() => setIsOpen(false)}
                open={isOpen}
                fullScreen={true}

            >
                <IconButton
                aria-label="close"
                onClick={() => {
                    setIsOpen(false)
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    zIndex: 580000
                }}
                >
                    <Close />
                </IconButton>
                {seamlessResult && <DialogContent dividers sx={{display: "flex", justifyContent: "center", alignItems: "center", position: "relative"}}>
                    <a href={`data:image/png;base64,${seamlessResult}`} download={`${v4()}.jpg`}>
                        <Box position={"absolute"} bottom={10} right={10} bgcolor={"black"} padding={2} borderRadius={2} sx={{cursor: "pointer"}}>
                            <Typography fontSize={16} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} color={"primary.main"}>Download</Typography>
                        </Box>
                    </a>

                    {seamlessResult && <img src={`data:image/png;base64,${seamlessResult}`} style={{objectFit: "contain", maxWidth: "90vw", maxHeight: "90vh"}} />}
                </DialogContent>}
            </Dialog>
        </Fragment>
    );
}
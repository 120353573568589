import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VendorState } from "../../redux/vendor/rootReducer";
import { ListItemIcon, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { setBreadcrumbs, toggleDrawer } from "../../redux/vendor/ui/uiActions";
import { Box } from "@mui/system";
import { theme as providerTheme } from '../../theme';

export const MenuCustomItem = (props) => {
    const { route, title, Icon, children } = props
    const location: any = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { breadcrumbs } = useSelector((state: VendorState) => state.ui);
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
    const theme = useTheme()
    const { isDrawerOpen } = useSelector((state: VendorState) => state.ui);
    const [showText] = useState((!isMobileAndDown || isMobileAndDown && isDrawerOpen))
  
  
    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false)
  
    const menuItemCss = (route) => {
      return {
        backgroundColor: isSelected(route) ? "primary.main": "", 
        borderRadius: 4, 
        height: 30, 
        boxShadow: isSelected(route) ? "primary.main": "",
        "&:hover": {
          backgroundColor: "primary.main",
        },
        position: "relative"
      }
    }
  
    const isSelected = (route) => {
      return location.pathname === route
    }
  
    const navigateTo = (route) => {
      if(route){
        navigate(route)
        dispatch(setBreadcrumbs(route.substring(1).split("/")))
      }
    }
  
    const handleItemSelected = () => {
        navigateTo(route)
        if(showText && isMobileAndDown)
          dispatch(toggleDrawer(!isDrawerOpen))
        //setIsSubMenuVisible(!isSubMenuVisible)
    }
  
    return <Box position={"relative"}
              onMouseEnter={() => {children && setIsSubMenuVisible(true)}}
              onMouseLeave={() => {children && setIsSubMenuVisible(false)}}
            ><MenuItem to={route} 
              component={Link} sx={{...menuItemCss(route)}} onClick={handleItemSelected}>
              <ListItemIcon>
                <img src={Icon} width={18} style={{
                    //@ts-ignore
                    filter: theme.palette.mode === "dark" ? "invert(100%) sepia(33%) saturate(4617%) hue-rotate(182deg) brightness(120%) contrast(101%)" : "invert(0%) sepia(0%) saturate(0%) hue-rotate(34deg) brightness(99%) contrast(102%)",
                }}/>
              </ListItemIcon>
              {showText && <Typography sx={{color: isSelected(route)?"primary.text":"primary.contrastText", marginLeft: "-8px"}} letterSpacing={"-0.5px"} fontSize={"10pt"}>{title}</Typography>}
            
          </MenuItem>
          {children && <Box sx={{ paddingBlock: 1, position: "absolute", right: "-150px", top: -8, zIndex: 100000,
                                  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                  backgroundColor: "primary.light", borderEndEndRadius: "8px", borderStartEndRadius: "8px"}} >
              {isSubMenuVisible && children}
            </Box>}
          </Box>
  }
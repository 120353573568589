import { Product } from "../../../modules/vendor/my-products/models/product.model";
import { SET_EDIT_PRODUCT_INITIAL_VARIANTS, SET_EDIT_PRODUCT_STEP_VALIDITY, SET_PRODUCTS, VALIDATE_EDIT_PRODUCT_STEP } from "./productsActionTypes";

export interface RoomsState {
    products: Product[],
    editProductStepToValidate: number,
    editProductStepsValidity: any[]
    initialVariants: any[]
}

const initialState: RoomsState = {
    products: [],
    editProductStepToValidate: undefined,
    editProductStepsValidity: [],
    initialVariants: []
};

const productsReducer = (state = initialState, action): RoomsState => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case VALIDATE_EDIT_PRODUCT_STEP: 
            return {
                ...state,
                editProductStepToValidate: action.payload
            }
        case SET_EDIT_PRODUCT_STEP_VALIDITY: 
            return {
                ...state,
                editProductStepsValidity: [
                    ...state.editProductStepsValidity.filter(e => e.step !== action.payload.step),
                    {
                        step: action.payload.step,
                        stepValidity: action.payload.stepValidity
                    }]
            }
        case SET_EDIT_PRODUCT_INITIAL_VARIANTS: 
            return {
                ...state,
                initialVariants: action.payload
            }
        case "RESET":
            return {
                ...initialState
            }
        default:
            return state
    }
};

export default productsReducer;
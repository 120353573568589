import { useApi } from "./use-api.hook";
import AuthClientStore from "../services/auth-client.service";
import { ApiMethod } from "../models/api-types.type";
import { routes } from "../../assets/constants";
import { User } from "../models/user.model";

/*
 * These variables are used to debounce the refreshTokens function
 */
let debouncedPromise: Promise<unknown> | null;
let debouncedResolve: (...args: unknown[]) => void;
let debouncedReject: (...args: unknown[]) => void;
let timeout: number;

export const useAuthApi = () => {
    const { sendRequest, sendProtectedRequest } = useApi();

    const login = async (email: string, password: string) => {
        const response = await sendRequest(ApiMethod.POST, routes.auth.login, {
            email,
            password,
        });

        AuthClientStore.setAccessToken(response.access_token);
        AuthClientStore.setRefreshToken(response.refresh_token);

        return response;
    };

    const logout = () => {
        AuthClientStore.removeAccessToken();
        AuthClientStore.removeRefreshToken();
    };

    const refreshTokens = async () => {
        clearTimeout(timeout);
        if (!debouncedPromise) {
            debouncedPromise = new Promise((resolve, reject) => {
                debouncedResolve = resolve;
                debouncedReject = reject;
            });
        }

        //@ts-ignore
        timeout = setTimeout(() => {
            const executeLogic = async () => {
                const response = await sendProtectedRequest(
                    ApiMethod.POST,
                    routes.auth.refreshTokens,
                    undefined,
                    AuthClientStore.getRefreshToken() ? true : false,
                );
                if(response){
                    AuthClientStore.setAccessToken(response.access_token);
                    AuthClientStore.setRefreshToken(response.refresh_token);
                }
            };

            executeLogic().then(debouncedResolve).catch(debouncedReject);

            debouncedPromise = null;
        }, 1000)

        return debouncedPromise;
    };

    const sendAuthGuardedRequest = async (
        userIsNotAuthenticatedCallback: () => void,
        method: ApiMethod,
        path: string,
        // eslint-disable-next-line
        body?: any,
        init?: RequestInit,
    ) => {
        try {
            return await sendProtectedRequest(method, path, body, undefined, init);
        } catch (e: any) {
            if (e?.status === 401) {
                try {
                    await refreshTokens();
                } catch (e) {
                    userIsNotAuthenticatedCallback();
                    logout();
                }
                return await sendProtectedRequest(method, path, body, undefined, init);
            }
            else if (e?.status === 403) {
                logout();
            }
        }
    };

    const me = (userIsNotAuthenticatedCallback: () => void) => {
        return sendAuthGuardedRequest(
            userIsNotAuthenticatedCallback,
            ApiMethod.GET,
            routes.auth.me,
        ) as Promise<User>;
    };

    return { login, logout, me, sendAuthGuardedRequest, refreshTokens };
};
import { Box, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useVendorsApi } from "../../../../common/hooks/use-vendors-api.hook";

export const UserGeneralInformation = ({user, setUser, check}) => {
    const [t,] = useTranslation();
    const [vendors, setVendors] = useState([])
    const ROLES = ["SUPER_ADMIN", "VENDOR_ADMIN"]
    let { userId } = useParams();
    const vendorsAPI = useVendorsApi()

    const [fields, setFields] = useState({
        firstname: {
            isTouched: false,
            label: "First Name*",
            name: "firstname"
        },
        lastname: {
            isTouched: false,
            label: "Last Name*",
            name: "lastname"
        },
        email: {
            isTouched: false,
            label: "Email*",
            name: "email"
        },
        password: {
            isTouched: false,
            label: "Password*",
            name: "password"
        },
        user: {
            isTouched: false,
            label: "User*",
            name: "user"
        },
        vendor: {
            isTouched: false,
            label: "Vendor*",
            name: "vendor"
        },
        role: {
            isTouched: false,
            label: "Role*",
            name: "roles"
        }
    })

    const initVendors = async() => {
        const vendors = await vendorsAPI.findAllVendors()
        setVendors(vendors)
    }

    useEffect(() => {
        initVendors()
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setUser({ ...user, [name]: value })
    }

    const handleVendorChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setUser({ ...user, [name]: vendors.find(vendor => vendor._id === value) })
    }

    const handleRoleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setUser({ ...user, [name]: [value] })
    }

    const isValidInput = (name) => {
        return (check && !user[name]) || (fields && fields[name] && fields[name].isTouched && !user[name])
    }

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE")}</Typography>
    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        <Grid item md={4} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.firstname.label}</Typography>
            <TextField
                    error={isValidInput(fields.firstname.name)}
                    name={fields.firstname.name}
                    value={user?user.firstname:""}
                    onChange={handleChange}
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={4} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.lastname.label}</Typography>
            <TextField
                    error={isValidInput(fields.lastname.name)}
                    name={fields.lastname.name}
                    value={user?user.lastname:""}
                    onChange={handleChange}
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={4} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.email.label}</Typography>
            <TextField
                    error={isValidInput(fields.email.name)}
                    name={fields.email.name}
                    value={user?user.email:""}
                    onChange={handleChange}
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        {!userId && <Grid item md={userId?6:12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.password.label}</Typography>
            <TextField
                    error={isValidInput(fields.password.name)}
                    name={fields.password.name}
                    value={user?user.password:""}
                    onChange={handleChange}
                    type="password"
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>}
        <Grid item md={6}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.role.label}</Typography>
            <Select
                error={isValidInput(fields.role.name)}
                value={user&&user.roles?user.roles[0]:"VENDOR_ADMIN"}
                name={fields.role.name}
                onChange={handleRoleChange}
                inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                size='small'
                fullWidth
                >
                {ROLES?.map(role => 
                    <MenuItem value={role} sx={{ backgroundColor: "primary.main" }} key={role}>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography textTransform={"capitalize"} sx={{color: "primary.contrastText", fontSize: "10pt"}}>{role.replace('_',' ').toLowerCase()}</Typography>
                        </Box>
                    </MenuItem>
                )}
            </Select>
        </Grid>
        {user && user.roles && user.roles[0] === "VENDOR_ADMIN" && <Grid item md={6}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.vendor.label}</Typography>
            <Select
                error={isValidInput(fields.vendor.name)}
                value={user?user.vendor?._id:""}
                name={fields.vendor.name}
                onChange={handleVendorChange}
                inputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                size='small'
                fullWidth
                >
                {vendors?.map(vendor => 
                    <MenuItem value={vendor._id} sx={{ backgroundColor: "primary.main" }} key={vendor._id}>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            {vendor?.config?.logo && <img src={vendor?.config?.logo} width={"20px"} style={{borderRadius: 8, marginRight: 6}}></img>}
                            <Typography sx={{color: "primary.contrastText", fontSize: "10pt"}}>{vendor.name}</Typography>
                        </Box>
                    </MenuItem>
                )}
            </Select>
        </Grid>}
    </Grid>
</Box>
}
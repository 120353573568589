export class CabochonRequirements {
    squareImages
    octoImages
    textureWidth
    octoImageHeight
    squareImageHeight
    groutLineWidth
    groutLineColor

    setSquareImages(files){
        this.squareImages = files
        return this
    }

    setOctoImages(files){
        this.octoImages = files
        return this
    }

    setSquareHeight(squareImageHeight){
        this.squareImageHeight = squareImageHeight
        return this
    }

    setOctoHeight(octoImageHeight){
        this.octoImageHeight = octoImageHeight
        return this
    }

    setTextureWidth(textureWidth){
        this.textureWidth = textureWidth
        return this
    }

    setGroutLineWidth(groutWidth){
        this.groutLineWidth = groutWidth
        return this
    }

    setGroutLineColor(color){
        this.groutLineColor = color
        return this
    }
}
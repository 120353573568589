import FadeIn from "react-fade-in/lib/FadeIn";
import { useEffect } from "react";
import * as _ from "lodash";
import { useParams } from "react-router-dom";
import { EditProduct } from "../../models/edit-product.model";
import { ProductsForSelection } from "./product-finalization.selection.form";
import { ProductsForDefault } from "./product-finalization.default.form";

interface ProductFinalizationProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

export const ProductFinalizationForm = (props: ProductFinalizationProps) => {
    const {editProduct} = props

    const { productId } = useParams()

    useEffect(() => {
        editProduct.createPossibleVariants(productId)
    }, [])

    return <FadeIn>
            {props.activeStep === 3 && <ProductsForSelection {...props} />}
            {props.activeStep === 4 && <ProductsForDefault {...props} />}
    </FadeIn>
}
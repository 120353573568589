import { ApiMethod } from "../models/api-types.type";
import AuthClientStore from "../services/auth-client.service";

const apiUrl = process.env.REACT_APP_PMS_API_URL as string;

const sendRequest = (
  method: ApiMethod,
  path: string,
  // eslint-disable-next-line
  body?: any,
  authToken?: string | null,
  init?: any,
) => {
  const isJsonBody = body && !(body instanceof FormData); // Check if the body is not FormData

  return fetch(apiUrl + path, {
    method,
    ...(body && { body: isJsonBody ? JSON.stringify(body) : body }), // Only stringify if body is JSON
    ...init,
    headers: {
      ...(isJsonBody && (!init?.headers || !init?.headers["Content-Type"]) && {
        "Content-Type": "application/json", // Add Content-Type only for JSON body
      }),
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      ...init?.headers,
    },
  }).then((response) => {
    if (response.status >= 400) {
      throw response;
    }
    if (init?.responseType === "arraybuffer") {
      return response.arrayBuffer();
    }
    return response.json();
  });
};

const sendProtectedRequest = (
  method: ApiMethod,
  path: string,
  // eslint-disable-next-line
  body?: any,
  useRefreshToken = false,
  init?: RequestInit,
) => {
  const authToken = useRefreshToken
    ? AuthClientStore.getRefreshToken()
    : AuthClientStore.getAccessToken();
  if (authToken) {
    return sendRequest(method, path, body, authToken, init);
  }
};

export const useApi = () => {
  return { sendRequest, sendProtectedRequest };
};

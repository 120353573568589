import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../redux/vendor/rootReducer";
import UploadIcon from "../../../../assets/icons/upload-icon.png"
import { Check, Close, Edit } from "@mui/icons-material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SketchPicker } from 'react-color'
import { useTranslation } from "react-i18next";
import { HexagonalRequirements } from "../models/HexagonalRequirements.model";
import { useProductsApi } from "../../../../common/hooks/use-products-api.hook";
import { setLoading } from "../../../../redux/vendor/ui/uiActions";

export const HexagonalPatternForm = ({seamlessResult, setSeamlessResult}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const hiddenFileInput = useRef(null);
    const { language } = useSelector((state: VendorState) => state.settings.theme);
    const [files, setFiles] = useState([])
    const [isOnEditGroutColor, setIsOnEditGroutColor] = useState(false)
    const productsAPI = useProductsApi()
    const dispatch = useDispatch()
    const [fields, setFields] = useState({
      L1: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.HEXAGONAL_HEIGHT')}(cm)*`,
            name: "L1",
            value: "",
        },
        L2: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.HEXAGONAL_WIDTH')}(cm)*`,
            name: "L2",
            value: "",
        },
        L3: {
            isTouched: false,
            label: `PARAM(cm)`,
            name: "L3",
            value: "",
        },
        textureWidth: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.TEXTURE_WIDTH')}(m)*`,
            name: "textureWidth",
            value: ""
        },
        textureHeight: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.TEXTURE_HEIGHT')}(m)*`,
            name: "textureHeight",
            value: ""
        },
        groutLine: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_WIDTH')}(mm)`,
            name: "groutLine",
            value: ""
        },
        groutColor: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_COLOR')}`,
            name: "groutColor",
            value: ""
        }
    })

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleFilesChange = async(event) => {
      const newFiles = event.target.files;
      setFiles([...files, ...Array.from(newFiles)])
    };
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            value: value,
            isTouched: true
        }})
    }

    const isValidStep = () => {
        return fields.L1.value && fields.L2.value && fields.textureWidth.value && fields.textureHeight.value && files.length > 0
    }

    const removeImage = (imageToRemove) => {
        setFiles(files.filter(image => image.name !== imageToRemove.name))
    }

    const handleGroutColorChange = (color) => {
      setFields({
        ...fields,
        groutColor: {
          ...fields.groutColor,
          value: color.hex
        }
      })
    }

    const handleGenerateSeamless = async () => {
      if(isValidStep()){
        dispatch(setLoading(true))
        const body = new HexagonalRequirements()
        body.setFiles(files)
            .setLengthL1(fields.L1.value)
            .setLengthL2(fields.L2.value)
            .setLengthL3(fields.L3.value === "" ?(Number.parseInt(fields.L1.value)/4).toString():fields.L3.value)
            .setTextureWidth(fields.textureWidth.value)
            .setTextureHeight(fields.textureHeight.value)
            .setGroutLineColor(fields.groutColor.value??"#FFFFFF")
            .setGroutLineWidth(fields.groutLine.value)
        let data = await productsAPI.generateSeamlessForPattern(body, "hexagonal");
        setSeamlessResult(data)
        dispatch(setLoading(false))
      }
    }

    return (
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2}} >
            <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.IMAGES')}</Typography>
            <Grid item xs={12}>
            <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={handleClick}
                  bgcolor={"primary.main"} 
                  display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                      <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                          filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                      {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.GENERIC_UPLOAD')}</Typography>}
                      {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{t('PATTERNS.PARTIALS.GENERIC_UPLOAD')}</Typography>}
                      <input
                          type="file"
                          onChange={handleFilesChange}
                          multiple
                          accept="image/*"
                          ref={hiddenFileInput}
                          style={{display: 'none'}} 
                      />
            </Box>

              <Grid container columnSpacing={2} rowSpacing={1} marginTop={1}>
                      
                  {files && files.length > 0 && files.map((image, index) => (<Grid key={index} item md={6} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <Close sx={{position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer"}} onClick={() => removeImage(image)}/>
                      <LazyLoadImage src={URL.createObjectURL(image)} style={{cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px"}} />
                  </Grid>))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sx={{backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2}} >
            <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.REQUIREMENTS')}</Typography>

            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.L1.label}</Typography>
              <TextField
                required
                error={fields.L1.isTouched && fields.L1.value === ""}
                name={fields.L1.name}
                value={fields.L1.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              />
            </Grid>


            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.L2.label}</Typography>
              <TextField
                required
                error={fields.L2.isTouched && fields.L2.value === ""}
                name={fields.L2.name}
                value={fields.L2.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              />
            </Grid>


            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.L3.label}</Typography>
              <TextField
                required
                error={fields.L3.isTouched && fields.L3.value === ""}
                name={fields.L3.name}
                value={fields.L3.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              />
            </Grid>
    
            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.textureWidth.label}</Typography>
              <Select
                error={fields.textureWidth.isTouched && fields.textureWidth.value === ""}
                name={fields.textureWidth.name}
                value={fields.textureWidth.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                inputProps={{ inputProps: { min: 1, max: 4 }, sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.textureHeight.label}</Typography>
              <Select
                error={fields.textureHeight.isTouched && fields.textureHeight.value === ""}
                name={fields.textureHeight.name}
                value={fields.textureHeight.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                inputProps={{ inputProps: { min: 1, max: 4 }, sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </Grid>
    
            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutLine.label}</Typography>
              <TextField
                error={fields.groutLine.isTouched && fields.groutLine.value === ""}
                name={fields.groutLine.name}
                value={fields.groutLine.value}
                onChange={handleChange}
                type="number"
                variant="outlined"
                color='secondary'
                fullWidth
                size='small'
                InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
              />
            </Grid>
    
            <Grid item xs={12}>
              <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutColor.label}</Typography>
              <Box bgcolor={fields.groutColor.value} padding={2} height={12} borderRadius={2} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} border={"1px solid lightgray"}>
                  <Edit sx={{backgroundColor: "primary.main", padding: 0.5, borderRadius: 20, cursor: "pointer", color: "primary.text", border: "1px solid lightgray"}} onClick={() => setIsOnEditGroutColor(true)}/>
              </Box>
              {isOnEditGroutColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <SketchPicker sx={{ width: '100%' }} color={fields.groutColor.value}
                                              onChange={handleGroutColorChange}/>
                <Box>
                    <Button variant="outlined" sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                        setIsOnEditGroutColor(false)
                        }} ><Check />
                    </Button>
                </Box>
              </Box>}
              
            </Grid>
    
            <Grid item xs={12}>
              <Box display={"flex"} alignItems={"center"} onClick={handleGenerateSeamless}
                  sx={{cursor: `${isValidStep()? "pointer":"not-allowed"}`, float: "right", bgcolor: `${isValidStep()? "primary.text":"gray"}`, paddingInline: 2, borderRadius: 2, marginTop: 2, width: "fit-content"}}>
                  <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{t('PATTERNS.PARTIALS.GENERATE')}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
};
import { Alert, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

const InactivityDetector = ({ timeoutDuration = 100000000 }) => {
  const [isActive, setIsActive] = useState(true);  // Track user's activity state
  const timeoutRef = useRef(null);                 // Reference to the timeout

  // Function to reset the inactivity timer
  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);  // Clear the existing timer
    }

    // Start a new timer to mark inactivity
    timeoutRef.current = setTimeout(() => {
      setIsActive(false);  // Set inactive after timeout duration
    }, timeoutDuration);

    setIsActive(true);  // User is active as long as events are firing
  };

  // Set up event listeners to detect user activity
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    
    // Attach the resetTimer function to each of the activity events
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Cleanup function to remove event listeners and clear timeout on unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={!isActive}
        onClose={() => setIsActive(true)}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you still here?"}
        </DialogTitle>
        <DialogActions>
          <Button variant='outlined' color="success" onClick={() => setIsActive(true)}>Yes</Button>
          <Button variant='outlined' color="success" onClick={() => setIsActive(false)} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InactivityDetector;
import { Delete, Edit } from '@mui/icons-material';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, TablePagination, Chip, TableSortLabel, useMediaQuery, Box } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpaceView } from './space-view.component';
import { Space } from '../../../vendor/my-spaces/models/space.model';

export const SpacesAsList = (props) => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [selectedSpaceIdForView, setSelectedSpaceIDForView] = useState<any>()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property) => {
        const isAsc = sortBy === property && sortOrder === 'asc';
        setSortBy(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
        const sortedSpaces = [...props.filteredSpaces].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        props.setFilteredSpaces(sortedSpaces);
    };

    return (<> {props.filteredSpaces && props.filteredSpaces.length>0 && <>
        <TableContainer component={Paper} sx={{borderRadius: 4}}>
                <Table size="small" >
                    <TableHead>
                        <TableRow  sx={{bgcolor: "primary.light"}}>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Space Name</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Size</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Surfaces</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{bgcolor: "primary.light"}}>
                        {props.filteredSpaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((space: Space, index) => (
                            <Fragment key={index}><TableRow >
                                <TableCell sx={{cursor: "pointer"}} onClick={() => {setSelectedSpaceIDForView(space.id)}}>
                                    <img src={space.thumbnailPath} width={"30px"} style={{borderRadius: 8}}></img>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{space.name}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{space.height}cm x {space.width}cm</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{space.surfaces.map(surface => 
                                        <Typography variant="caption" key={surface}>
                                            <Chip size='small' label={surface.replace('_',' ')} sx={{fontSize: "8pt", textTransform: "capitalize"}}/>
                                        </Typography>
                                    )}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Edit onClick={() => props.handleEditSpace(space)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                    <Delete onClick={() => props.onDelete(space)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                </TableCell>
                            </TableRow>
                        </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedSpaceIdForView && <SpaceView selectedSpaceForViewID={selectedSpaceIdForView} setSelectedSpaceForView={setSelectedSpaceIDForView} />}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.filteredSpaces.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>}
    </>)
}
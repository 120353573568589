import { Check, Close } from "@mui/icons-material";
import { Alert, Box, Dialog, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";
import { Size } from "../../../my-products/models/product.model";

interface SizeVariantProps {
    editProduct: EditProduct
    open: boolean
    setEditProduct
    onClose
}

export const SizeVariantDialog = (props: SizeVariantProps) => {
    const { onClose, open, editProduct, setEditProduct } = props;
    const [showNewSizeInput, setShowNewSizeInput] = useState(false)
    const [newSize, setNewSize] = useState<Size>()
    const [warningMessage, setWarningMessage] = useState<string>()
    const [activeSizes, setActiveSizes] = useState(editProduct.sizes)

    useEffect(() => {
        if(activeSizes.length === 0){
            setShowNewSizeInput(true)
        }
    }, [activeSizes])

    const handleClose = () => {
      onClose();
    };
  
    const handleSizeAdded = () => {
        if(!newSize || !newSize.width || newSize.width <= 0){
            setWarningMessage("Width can't be blank or less than 0.")
        } else if(!newSize.height || newSize.height <= 0){
            setWarningMessage("Height can't be blank or less than 0.")
        } else if(activeSizes.find(size => size.width === newSize.width && size.height === newSize.height && size.unit === newSize.unit)){
            setWarningMessage("Size already existing.")
        } else {
            setWarningMessage(undefined)
            setActiveSizes([...activeSizes, newSize])
            setShowNewSizeInput(false)
            setNewSize(undefined)
        }
    };

    const handleSizeRemoved = (size) => {
        setActiveSizes(activeSizes.filter(activeSize => !(activeSize.width === size.width && activeSize.height === size.height && activeSize.unit === size.unit)))
    }

    const handleSave = () => {
        setEditProduct(_.cloneDeep(props.editProduct.setSizes(activeSizes)))
        handleClose()
    }

    const handleCancel = () => {
        setActiveSizes(props.editProduct.sizes)
        setNewSize(undefined)
        setShowNewSizeInput(false)
        setWarningMessage(undefined)
        handleClose()
    }

    const unitBGColor = (size, unit) => {
        return size?.unit === unit ?"secondary.main":"primary.light"
    }

    const unitTXTColor = (size, unit) => {
        return size?.unit === unit ?"primary.main":"primary.text"
    }
  
    return (
      <Dialog onClose={handleClose} open={open} maxWidth={"md"} fullWidth >
            <Typography fontSize={15} color={"primary.text"} letterSpacing={-0.5} fontWeight={"bolder"} textTransform={'capitalize'} padding={2} >Default dimensions</Typography>
            <Divider />
                {activeSizes.map((size, index) => (
                    <Grid key={index} container columnSpacing={3} rowSpacing={1} paddingBlock={0.5} paddingLeft={2} bgcolor={"primary.light"}>
                        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"}>
                            <Typography fontSize={15} color={"primary.text"} paddingInline={1} letterSpacing={-0.5} textTransform={'capitalize'} flex={1}>Width</Typography>
                            <Box bgcolor={"primary.light"} padding={1} borderRadius={2}  marginRight={1} height={"35px"} display={"flex"} alignItems={"center"} flex={2} justifyContent={"space-between"}>
                                <Typography fontSize={15} color={"primary.text"} paddingInline={4} letterSpacing={-0.5} textTransform={'capitalize'} >{size.width}</Typography>
                                <InputAdornment position="end">cm</InputAdornment>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"}>
                            <Typography fontSize={15} color={"primary.text"} paddingInline={1} letterSpacing={-0.5} textTransform={'capitalize'} flex={1}>Height</Typography>
                            <Box bgcolor={"primary.light"} padding={1} borderRadius={2}  marginRight={1} height={"35px"} display={"flex"} alignItems={"center"} flex={2} justifyContent={"space-between"}>
                                <Typography fontSize={15} color={"primary.text"} paddingInline={4} letterSpacing={-0.5} textTransform={'capitalize'} >{size.height}</Typography>
                                <InputAdornment position="end">cm</InputAdornment>
                            </Box>
                        </Grid>
                        <Grid item xs={1} md={1} display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                            <Close sx={{cursor: "pointer"}} onClick={() => handleSizeRemoved(size)}/>
                        </Grid>
                    </Grid>
                ))}
                {!showNewSizeInput && <Box padding={1} borderRadius={2} paddingInline={2} sx={{cursor: "pointer"}} onClick={() => setShowNewSizeInput(true)} 
                    marginRight={1} marginBottom={1}>
                    <Typography fontSize={15} color={"secondary.main"} letterSpacing={-0.5} textTransform={'capitalize'} >+ Add new</Typography>
                </Box>}
                {showNewSizeInput && 
                    <Grid container columnSpacing={3} rowSpacing={1} paddingBlock={0.5} display={"flex"} paddingLeft={2} >
                        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} >
                            <Typography fontSize={15} color={"primary.text"} paddingInline={1} letterSpacing={-0.5} textTransform={'capitalize'} flex={1}>Width</Typography>
                            <Box bgcolor={"primary.light"} padding={1} borderRadius={2}  marginRight={1} height={"35px"} display={"flex"} alignItems={"center"} flex={2}>
                                <TextField
                                    onChange={(event) => setNewSize({
                                        ...newSize,
                                        width: Number.parseFloat(event.target.value)
                                    } as Size)}
                                    size="small"
                                    type="number"
                                    
                                    focused
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            //handleSizeAdded("width")
                                            event.preventDefault()
                                        }
                                    }}
                                    InputProps={{ 
                                        sx: {  backgroundColor: "transparent", fontSize: "15px", color: "primary.contrastText", paddingInline: 2,
                                        "& fieldset": { border: 'none' }, min: 0
                                        },
                                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                    }}
                                    
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"}>
                            <Typography fontSize={15} color={"primary.text"} paddingInline={1} letterSpacing={-0.5} textTransform={'capitalize'} flex={1}>Height</Typography>
                            <Box bgcolor={"primary.light"} padding={1} borderRadius={2}  marginRight={1} height={"35px"} display={"flex"} alignItems={"center"} flex={2}>
                                <TextField
                                    onChange={(event) => setNewSize({
                                        ...newSize,
                                        height: Number.parseFloat(event.target.value)
                                    } as Size )}
                                    size="small"
                                    type="number"
                                    focused
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                        }
                                    }}
                                    InputProps={{ 
                                        sx: {  backgroundColor: "transparent", fontSize: "15px", color: "primary.contrastText", paddingInline: 2,
                                        "& fieldset": { border: 'none', textAlign: "center" },
                                        },
                                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={1} md={2} display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                            <Check sx={{cursor: "pointer"}} onClick={() => handleSizeAdded()}/>
                        </Grid>
                    </Grid>
                }

            {warningMessage && <Alert severity="error">{warningMessage}</Alert>}

            <Box marginBlock={1} padding={1} paddingRight={4}>

                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                        <Box display={"flex"} alignItems={"center"} onClick={() => handleCancel()}
                            sx={{marginRight: 1, cursor: "pointer", bgcolor: "primary.main", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content", border: "1px solid black", borderColor: "primary.text"}}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.text"} padding={0}>Cancel</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} onClick={() => handleSave()}
                            sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>Save</Typography>
                        </Box>
                    </Box>
            </Box>
      </Dialog>
    );
}
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { useAuthContext } from "./use-auth-context.hook";
import { useVendorsApi } from "./use-vendors-api.hook";
import Config from "../../modules/vendor/settings/models/config.model";

export let areas: any[] = []
export let config: Config

export const resetConfig = () => {
  config = null
}

export const useSettingsApi = () => {
  const { sendAuthGuardedRequest } = useAuthContext();
  const vendorsAPI = useVendorsApi()
  const fonts = ["Lexend", "Roboto", "Open Sans", "Montserrat", "Lato", "Poppins", "Source Sans Pro", "Raleway", "Noto Sans", "Inter", "Roboto Slab", "Merriweather", "Playfair Display"]

  const findConfig = async (): Promise<any> => {
    const response: any = await sendAuthGuardedRequest(
      ApiMethod.GET,
      routes.settings.findConfig,
    )
    const companyConfig: Config = response
    config = companyConfig
    areas = await vendorsAPI.findVendorAreas()
    return companyConfig
  };
  
  const updateConfig = async (config): Promise<any> => {
    return await sendAuthGuardedRequest(
      ApiMethod.PATCH,
      routes.settings.patch,
      config
    )
  };

  return {
    findConfig,
    updateConfig,
    fonts
  };

};

import { Space } from "../../../modules/vendor/my-spaces/models/space.model";
import { SET_SPACES, SET_SELECTED_SPACE_FOR_EDIT, SET_MAX_SPACES } from "./spacesActionTypes";

export interface RoomsState {
    spaces: Space[],
    selectedSpace: Space,
    maxSpaces: number
}

const initialState: RoomsState = {
    spaces: [],
    selectedSpace: null,
    maxSpaces: 0
};

const spacesReducer = (state = initialState, action): RoomsState => {
    switch (action.type) {
        case SET_SPACES:
            return {
                ...state,
                spaces: action.payload
            }
        case SET_MAX_SPACES:
            return {
                ...state,
                maxSpaces: action.payload
            }
        case SET_SELECTED_SPACE_FOR_EDIT: 
            return {
                ...state,
                selectedSpace: action.payload
            }
        default:
            return state
    }
};

export default spacesReducer;
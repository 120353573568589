import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Product } from "../models/product.model";
import { useProductsApi } from "../../../../common/hooks/use-products-api.hook";


export const ProductView = ({selectedProductForViewID, setSelectedProductForView}) => {
    const [product, setProduct] = useState<Product>()
    const productsAPI = useProductsApi()
    
    useEffect(() => {
        const getProduct = async () => {
            const result = await productsAPI.findOneProduct(selectedProductForViewID)
            setProduct(result)
        }
        getProduct()
    }, [selectedProductForViewID])

    return (
        <Fragment>
            <Dialog
                onClose={() => setSelectedProductForView(null)}
                open={product !== null}
                fullScreen={true}

            >
                {product && <DialogTitle sx={{ m: 0, p: 2 }} >
                    <Typography color={"primary.text"} fontSize={20} fontWeight={"bolder"} letterSpacing={0}>
                        {product.name} - {product.color} - {product.sizeName} - {product.pattern} 
                    </Typography>
                </DialogTitle>}
                <IconButton
                aria-label="close"
                onClick={() => setSelectedProductForView(null)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <Close />
                </IconButton>
                {product && <DialogContent dividers >
                    <Grid container spacing={10}>
                        <Grid item md={6} xs={12} sm={12}>
                            <img src={product.thumbnailPath} width={"100%"} style={{borderRadius: 6}}></img>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                           <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}><Typography color={"primary.text"} fontSize={18} letterSpacing={0} >{product.description}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Brand: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.brand}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Color: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.color}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Pattern: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.pattern}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Size: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.sizeName}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Application Type: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} textTransform={"capitalize"} marginLeft={2}>{product.application_types.join(',')}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Type: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.type}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>SKU: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.sku}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Link: </Typography></td>
                                        <td><Typography color={"primary.text"} fontSize={18} letterSpacing={0} marginLeft={2}>{product.link}</Typography></td>
                                    </tr>
                                    {product.siblings && product.siblings.length > 0 &&<tr>
                                        <td><Typography color={"primary.text"} fontSize={18} fontWeight={"bolder"} letterSpacing={0}>Variants: </Typography></td>
                                        <td>
                                            <Box sx={{cursor: "pointer"}} display={"flex"} marginBlock={0} marginInline={2} >
                                                {product.siblings.map(sibling => (<Box marginRight={1} key={sibling.id} onClick={() => {setSelectedProductForView(sibling.uuid)}}>
                                                    <img src={sibling.thumbnailPath} width={"25px"} height={"25px"} style={{borderRadius: 6}}></img>
                                                </Box>))}
                                            </Box>
                                        </td>
                                    </tr>}
                                </tbody>
                           </table>

                        </Grid>
                    </Grid>
                    

                    
                   
                </DialogContent>}
            </Dialog>
        </Fragment>
    );
}
export class StackBondRequirements {
    files
    plankWidth
    plankHeight
    textureWidth
    textureHeight
    groutLineWidth
    groutLineColor

    setFiles(files){
        this.files = files
        return this
    }

    setPlankWidth(plankWidth){
        this.plankWidth = plankWidth
        return this
    }

    setPlankHeight(plankHeight){
        this.plankHeight = plankHeight
        return this
    }

    setTextureWidth(textureWidth){
        this.textureWidth = textureWidth
        return this
    }

    setTextureHeight(textureHeight){
        this.textureHeight = textureHeight
        return this
    }

    setGroutLineWidth(groutWidth){
        this.groutLineWidth = groutWidth
        return this
    }

    setGroutLineColor(color){
        this.groutLineColor = color
        return this
    }
}
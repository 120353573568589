import { Container, Stack, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled, Typography } from "@mui/material";

import Step1Icon from "../../../../../assets/icons/products/step-1-icon.png"
import Step2Icon from "../../../../../assets/icons/products/step-2-icon.png"
import Step3Icon from "../../../../../assets/icons/products/step-3-icon.png"
import Step4Icon from "../../../../../assets/icons/products/step-4-icon.png"
import { useTranslation } from "react-i18next";

export const EditProductStepper = ({steps, activeStep}) => {
    const [t] = useTranslation();


    return <Container maxWidth={"lg"} >
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                    <Step key={label} sx={{
                        ".MuiStepLabel-label": {
                        marginTop: 0.5,
                        },
                    }}>
                        <StepLabel  StepIconComponent={ColorlibStepIcon}> <Typography fontSize={14} letterSpacing={-0.5} fontWeight={activeStep === index ? "bolder": "light"} >{label}</Typography></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    </Container>
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 25,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        filter:
          theme.palette.mode === "dark"?'invert(100%) sepia(97%) saturate(11%) hue-rotate(210deg) brightness(102%) contrast(105%)':'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        filter:
        theme.palette.mode === "dark"?'invert(100%) sepia(97%) saturate(11%) hue-rotate(210deg) brightness(102%) contrast(105%)':'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: "50%",
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background: "black",
      filter:
        theme.palette.mode === "dark"?'invert(100%) sepia(97%) saturate(11%) hue-rotate(210deg) brightness(102%) contrast(105%)':'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      background: "black",
      filter:
        theme.palette.mode === "dark"?'invert(100%) sepia(97%) saturate(11%) hue-rotate(210deg) brightness(102%) contrast(105%)':'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)',
      
    }),
  }));
  
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
  
    const icons: { [index: string]: React.ReactElement } = {
      1: <img src={Step1Icon} width={"25px"} style={{ filter: "invert(100%) sepia(100%) saturate(168%) hue-rotate(139deg) brightness(109%) contrast(101%)" }}/>,
      2: <img src={Step2Icon} width={"25px"} style={{ filter: "invert(100%) sepia(100%) saturate(168%) hue-rotate(139deg) brightness(109%) contrast(101%)" }}/>,
      3: <img src={Step3Icon} width={"25px"} style={{ filter: "invert(100%) sepia(100%) saturate(168%) hue-rotate(139deg) brightness(109%) contrast(101%)" }}/>,
      4: <img src={Step4Icon} width={"25px"} style={{ filter: "invert(100%) sepia(100%) saturate(168%) hue-rotate(139deg) brightness(109%) contrast(101%)" }}/>,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  

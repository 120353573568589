import { Delete, DragIndicator, Edit, GetAppRounded, SettingsApplications, SettingsOutlined, SortByAlpha, SwapVert } from '@mui/icons-material';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, TablePagination, Chip, TableSortLabel, Box, Menu, MenuItem, Badge } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductView } from './product-view.component';
import { MaterialUISwitch } from '../../../../common/components/MUISwitch';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { VendorState } from '../../../../redux/vendor/rootReducer';
import { useSelector } from 'react-redux';

export const ProductsAsList = (props) => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedProductIdForView, setSelectedProductIDForView] = useState(null);
    const [showOnlyProducts, setShowOnlyProducts] = useState("all");
    const [displayedProductsOrder, setdisplayedProductsOrder] = useState(props.filteredProducts);
    const [anchorEl, setAnchorEl] = useState(null);

    const [isSortOngoing, setIsSortOngoing] = useState(false)
    const [isSortManual, setIsSortManual] = useState(false)

    useEffect(() => {
        setdisplayedProductsOrder(props.filteredProducts);
    }, [props.filteredProducts]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property, order = 'asc') => {
        setIsSortOngoing(true);
        setIsSortManual(false);
        setSortBy(property);
        setSortOrder(order);

        const sortedProducts = [...filteredProducts].sort((a, b) => {
            const aValue = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
            const bValue = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });

        const updatedProducts = sortedProducts.map((product, index) => ({
            ...product,
            order: index + 1
        }));

        props.setFilteredProducts(updatedProducts);
    };

    const handleManualSortStart = () => {
        setIsSortOngoing(true)
        setIsSortManual(true)
    }

    useEffect(() => {
        if (isSortOngoing) {
            setRowsPerPage(600);
            setPage(0);
        } else {
            setPage(0);
            setRowsPerPage(10)
        }
    }, [isSortOngoing])

    const handleManualSortEnd = () => {
        setIsSortManual(false)
        setIsSortOngoing(false)
        props.handleSaveManualSort(displayedProductsOrder.map(product => ({ id: product.id, data: { order: product.order } })))
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        // Create a copy of the displayedProductsOrder array
        const reorderedProducts = Array.from(displayedProductsOrder);
        const [movedProduct] = reorderedProducts.splice(result.source.index, 1);

        // Insert the moved product at the new destination index
        reorderedProducts.splice(result.destination.index, 0, movedProduct);

        // Update the order property for each product based on its new position
        const updatedProducts = reorderedProducts.map((product: any, index) => ({
            ...product,
            order: index + 1  // Set order to match the current index (starting at 1)
        }));

        // Update the state and props with the reordered list
        setdisplayedProductsOrder(updatedProducts);
        props.setFilteredProducts(updatedProducts);
    };


    const filteredProducts = showOnlyProducts === "all"
        ? displayedProductsOrder
        : showOnlyProducts === "hidden"
            ? displayedProductsOrder.filter(product => !product.isVisible)
            : displayedProductsOrder.filter(product => product.isVisible);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">{t('PAGES.MY_PRODUCTS.TITLE')}</Typography>
            </Box>
            <DragDropContext onDragEnd={handleDragEnd} >
                <Droppable droppableId="productList" >
                    {(provided) => (
                        <TableContainer
                            component={Paper}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                                borderRadius: 4,
                                maxHeight: '70vh',
                                overflowY: 'scroll',
                                '::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                '-ms-overflow-style': 'none',
                                scrollbarWidth: 'none',
                            }}
                        >
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box style={{ borderTopRightRadius: "16px" }} padding={0.5}
                                    borderBottom={"1px solid white"} sx={{ bgcolor: "primary.light" }} width={"fit-content"} display={"flex"}>
                                    <Typography variant="body2" dir='ltr' textAlign={"center"}
                                        onClick={() => setShowOnlyProducts("all")}
                                        sx={{ cursor: "pointer" }}
                                        borderRadius={2} width={"50px"} letterSpacing={-0.5} marginInline={2} marginBlock={0.5} padding={0.5} bgcolor={showOnlyProducts === "all" ? "white" : ""}>{t('PAGES.MY_PRODUCTS.ALL')}</Typography>
                                    <Typography variant="body2" dir='ltr' textAlign={"center"}
                                        onClick={() => setShowOnlyProducts("active")}
                                        sx={{ cursor: "pointer" }}
                                        borderRadius={2} width={"50px"} letterSpacing={-0.5} marginInline={2} marginBlock={0.5} padding={0.5} bgcolor={showOnlyProducts === "active" ? "white" : ""}>{t('PAGES.MY_PRODUCTS.VISBILE')}</Typography>
                                    <Typography variant="body2" dir='ltr' textAlign={"center"}
                                        onClick={() => setShowOnlyProducts("hidden")}
                                        sx={{ cursor: "pointer" }}
                                        borderRadius={2} width={"50px"} letterSpacing={-0.5} marginInline={2} marginBlock={0.5} padding={0.5} bgcolor={showOnlyProducts === "hidden" ? "white" : ""}>{t('PAGES.MY_PRODUCTS.HIDDEN')}</Typography>
                                </Box>
                                <Box borderBottom={"1px solid white"} sx={{ bgcolor: "primary.light" }} padding={0.5} style={{ borderTopLeftRadius: "16px" }}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        {isSortOngoing && <Box bgcolor={"black"} borderRadius={2} paddingInline={1} paddingBlock={0.5} marginRight={2} marginLeft={1} sx={{ cursor: "pointer" }} onClick={handleManualSortEnd}>
                                            <Typography variant='caption' color={"white"} sx={{ fontSize: "10pt", letterSpacing: -0.5 }}> Validate order </Typography>
                                        </Box>}
                                        <SwapVert sx={{ cursor: "pointer", padding: 1 }} onClick={handleMenuClick} />
                                    </Box>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                        <MenuItem onClick={() => { handleSort('name', 'asc'); handleMenuClose(); }}>         <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5 }}>By name A-Z</Typography></MenuItem>
                                        <MenuItem onClick={() => { handleSort('name', 'desc'); handleMenuClose(); }}>        <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5 }}>By name Z-A</Typography></MenuItem>
                                        <MenuItem onClick={() => { handleSort('createdAt', 'asc'); handleMenuClose(); }}>    <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5 }}>By date old to new</Typography></MenuItem>
                                        <MenuItem onClick={() => { handleSort('createdAt', 'desc'); handleMenuClose(); }}>   <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5 }}>By date new to old</Typography></MenuItem>
                                        <MenuItem onClick={() => { handleManualSortStart(); handleMenuClose(); }}>           <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5 }}>Manually</Typography></MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                            <Table size="small" sx={{ bgcolor: "primary.light" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bolder" }}>Order</TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}></TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>
                                            {t('PAGES.MY_PRODUCTS.TABLE.H_NAME')}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>
                                            {t('PAGES.MY_PRODUCTS.TABLE.H_SKU')}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>
                                            {t('PAGES.MY_PRODUCTS.TABLE.H_BRAND')}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>{t('PAGES.MY_PRODUCTS.TABLE.H_AVAILABILITY')}</TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>{t('PAGES.MY_PRODUCTS.TABLE.H_TYPE')}</TableCell>
                                        <TableCell sx={{ fontWeight: "bolder" }}>{t('PAGES.MY_PRODUCTS.TABLE.H_SIZE')}</TableCell>
                                        <TableCell sx={{ fontWeight: "bolder", width: "80px" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                                        <Draggable key={product.id} draggableId={product.id.toString()} index={index} isDragDisabled={!isSortManual}>
                                            {(provided, snapshot) => (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        bgcolor: snapshot.isDragging ? "white" : "transparent", // Set bg color when dragging
                                                        transition: "background-color 0.2s ease", // Smooth transition for bg color
                                                    }}
                                                >
                                                    <TableCell sx={{ width: "50px" }}>
                                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                            {isSortManual && <DragIndicator />}
                                                            {product.order < 0 ? "\u00A0\u00A0\u00A0" : product.order}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ width: "30px" }} onClick={() => setSelectedProductIDForView(product.id)}>
                                                        <Badge badgeContent={product.siblings.length} color="primary" overlap="circular">
                                                            <img src={product.thumbnailPath} width={"30px"} height={"30px"} style={{ borderRadius: 8 }} alt="product thumbnail" />
                                                        </Badge>
                                                        <Box display="flex" mt={1} ml={-1}>
                                                            {product.siblings.slice(0, 5).map(sibling => (
                                                                <Box marginRight={-1} key={sibling.id} sx={{ cursor: "pointer" }} onClick={() => { setSelectedProductIDForView(sibling.id) }}>
                                                                    <img src={sibling.thumbnailPath} width={"14px"} height={"14px"} style={{ borderRadius: 10, border: "1px solid white" }} alt="sibling thumbnail" />
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ width: "300px" }}>{product.name}</TableCell>
                                                    <TableCell sx={{ width: "100px" }}>{product.sku}</TableCell>
                                                    <TableCell sx={{ width: "100px" }}>{product.brand}</TableCell>
                                                    <TableCell sx={{ width: "50px" }}>
                                                        <MaterialUISwitch checked={product.isVisible} onChange={() => props.handleProductVisibilityChange(product)} />
                                                    </TableCell>
                                                    <TableCell sx={{ width: "100px" }}>
                                                        {product.application_types.map(type => (
                                                            <Chip key={type} size='small' label={t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.' + type.toUpperCase())} sx={{ fontSize: "8pt", textTransform: "capitalize" }} />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell sx={{ width: "100px" }}>{product.width}x{product.length}</TableCell>
                                                    <TableCell sx={{ width: "80px" }}>
                                                        <IconButton onClick={() => props.handleEditProduct(product)}><Edit sx={{ color: "black" }} /></IconButton>
                                                        <IconButton onClick={() => props.onDelete(product)}><Delete sx={{ color: "black" }} /></IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    )}
                </Droppable>
            </DragDropContext>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {selectedProductIdForView && <ProductView selectedProductForViewID={selectedProductIdForView} setSelectedProductForView={setSelectedProductIDForView} />}
        </>
    );
}

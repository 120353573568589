import { ArrowForwardIos } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";


export const AnalyticType = ({type}) => {
    const {analyticType} = useParams()
    const navigate = useNavigate()

    const navigateTo = (path) => {
        navigate(path, {replace: true})
    }

    return <Box marginRight={1} paddingInline={2} paddingBlock={0.5} onClick={() => navigateTo(`engagement/analytics/${type}`)}
            sx={{cursor: "pointer",
                border: "1px solid transparent",
                borderColor: analyticType === type ? "primary.text":"transparent",
                width: "150px",
                "&:hover": {
                    backgroundColor: "primary.main",
                },
                borderEndEndRadius: "16px", borderStartEndRadius: "16px"
            }} display="flex" 
            alignItems={"center"} justifyContent={"space-between"}>
        <Typography color='primary.text' textTransform="capitalize" 
            variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}>{type}</Typography>
    </Box>
}
import { useEffect, useState } from 'react';
import { Alert, Box, Button, Divider, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { theme } from "../../../theme";
import { MainHeight, MainHeightMobile, MainWidth, MainWidthMobile } from '../../../assets/constants.css';

import statPageImage from "../../../assets/login-page-bg.png";
import logo from "../../../assets/ardealy-black-logo.png";
import { setLoading } from '../../../redux/vendor/ui/uiActions';
import { useUsersApi } from '../../../common/hooks/use-users-api.hook';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const isMobileAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [mainHeight] = useState(isMobileAndDown ? MainHeightMobile : MainHeight);
  const [mainWidth] = useState(isMobileAndDown ? MainWidthMobile : MainWidth);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEmailAlreadyVerified, setEmailIsAlreadyVerified] = useState(false);
  const location = useLocation();
  const usersAPI = useUsersApi()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  useEffect(() => {
    verifyToken()
  }, [])

  const verifyToken = async() => {
    if(getQueryParam('token')){
      const success = await sendVerifyToken(getQueryParam('email'), getQueryParam('token'))
      if(!success){
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('token');
        setSearchParams(searchParams);
      } else {
        setEmailIsAlreadyVerified(true)
      }
    }
  }

  const sendVerifyToken = async (email, token) => {
    try {
      dispatch(setLoading(true)); // Optional, if you want to show a loading spinner
      const response = await usersAPI.sendVerifyToken(email, token); // Assuming your service has this method
      if (response.success) {
        setIsSuccess(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsSuccess(false);
        setErrorMessage('An error has occured! Please try again later.')
      }
      return response.success
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      dispatch(setLoading(false)); // End loading
    }
  };

  const sendVerificationEmail = async (email) => {
    try {
      dispatch(setLoading(true)); // Optional, if you want to show a loading spinner
      const response = await usersAPI.sendVerificationEmail(email); // Assuming your service has this method
      if (response.success) {
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage('Verification email has been sent. Please check your inbox.')
      } else {
        setIsError(true);
        setIsSuccess(false);
        setErrorMessage('Failed to send verification email. Please try again later.')
      }
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      dispatch(setLoading(false)); // End loading
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && !isEmailAlreadyVerified) {
      sendVerificationEmail(email);
    } 
    if (event.keyCode === 13 && isEmailAlreadyVerified && email && password) {
      usersAPI.sendSetPassword(email, password, getQueryParam('token')); 
    } 
  };

  const handleSubmit = async () => {
    if (!isEmailAlreadyVerified) {
      sendVerificationEmail(email);
    } 
    else if (email && password) {
      const data = await usersAPI.sendSetPassword(email, password, getQueryParam('token'));
      if(data){
        setIsSuccess(true)
        setIsError(false)
        setSuccessMessage('Your password has been set successfully! You can now log in.')
        dispatch(setLoading(true))
        setTimeout(() => {
          navigate('/login')
          dispatch(setLoading(false))
        }, 2000);
      } else {
        setIsSuccess(false)
        setIsError(true)
        setSuccessMessage('Failed to set password for your email. Please try again later.')
      }
    } 
  }

  return (
    <Box>
      <Grid container  justifyContent={"center"}>
        <Grid item md={6} xs={12} style={{ height: mainHeight, borderRadius: 15, userSelect: "none" }} bgcolor={"primary.main"} >
          <Box bgcolor={"white"} position={"relative"} height={"100%"} display={"flex"} flexDirection={"column"} alignItems="center" justifyContent={"space-between"} borderRadius={4} >
            <Box textAlign={"center"} marginTop={2} display={"flex"}>
              <img src={logo} style={{ width: "120px" }} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} justifyContent="center" height={"100%"} width={"75%"} marginTop={-15}>
              <Typography variant='caption' textTransform={"capitalize"} fontWeight={"bolder"} sx={{ fontSize: "20pt", letterSpacing: -0.5 }} lineHeight={1.2}>
                {isEmailAlreadyVerified && <>Email verified!</>}
                {!isEmailAlreadyVerified && <>Register now!</>}
              </Typography>
              {!isEmailAlreadyVerified && <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5 }} lineHeight={1}>
                Enter your email to create an account
              </Typography>}

              {isEmailAlreadyVerified && <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5 }} lineHeight={1}>
                Set a strong password for your account!
              </Typography>}

              <Divider sx={{ marginBlock: 4 }} />
              <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5 }}>Email </Typography>
              <TextField
                variant="outlined"
                fullWidth
                margin="none"
                size='small'
                color='secondary'
                value={email}
                inputProps={{ sx: { fontSize: "11pt", letterSpacing: -0.5 } }}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              {isEmailAlreadyVerified && <>
                <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5 }}>Password </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="none"
                  size='small'
                  color='secondary'
                  type='password'
                  value={password}
                  inputProps={{ sx: { fontSize: "11pt", letterSpacing: -0.5 } }}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </>}
              <Divider sx={{ marginBlock: 2 }} />
              <Box
                onClick={() => handleSubmit()}
                padding={1.5} color={"primary.main"} borderRadius={2} textAlign={"center"}
                sx={{ cursor: "pointer", background: "linear-gradient(126deg, rgba(0,7,128,1) 0%, rgba(33,126,232,1) 100%)" }}
              >
                {!isEmailAlreadyVerified && <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5, marginTop: 1 }}> Send verification email </Typography>}
                {isEmailAlreadyVerified && <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5, marginTop: 1 }}> Create account </Typography>}
              </Box>

              {isSuccess && <Alert severity="success" sx={{ marginTop: 2 }}>{successMessage}</Alert>}
              {isError && <Alert severity="error" sx={{ marginTop: 2 }}>{errorMessage}</Alert>}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterPage;

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#4caf50', // ON color for the track (green)
        opacity: 1,
        ...theme.applyStyles('dark', {
          backgroundColor: '#388e3c', // Dark mode ON color (darker green)
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c', // OFF color for the thumb (red)
    width: 22,
    height: 22,
    ...theme.applyStyles('dark', {
      backgroundColor: '#d32f2f', // Dark mode OFF color (darker red)
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be', // OFF color for the track (gray)
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#8796A5', // Dark mode OFF color (darker gray)
    }),
  },
}));

import { Box,  Divider, Grid, Skeleton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { EditProduct } from "../../models/edit-product.model";

interface ProductPreviewProps {
    editProduct: EditProduct
}

export const ProductPreview = (props: ProductPreviewProps) => {
    const {editProduct} = props
    const [t] = useTranslation();

    return  <Grid item md={12}>
                {editProduct && <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
                    <Typography color={"primary.text"} fontSize={14} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.PREVIEW.TITLE")}</Typography>
                    <Divider />
                    <Grid container spacing={1} >
                        <Grid item md={4} xs={12} sm={12} sx={{marginTop: 1}}>
                            {!editProduct.defaultVariantImage && <Skeleton variant="rectangular" sx={{
                                md: {
                                    width:100,
                                    height:100
                                },
                                sm: {
                                    width:100,
                                    height:100
                                },
                                xs: {
                                    width:50,
                                    height:100
                                }
                            }} />}
                            {editProduct.defaultVariantImage && <img src={editProduct.defaultVariantImage} width={100} height={100} style={{borderRadius: "8px"}}/>}
                        </Grid>
                        <Grid item md={8} xs={12}  sm={12} sx={{marginTop: 1}}>
                            {!editProduct?.brand && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                            {editProduct?.brand && <Typography color={"primary.contrastText"} fontSize={12} letterSpacing={0}>{editProduct.brand}</Typography>}

                            {!editProduct?.name && <Skeleton variant="text" sx={{ fontSize: '3rem' }} />}
                            {editProduct?.name && <Typography color={"primary.text"} fontSize={14} letterSpacing={0} fontWeight={"bolder"} marginTop={0.5}>{editProduct.name}</Typography>}

                            {!editProduct?.description && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                            {editProduct?.description && <Typography color={"primary.contrastText"} fontSize={12} letterSpacing={0} lineHeight={1.2} marginTop={0.5}>{editProduct.description}</Typography>}

                        </Grid>


                        {(editProduct.application_types.length>0) && <Grid item md={8} xs={12}  sm={12}>
                            <Typography color={"primary.text"} fontSize={12} fontWeight={"bolder"} letterSpacing={0} marginRight={0.5}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.APPLICATION')}:</Typography>
                            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                {editProduct.application_types.map((application, index) => <Box key={index} paddingInline={0.5} width={"auto"} marginRight={0.5} >
                                        <Typography color={"primary.contrastText"} textTransform={"capitalize"} fontSize={12} letterSpacing={0}>- {t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.'+application.toUpperCase())}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>}

                        {(editProduct?.sizes?.length>0) && <Grid item md={8} xs={12}  sm={12}>
                            <Typography color={"primary.text"} fontSize={12} fontWeight={"bolder"} letterSpacing={0} marginRight={0.5}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SIZE')}:</Typography>
                            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                {editProduct?.sizes.map((size, index) => <Box key={index} paddingInline={0.5} width={"auto"} marginRight={0.5} >
                                    <Typography color={"primary.contrastText"} fontSize={12} letterSpacing={0}>- {size.width}{size.unit} x {size.height}{size.unit}</Typography>
                                </Box>
                                )}
                            </Box>
                        </Grid>}

                        {(editProduct?.colors?.length>0) && <Grid item md={8} xs={12}  sm={12}>
                            <Typography color={"primary.text"} fontSize={12} fontWeight={"bolder"} letterSpacing={0} marginRight={0.5}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLOR')}:</Typography>
                            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                {editProduct?.colors.map((color, index) => <Box key={index} paddingInline={0.5} width={"auto"} marginRight={0.5} >
                                    <Typography color={"primary.contrastText"} textTransform={"capitalize"}  fontSize={12} letterSpacing={0}>- {color}</Typography>
                                </Box>
                                )}
                            </Box>
                        </Grid>}
                        {(editProduct?.patterns?.length>0) && <Grid item md={8} xs={12}  sm={12}>
                            <Typography color={"primary.text"} fontSize={12} fontWeight={"bolder"} letterSpacing={0} marginRight={0.5}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.PATTERN')}:</Typography>
                            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                {editProduct?.patterns.map((pattern, index) => <Box key={index} paddingInline={0.5} width={"auto"} marginRight={0.5} >
                                        <Typography color={"primary.contrastText"} textTransform={"capitalize"} fontSize={12} letterSpacing={0}>- {pattern}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>}
            </Grid>
}
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../redux/vendor/rootReducer";
import UploadIcon from "../../../../assets/icons/upload-icon.png"
import { Check, Close, Edit } from "@mui/icons-material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SketchPicker } from 'react-color'
import { useTranslation } from "react-i18next";
import { CabochonRequirements } from "../models/CabochonRequirements.model";
import { useProductsApi } from "../../../../common/hooks/use-products-api.hook";
import { setLoading } from "../../../../redux/vendor/ui/uiActions";

export const CabochonPatternForm = ({seamlessResult, setSeamlessResult}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const hiddenOctogonalFileInput = useRef(null);
    const hiddenSquareFileInput = useRef(null);
    const { language } = useSelector((state: VendorState) => state.settings.theme);
    const [octogonalImages, setOctogonalImages] = useState([])
    const [squareImages, setSquareImages] = useState([])
    const [isOnEditGroutColor, setIsOnEditGroutColor] = useState(false)
    const productsAPI = useProductsApi()
    const dispatch = useDispatch()

    const [fields, setFields] = useState({
        squareHeight: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.SQUARE_HEIGHT')}(cm)*`,
            name: "squareHeight",
            value: "",
        },
        octogonalHeight: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.OCTOGONAL_HEIGHT')}(cm)*`,
            name: "octogonalHeight",
            value: ""
        },
        textureWidth: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.TEXTURE_WIDTH')}(m)*`,
            name: "textureWidth",
            value: ""
        },
        groutLine: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_WIDTH')}(mm)`,
            name: "groutLine",
            value: "0"
        },
        groutColor: {
            isTouched: false,
            label: `${t('PATTERNS.PARTIALS.GROUT_LINE_COLOR')}`,
            name: "groutColor",
            value: ""
        }
    })

    const handleClick = (event, type) => {
        if(type === "square"){
            hiddenSquareFileInput.current.click();
        } else {
            hiddenOctogonalFileInput.current.click();
        }
    };

    const handleFilesChange = async(event, type) => {
        const files = event.target.files;
        if(type === "square"){
            setSquareImages([...squareImages, ...Array.from(files)])
        } else {
            setOctogonalImages([...octogonalImages, ...Array.from(files)])
        }
    };

    const handleGenerateSeamless = async () => {
        if(isValidStep()){
            dispatch(setLoading(true))
            const body = new CabochonRequirements()
            body.setGroutLineColor(fields.groutColor.value??"#FFFFFF")
                .setGroutLineWidth(fields.groutLine.value)
                .setOctoImages(octogonalImages)
                .setSquareImages(squareImages)
                .setTextureWidth(fields.textureWidth.value)
                .setOctoHeight(fields.octogonalHeight.value)
                .setSquareHeight(fields.squareHeight.value)
            let data = await productsAPI.generateSeamlessForPattern(body, "cabochon");
            setSeamlessResult(data)
            dispatch(setLoading(false))
        }
    }
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            value: value,
            isTouched: true
        }})
    }

    const isValidStep = () => {
        return fields.octogonalHeight.value && fields.squareHeight.value && fields.textureWidth.value && squareImages.length > 0 && octogonalImages.length > 0
    }

    const removeImage = (imageToRemove, type) => {
        if(type === "square"){
            setSquareImages(squareImages.filter(image => image.name !== imageToRemove.name))
        } else {
            setOctogonalImages(octogonalImages.filter(image => image.name !== imageToRemove.name))
        }
    }

    const handleGroutColorChange = (color) => {
        setFields({
        ...fields,
        groutColor: {
            ...fields.groutColor,
            value: color.hex
        }
        })
    }

    return (
      <form>
        <Grid container spacing={2}>
            <Grid container xs={12} md={4} sx={{backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2}} >
                <Grid item xs={12} md={12}>
                    <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.IMAGES')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={(event) => handleClick(event, "octogonal")}
                        bgcolor={"#9679A1"} 
                        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_OCTOGONAL')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" textAlign={"center"} color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_OCTOGONAL')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => handleFilesChange(event, "octogonal")}
                                multiple
                                accept="image/*"
                                ref={hiddenOctogonalFileInput}
                                style={{display: 'none'}} 
                            />
                    </Box>                  
                </Grid>
                <Grid container md={6} spacing={2} padding={1}>
                    {octogonalImages && octogonalImages.length > 0 && octogonalImages.map((image, index) => (<Grid key={index} item md={6} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Close sx={{position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer"}} onClick={() => removeImage(image, "octogonal")}/>
                        <LazyLoadImage src={URL.createObjectURL(image)} style={{cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px"}} />
                    </Grid>))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={(event) => handleClick(event, "square")}
                        bgcolor={"#D5C6E0"} 
                        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                            <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
                            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} textAlign="center" fontWeight="bolder" color={"primary.contrastText"}>{t('PATTERNS.PARTIALS.UPLOAD_SQUARE')}</Typography>}
                            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} textAlign="center" fontWeight="bolder" color={"primary.text"}>{t('PATTERNS.PARTIALS.UPLOAD_SQUARE')}</Typography>}
                            <input
                                type="file"
                                onChange={(event) => handleFilesChange(event, "square")}
                                multiple
                                accept="image/*"
                                ref={hiddenSquareFileInput}
                                style={{display: 'none'}} 
                            />
                    </Box>

                </Grid>

                <Grid container md={6} spacing={2} padding={1}>
                    {squareImages && squareImages.length > 0 && squareImages.map((image, index) => (<Grid key={index} item md={6} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Close sx={{position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer"}} onClick={() => removeImage(image, "square")}/>
                        <LazyLoadImage src={URL.createObjectURL(image)} style={{cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px"}} />
                    </Grid>))}
                </Grid>
                
            </Grid>
            <Grid item xs={12} md={7} sx={{backgroundColor: "primary.light", padding: 2, borderRadius: 4, margin: 2}} >
                <Typography fontSize={20} letterSpacing={-0.5} fontWeight="bolder" textTransform={"capitalize"} marginBlock={1}>{t('PATTERNS.PARTIALS.REQUIREMENTS')}</Typography>

                <Grid item xs={12}>
                    <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.octogonalHeight.label}</Typography>
                    <TextField
                    required
                    error={fields.octogonalHeight.isTouched && fields.octogonalHeight.value === ""}
                    name={fields.octogonalHeight.name}
                    value={fields.octogonalHeight.value}
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    color='secondary'
                    fullWidth
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                    />
                </Grid>
        
                <Grid item xs={12}>
                    <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.squareHeight.label}</Typography>
                    <TextField
                    required
                    error={fields.squareHeight.isTouched && fields.squareHeight.value === ""}
                    name={fields.squareHeight.name}
                    value={fields.squareHeight.value}
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    color='secondary'
                    fullWidth
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                    />
                </Grid>
        
                <Grid item xs={12}>
                    <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.textureWidth.label}</Typography>
                    <Select
                        error={fields.textureWidth.isTouched && fields.textureWidth.value === ""}
                        name={fields.textureWidth.name}
                        value={fields.textureWidth.value}
                        onChange={handleChange}
                        type="number"
                        variant="outlined"
                        color='secondary'
                        fullWidth
                        size='small'
                        inputProps={{ inputProps: { min: 1, max: 4 }, sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                    </Select>
                </Grid>

        
                <Grid item xs={12}>
                    <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutLine.label}</Typography>
                    <TextField
                    error={fields.groutLine.isTouched && fields.groutLine.value === ""}
                    name={fields.groutLine.name}
                    value={fields.groutLine.value}
                    onChange={handleChange}
                    type="number"
                    variant="outlined"
                    color='secondary'
                    fullWidth
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Typography fontSize={12} color={"primary.contrastText"} letterSpacing={0} >{fields.groutColor.label}</Typography>
                    <Box bgcolor={fields.groutColor.value} padding={2} height={12} borderRadius={2} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} border={"1px solid lightgray"}>
                        <Edit sx={{backgroundColor: "primary.main", padding: 0.5, borderRadius: 20, cursor: "pointer", color: "primary.text", border: "1px solid lightgray"}} onClick={() => setIsOnEditGroutColor(true)}/>
                    </Box>
                    {isOnEditGroutColor && <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <SketchPicker sx={{ width: '100%' }} color={fields.groutColor.value}
                                                    onChange={handleGroutColorChange}/>
                        <Box>
                            <Button variant="outlined" sx={{margin: 1, color: "primary.text", borderColor: "primary.text"}} onClick={() => {
                                setIsOnEditGroutColor(false)
                                }} ><Check />
                            </Button>
                        </Box>
                    </Box>}
                    
                </Grid>
        
                <Grid item xs={12}>
                    <Box display={"flex"} alignItems={"center"} onClick={handleGenerateSeamless}
                        sx={{cursor: `${isValidStep()? "pointer":"not-allowed"}`, bgcolor: `${isValidStep()? "primary.text":"gray"}`, float: "right", paddingInline: 2, borderRadius: 2, marginTop: 2, width: "fit-content"}}>
                        <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>{t('PATTERNS.PARTIALS.GENERATE')}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
      </form>
    );
};

import { Grid } from "@mui/material"
import { ProductPattern } from "./product-pattern.component"

import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { PRODUCT_TYPES_PATTERNS } from "../../models/types.enums"


export const ProductPatterns = (props) => {
    const [t] = useTranslation();
    const [patternList, setPatternList] = useState([])

    useEffect(() => {
        props.editProduct.application_types.forEach(application_type => {
            const selectedType = PRODUCT_TYPES_PATTERNS[application_type].find(type => type.name.toLowerCase() === props.editProduct.type.toLowerCase())
            if(selectedType)
                setPatternList(selectedType.patterns)
        })
    }, [])

    return <Grid container columnSpacing={3} rowSpacing={1}>
                {patternList.map((pattern, index) => (
                    <Grid key={index} item md={4} xs={12} >
                        <ProductPattern pattern={pattern.name}
                                        {...props}
                                        icon={pattern.icon} />
                    </Grid>
                ))}
            </Grid>
}
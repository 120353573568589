import { APPLICATION_TYPE, Product, Size } from "../../my-products/models/product.model"
import { ProductVariant } from "./variant.model"
import { v4 as uuidv4 } from 'uuid';
import * as _ from "lodash";
import { logAccess } from "../../../../common/utils/utils";
import { config } from "../../../../common/hooks/use-settings-api.hook";

export class EditProduct {
  private _name?: string;
  private _description?: string;
  private _link?: string;
  private _addToCartLink?: string;
  private _collections?: string[];
  private _brand?: string;
  private _type?: string;
  private _application_types?: APPLICATION_TYPE[];
  private _isStatic?: boolean;
  private _sizes?: Size[];
  private _colors?: string[];
  private _patterns?: string[];
  private _defaultVariantImage?: string

  private _variants?: ProductVariant[];
  private _initialVariants?: ProductVariant[];
  private _images?: any[]
  private _colorsImageType?: any

  constructor() {
    this.setApplicationTypes([APPLICATION_TYPE.FLOOR])
        .setIsStatic(true)
        .setSizes([])
        .setColors([])
        .setBrand(config?.company??"")
        .setPatterns([])
        .setImages([])
        .setVariants([])
        .setInitialVariants([])
        .setColorsImageType({})
        .setDefaultVariantImage(null)
  }

  public async mapExistingProductToEditProduct(product: Product, vendor: string) {
    (await this.setName(product.name)
      .setDescription(product.description)
      .setCollections(product.specifics?.collections??[])
      .setBrand(product.brand ? product.brand : vendor)
      .setType(product.type)
      .setLink(product.link)
      .setAddToCartLink(product.addToCartLink)
      .setApplicationTypes(product.application_types)
      .setSizes(EditProduct.getSizesFromExistingProduct(product))
      .setColors(product.color ? _.uniq([product.color, ...product.siblings?.map(sibling => sibling.color)??[]]) : ["default"])
      .setPatterns(product.pattern ? _.uniq([product.pattern.toLowerCase(), ...product.siblings?.map(sibling => sibling.pattern.toLowerCase())??[]]) : [])
      .setImages([])
      .setDefaultVariantImage(product.thumbnailPath)
      .setColorsImageType(this.colors.reduce((acc, color) => {
        acc[color] = "seamless";
        return acc;
      }, {}))      
      .prepareAndSetVariants(product))
      .setInitialVariants([...this.variants])
  }

  
  public async prepareAndSetVariants(product: Product): Promise<this> {
    let variants: any = []
    variants = [await (new ProductVariant()).mapExistingProductVariant(product, true)]
    variants = Promise.all([...variants,...product.siblings?.map(async(sibling) => await (new ProductVariant()).mapExistingProductVariant(sibling, true))??[]])
    this.setVariants(await variants)
    return this
  }

  public createPossibleVariants(productId): this{
    const variants = this.colors?.map((color, colorIndex) => {
      return this.sizes.map((size: Size, sizeIndex) => {
          return this.patterns.map((pattern, patternIndex) => {
              const initialVariant = productId ? this.getInitialVariant(color, size, pattern):null
              return (new ProductVariant()) .setId(initialVariant?initialVariant.getId():uuidv4())
                                            .setTitle(this.name)
                                            .setBrand(this.brand)
                                            .setType(this.type)
                                            .setApplicationTypes(this.application_types)
                                            .setCollections(this.collections)
                                            .setLink(this.link)
                                            .setAddToCartLink(this.addToCartLink)
                                            .setColor(color)
                                            .setSize(size.height, size.width, size.unit)
                                            .setPattern(pattern)
                                            .setIsSelected(initialVariant?true:(!productId ? true : false))
                                            .setIsDefault(initialVariant ? initialVariant.getIsDefault() : colorIndex === sizeIndex && sizeIndex === patternIndex && patternIndex === 0)
                                            .setIsSeamless(this.colorsImageType?this.colorsImageType[color] === "seamless":null)
                                            .setSku(initialVariant? initialVariant.getSku():null)
                                            .setHttpImageURL(initialVariant? initialVariant.getHttpImageURL():null)
                                            .setSeamless64URL(initialVariant? initialVariant.getSeamless64URL():null)
                                            .setThumbnail64URL(initialVariant? initialVariant.getThumbnail64URL():null)
                                            .setProductId(initialVariant?initialVariant.productId:uuidv4())
                                            .setProductUuid(initialVariant?initialVariant.productUuid:uuidv4())
                                            .setSeamlessHeight(initialVariant?initialVariant.getSeamlessHeight():null)
                                            .setSeamlessWidth(initialVariant?initialVariant.getSeamlessWidth():null)
          })
      })
    }).flat(3)
    this.setVariants(variants)
    return this
  }

  private getInitialVariant(color, size, pattern) {
    return this.initialVariants.find(variant => 
        variant.getColor().toLowerCase() === color.toLowerCase() && 
        variant.getSize().unit === size.unit && 
        variant.getSize().height === size.height && 
        variant.getSize().width === size.width && 
        variant.getPattern().toLowerCase() === pattern.toLowerCase()
    )
  }

  public getVariantDifferences(): { added: ProductVariant[], removed: ProductVariant[], modified: ProductVariant[], unchanged: ProductVariant[] } {
    const added: ProductVariant[] = [];
    const removed: ProductVariant[] = [];
    const modified: ProductVariant[] = [];
    const unchanged: ProductVariant[] = [];
  
    const initialVariantMap = new Map(this._initialVariants.map(variant => [variant.productId, variant]));
    const currentVariantMap = new Map(this._variants.filter(variant => variant.getIsSelected()).map(variant => [variant.productId, variant]));
    // Find added variants
    for (const currentVariant of this._variants.filter(variant => variant.getIsSelected())) {
      if (!initialVariantMap.has(currentVariant.productId)) {
        added.push(currentVariant);
      }
    }
  
    // Find removed variants
    for (const initialVariant of this._initialVariants) {
      if (!currentVariantMap.has(initialVariant.productId)) {
        removed.push(initialVariant);
      }
    }

    // Find modified and unchanged variants
    for (const currentVariant of this._variants.filter(variant => variant.getIsSelected())) {
      const initialVariant = initialVariantMap.get(currentVariant.productId);
      if (initialVariant) {
        if (_.isEqual(initialVariant, currentVariant)) {
          unchanged.push(currentVariant);
        } else {
          modified.push(currentVariant);
        }
      }
    }
    return { added, removed, modified, unchanged };
  }

  public getDefaultVariant(): ProductVariant | undefined {
    return this.variants.find((variant: ProductVariant) => variant.getIsDefault())
  }

  public getSelectedOnlyVariants(): ProductVariant[] {
    return this.variants.filter((variant: ProductVariant) => variant.getIsSelected())
  }

  public get colorsImageType(): any[] | undefined {
    return this._colorsImageType;
  }

  @logAccess
  public setColorsImageType(colorsImageType: any): this {
    this._colorsImageType = colorsImageType
    return this;
  }

  @logAccess
  public setColorImageType(color: string, imageType: "plain"|"seamless"): this {
    if(!this._colorsImageType)
      this._colorsImageType = {}
    this._colorsImageType[color] = imageType;
    return this;
  }

  public get images(): any[] | undefined {
    return this._images;
  }

  @logAccess
  public setImages(images: any[]): this {
    this._images = images;
    return this;
  }

  @logAccess
  public setProperty(propertyName: string, value): this{
    this[propertyName] = value
    return this
  }

  public get isStatic(): boolean | undefined {
    return this._isStatic;
  }

  @logAccess
  public setIsStatic(isStatic: boolean): this {
    this._isStatic = isStatic;
    return this;
  }

  @logAccess
  public updateIsStatic(): this {
    this._isStatic = this._application_types?.indexOf(APPLICATION_TYPE.RUGS) === -1
    return this;
  }

  @logAccess
  public toggleIsStatic(): this {
    this._isStatic = !this.isStatic
    return this.updateApplicationType()
  }

  public get name(): string | undefined {
    return this._name;
  }

  @logAccess
  public setName(name: string): this {
    this._name = name;
    return this;
  }

  public get link(): string | undefined {
    return this._link;
  }

  @logAccess
  public setLink(link: string): this {
    this._link = link;
    return this;
  }

  public get addToCartLink(): string | undefined {
    return this._addToCartLink;
  }

  @logAccess
  public setAddToCartLink(link: string): this {
    this._addToCartLink = link;
    return this;
  }

  public get collections(): string[] | undefined {
    return this._collections;
  }

  @logAccess
  public setCollections(collections: string[]): this {
    this._collections = collections;
    return this;
  }

  public get description(): string | undefined {
    return this._description;
  }

  @logAccess
  public setDescription(description: string): this {
    this._description = description;
    return this;
  }

  public get brand(): string | undefined {
    return this._brand;
  }

  @logAccess
  public setBrand(brand: string): this {
    this._brand = brand;
    return this;
  }

  public get type(): string | undefined {
    return this._type;
  }

  @logAccess
  public setType(type: string): this {
    this._type = type;
    return this;
  }

  public get application_types(): APPLICATION_TYPE[] | undefined {
    return this._application_types;
  }

  @logAccess
  public setApplicationTypes(application_types: APPLICATION_TYPE[]): this {
    this._application_types = application_types;
    this.updateIsStatic()
    return this;
  }

  @logAccess
  public toggleApplicationType(application_type: APPLICATION_TYPE): this {
    const index = this._application_types.indexOf(application_type);
    if(index !== -1){
      this._application_types = this._application_types.filter(at => at !== application_type)
    } else {
      this._application_types = [...this._application_types, application_type]
    }
    return this.updateIsStatic()
  }

  @logAccess
  private updateApplicationType(): this {
    this._application_types = !this._isStatic ? [APPLICATION_TYPE.RUGS] : [APPLICATION_TYPE.FLOOR]
    return this;
  }

  public get sizes(): Size[] | undefined {
    return this._sizes;
  }

  public hasSizes(): boolean{
    return this._sizes && this._sizes.length > 0
  }
 
  @logAccess
  public setSizes(sizes: Size[]): this {
    this._sizes = sizes;
    return this;
  }

  static getSizesFromExistingProduct = (product) => {
    const allSizes = [
        {
            width: product.width,
            height: product.length,
            unit: product.unit
        },
        ...product.siblings?.map(sibling => ({
            width: sibling.width,
            height: sibling.length,
            unit: sibling.unit
        }))??[]
    ]
    return allSizes.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.width === obj1.width) && (obj2.length === obj1.length) && (obj2.unit === obj1.unit)) === i
    )
}

  public get colors(): string[] | undefined {
    return this._colors;
  }

  public hasColors(): boolean{
    return this._colors && this._colors.length > 0
  }

  
  @logAccess
  public setColors(colors: string[]): this {
    this._colors = colors;
    return this;
  }

  public get patterns(): string[] | undefined {
    return this._patterns;
  }

  @logAccess
  public setPatterns(patterns: string[]): this {
    this._patterns = patterns;
    return this;
  }

  public get defaultVariantImage(): string | undefined {
    return this._defaultVariantImage;
  }

  @logAccess
  public setDefaultVariantImage(image: string): this {
    this._defaultVariantImage = image;
    return this;
  }

  @logAccess
  public togglePattern(pattern: string): this {
    const index = this._patterns.indexOf(pattern);
    if(index !== -1){
      this._patterns = this.patterns.filter(p => p !== pattern)
    } else {
      this._patterns = [...this._patterns, pattern]
    }
    return this;
  }

  public get variants(): ProductVariant[] | undefined {
    return this._variants;
  }

  @logAccess
  public setVariants(variants: ProductVariant[]): this {
    this._variants = variants;
    return this;
  }

  public get initialVariants(): ProductVariant[] | undefined {
    return this._initialVariants;
  }

  @logAccess
  public setInitialVariants(initialVariants: ProductVariant[]): this {
    this._initialVariants = initialVariants;
    return this;
  }
}
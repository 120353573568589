import { Box, createTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SideBar } from "../common/Header/SideBar";
import {  defaultPrimaryColor, defaultSecondaryColor } from "../theme";
import { MainHeight, MainHeightMobile, MainWidth, MainWidthMobile } from "../assets/constants.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {theme as providerTheme} from "../theme"
import { VendorState } from "../redux/vendor/rootReducer";
import Dashboard from "../pages/superadmin/dashboard/super-admin.dashboard.page";
import OurVendors from "../pages/superadmin/vendors/our-vendors.page";
import { EditVendor } from "../pages/superadmin/vendors/edit-vendor.page";
import { Users } from "../pages/superadmin/users/users.page";
import { EditUser } from "../pages/superadmin/users/edit-user.page";
import OurSpaces from "../pages/superadmin/spaces/our-spaces.page";
import { EditSpace } from "../pages/superadmin/spaces/edit-space.page";
import { NewSpace } from "../pages/superadmin/spaces/new-space.page";

export function SuperAdminPrivateRoutes() {
    const { isDrawerOpen } = useSelector((state: VendorState) => state.ui);
    const { t, i18n } = useTranslation();
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
    const [mainHeight] = useState(isMobileAndDown ? MainHeightMobile: MainHeight)
    const [mainWidth] = useState(isMobileAndDown ? MainWidthMobile: MainWidth)

    return (
        <div dir={i18n.language === "ar" ? "rtl": "ltr"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box style={{display: 'flex', backgroundColor: "#f7f8f9", height: mainHeight, maxHeight: mainHeight, borderRadius: 15, width: MainWidth}} >
                        <SideBar />
                        <Box sx={{flexGrow: 1, mainHeight, background: "white", width: MainWidth, borderTopRightRadius: 15, borderBottomRightRadius: 15, overflow: "auto", visibility: isDrawerOpen ? "hidden":"visible"}}>
                            <Routes>
                              <Route path="dashboard" element={<Dashboard />} />

                              <Route path='vendors'>
                                  <Route path='' element={<OurVendors />} /> 
                                  <Route path='new' element={<EditVendor />} /> 
                                  <Route path=':vendorId/edit' element={<EditVendor />} /> 
                              </Route>

                              <Route path='users'>
                                  <Route path='' element={<Users />} /> 
                                  <Route path='new' element={<EditUser />} /> 
                                  <Route path=':userId/edit' element={<EditUser />} /> 
                              </Route>

                              <Route path='spaces'>
                                  <Route path='' element={<OurSpaces />} /> 
                                  <Route path='new' element={<NewSpace />} /> 
                                  <Route path=':spaceId/edit' element={<EditSpace />} /> 
                              </Route>

                              <Route path="*" element={<></>} />
                              
                            </Routes>
                        </Box>
                    </Box>
            </LocalizationProvider>
        </div>
    );
}

export let theme = createTheme({
    spacing: (factor) => `${0.5 * factor}rem`,
    palette: {
      primary: {
        main: defaultPrimaryColor,
      },
      secondary: {
        main: defaultSecondaryColor,
      },
    },
    typography: {
      fontFamily: `"Helvetica", "Arial", sans-serif`
      }
  })
import { useEffect, useState } from 'react';
import { Typography,  TextField, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/components/ConfirmModal';
import { toggleConfirmModal } from '../../../redux/vendor/ui/uiActions';
import { PageContainer } from '../../../common/components/PageContainer';
import { User } from '../../../common/models/user.model';
import { UsersAsList } from '../../../modules/superadmin/users/components/users-as-list.component';
import { useUsersApi } from '../../../common/hooks/use-users-api.hook';

export const Users = () => {
    const [t] = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(displayedUsers);
    const [filter, setFilter] = useState('');
    const [modalProps, setModalProps] = useState({})
    const usersAPI = useUsersApi()

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
        const users = await usersAPI.findAllUsers()
        setDisplayedUsers(users)
    }

    const onDelete = (user) => {
        dispatch(toggleConfirmModal())
        setModalProps({
            title: "Remove user",
            description: "Do you really wan't to remove this user?",
            handleAccepted: () => handleRemoveUser(user)
        })
    }

    const handleRemoveUser = async (user) => {
        await usersAPI.findOneUserAndDelete(user._id)
        await fetchUsers()
    }

    useEffect(() => {
        setFilteredUsers(displayedUsers);
    }, [displayedUsers]);


    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilter(value);
        const filteredUsers = displayedUsers.filter(user =>
            user._id?.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            user.firstname?.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            user.lastname?.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            (user.lastname + " " + user.firstname).replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            (user.firstname + " " + user.lastname).replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            user.email.replace(/ {2,}/g, " ").toLowerCase().includes(value.toLowerCase()) ||
            user.roles.some(type => type.toLowerCase().includes(value.toLowerCase())) 
        );
        setFilteredUsers(filteredUsers);
    };

    const handleEditUser = (user: User) => {
        // dispatch(setSelectedUserForEdit(user))
        navigate(`/users/${user._id}/edit`)
    }


    return (
        <PageContainer title={"Users list"}>
                <Box display="flex">
                    <TextField
                            label={
                                <Typography variant='caption' sx={{fontSize: "10pt", letterSpacing: -0.5}}> Filter by name, email, vendor or role</Typography>
                              }
                            variant="outlined"
                            value={filter}
                            color='secondary'
                            onChange={handleFilterChange}
                            sx={{marginBottom: 2, marginRight: 2, height: "40px", fontSize: "10pt", letterSpacing: -0.5}}
                            fullWidth
                            size='small'
                        />
                </Box>
                <UsersAsList    users={displayedUsers}
                                filteredUsers={filteredUsers}
                                setFilteredUsers={setFilteredUsers}
                                handleEditUser={handleEditUser}
                                onDelete={onDelete}
                        />
                <ConfirmDialog {...modalProps} />
        </PageContainer>
    );
};


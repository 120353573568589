import dayjs from "dayjs";
import * as _ from "lodash"
import { QueryParam } from "../models/api-types.type";

export const getBase64 = (file): Promise<any> => {
  return new Promise(resolve => {
    let fileInfo;
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const imageUrlToBase64 = async (url): Promise<any> => {
  try {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve((base64data as string).split(',')[1])
      };
      reader.onerror = reject;
    });
  } catch (error) {
    return Promise.resolve()
  }

};

// Function to get date in YYYY-MM-DD format
export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Function to add missing dates with count = 0
export const fillMissingDates = (data) => {
  if (data.length === 0) return data;

  const result = [];
  let currentDate = new Date(data[0]._id);
  const endDate = new Date(data[data.length - 1]._id);

  const dateMap = data.reduce((map, item) => {
    map[item._id] = item;
    return map;
  }, {});

  while (currentDate <= endDate) {
    const formattedDate = formatDate(currentDate);
    if (dateMap[formattedDate]) {
      result.push(dateMap[formattedDate]);
    } else {
      result.push({
        "_id": formattedDate,
        "count": 0,
        "date": new Date(currentDate).toISOString()
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};

export const get30DaysAgo = () => {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 30);
  return dayjs(sevenDaysAgo)
}

export const randomHexColorCode = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

const LoggingConfig = {
  enableLogging: true,
};

export function logAccess(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    if (LoggingConfig.enableLogging) {
      const stack = new Error().stack;
      //console.log(`Calling ${propertyKey} with arguments:`, args, stack);
      const result = originalMethod.apply(this, args);
      return result;
    }
  };

  return descriptor;
}

export const base64ToBlob = (base64) => {
  if (!base64) {
    throw new Error("Invalid base64 input: The input is null or undefined.");
  }

  const prefix = "data:image/";
  if (base64.startsWith(prefix)) {
    base64 = base64.split(",")[1];
  }

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'image/jpeg' });
};

export function getDiff(obj1, obj2) {
  return _.reduce(obj1, (result, value, key) => {
    // Compare values at the same key in both objects
    if (!_.isEqual(value, obj2[key])) {
      // Add the key and the value from obj2 to the result object
      result[key] = obj2[key];
    }
    return result;
  }, {});
}

export function isValidURL(string) {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
  return regex.test(string);
}


export const buildQueryParams = (queryParams: QueryParam[]): string => {
  let queryString = "";
  queryParams.forEach((queryParam) => {
    if (queryString) {
      queryString += `&${queryParam.key}=${queryParam.value}`;
    } else {
      queryString = `?${queryParam.key}=${queryParam.value}`;
    }
  });

  return queryString;
};
export class OpusRequirements {
    imageA
    imageB
    imageC
    imageD
    textureWidth
    textureHeight
    groutLineWidth
    groutLineColor

    setImageA(file){
        this.imageA = file
        return this
    }

    setImageB(file){
        this.imageB = file
        return this
    }

    setImageC(file){
        this.imageC = file
        return this
    }

    setImageD(file){
        this.imageD = file
        return this
    }

    setTextureWidth(textureWidth){
        this.textureWidth = textureWidth
        return this
    }

    setTextureHeight(textureHeight){
        this.textureHeight = textureHeight
        return this
    }

    setGroutLineWidth(groutWidth){
        this.groutLineWidth = groutWidth
        return this
    }

    setGroutLineColor(color){
        this.groutLineColor = color
        return this
    }
}
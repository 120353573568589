import { Box, Dialog} from "@mui/material";
import { Close } from '@mui/icons-material';

export const SpaceDetails = (props) => {
    const { onClose, selectedValue, space } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };
  
    return (
      <Dialog onClose={handleClose} open={space} fullScreen>
            <Box height={"100vh"} maxWidth={"100vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Close sx={{position: "absolute", background: "white", borderRadius: "4px", padding: "2px", top: 20, right: 30, cursor: "pointer", fontSize: "40px"}} onClick={() => onClose()}/>
                <img src={space.uncroppedThumbnailPath} style={{objectFit: 'cover', maxHeight: "100vh", maxWidth: "100vw"}}/>
            </Box>
            {/* <Grid container spacing={1} flex={2} padding={2}>
                <Grid item xs={3}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"bolder"}>Space name:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"lighter"}>{space.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"bolder"}>Surfaces:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"lighter"}>{space.surfaces.map(e => <span style={{marginRight: 10}}>{e}</span>)}</Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"bolder"}>Size:</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography sx={{color: "black"}} letterSpacing={"-0.5px"} fontSize={"10pt"} fontWeight={"lighter"}>{space.width} x {space.height}</Typography>
                </Grid>
            </Grid> */}
      </Dialog>
    );
  }
import { SET_SPACES, SET_MAX_SPACES, SET_SELECTED_SPACE_FOR_EDIT } from "./spacesActionTypes";

export const setSpaces = (spaces) => {
    return {
        type: SET_SPACES,
        payload: spaces,
    };
};

export const setMaxSpaces = (max) => {
    return {
        type: SET_MAX_SPACES,
        payload: max,
    };
};

export const setSelectedSpaceForEdit = (space) => {
    return {
        type: SET_SELECTED_SPACE_FOR_EDIT,
        payload: space,
    };
};
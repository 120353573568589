import translate from "translate";
import { Box, Dialog, DialogTitle, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";

import UploadIcon from "../../../../../../assets/icons/upload-icon.png"
import { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EditProduct } from "../../../models/edit-product.model";
import { extractColors } from "extract-colors";

import * as _ from "lodash"
import { ntc } from "../../../../../../ntc";
import { VendorState } from "../../../../../../redux/vendor/rootReducer";
import { useSelector } from "react-redux";

interface ProductImagesPicker {
    editProduct: EditProduct
    setEditProduct,
    color: string
}

export const ProductImages = (props: ProductImagesPicker) => {
    const {color, editProduct, setEditProduct} = props
    const [t] = useTranslation();
    const [isOpen, setIsOpen] = useState(false)
    const [selectedImage, setSelectedImage] = useState<any>()

    const selectImage = (image) => {
        setIsOpen(true)
        setSelectedImage(image)
    }

    const closeDialog = () => {
        setIsOpen(false)
        setSelectedImage(undefined)
    }

    const removeImage = (image, color) => {
        const obj = {
            ...editProduct.images,
            [color]: editProduct.images[color].filter(img => img.name !== image.name)
        }
        if(obj[color].length === 0){
            delete obj[color]
        }
        setEditProduct(_.cloneDeep(editProduct.setImages(obj)))
    }

    const handleSetImages = (images) => {
        setEditProduct(_.cloneDeep(editProduct.setImages(images)))
    }

    return <Box>
                <UploadBox images={editProduct.images} setImages={handleSetImages} color={color} />

                <Grid container columnSpacing={2} rowSpacing={1} marginTop={1}>
                    
                    {editProduct.images && editProduct.images[color]?.length > 0 && editProduct.images[color]?.map((image, index) => (<Grid key={index} item md={6} xs={12} position={"relative"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Close sx={{position: "absolute", right: 5, bgcolor: "primary.main", borderRadius: "50%", cursor: "pointer"}} onClick={() => removeImage(image, color)}/>
                        <LazyLoadImage src={URL.createObjectURL(image)} style={{cursor: "pointer", width: "-webkit-fill-available", borderRadius: "4px"}} 
                            onClick={() => selectImage(image)}/>
                    </Grid>))}
                </Grid>

                <ImageDialog image={selectedImage} isOpen={isOpen} closeDialog={closeDialog} base64Image={null} />
            </Box>
}

export const ImageDialog = ({image, isOpen, closeDialog, base64Image}) => {

    return <Dialog open={isOpen} onClose={() => closeDialog()} fullScreen>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'fixed',
            right: 8,
            top: 8,
            color: "black",
            backgroundColor: "white"
          }}
        >
          <Close />
        </IconButton>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} overflow-x={"scroll"} height={"100vh"} width={"100vw"}>
            {image && <img src={URL.createObjectURL(image)} style={{width: "-webkit-fill-available"}}/>}
            {base64Image && <img src={`data:image/png;base64,${base64Image}`} style={{maxWidth: "100vw", maxHeight: "100vh"}}/>}
        </Box>
    </Dialog>
}

const UploadBox = ({images, setImages, color}) => {
    const theme = useTheme();
    const hiddenFileInput = useRef(null);
    
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = async(event) => {
        const files = event.target.files;
        
        setImages({
            ...images,
            [color]: images[color] ? [...images[color], ...Array.from(files)]:Array.from(files)
        });
      };

    return <Box borderRadius={2} paddingBlock={1} marginTop={1} border={"1px solid gray"} sx={{cursor: 'pointer'}} onClick={handleClick}
        bgcolor={"primary.main"} 
        display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <img src={UploadIcon} style={{height: "45px", padding: "10px", 
                filter: (theme.palette.mode === 'dark') ? "invert(100%) sepia(0%) saturate(7494%) hue-rotate(27deg) brightness(97%) contrast(106%)": ""}}/>
            {theme.palette.mode !== 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.contrastText"}>{color}</Typography>}
            {theme.palette.mode === 'dark' && <Typography fontSize={12} letterSpacing={-0.5} fontWeight="bolder" color={"primary.text"}>{color}</Typography>}
            <input
                type="file"
                onChange={handleChange}
                multiple
                accept="image/*"
                ref={hiddenFileInput}
                style={{display: 'none'}} 
            />
    </Box>
}
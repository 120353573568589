import { Box } from "@mui/material"
import { TopNavBar } from "../Header/TopNavBar"
import FadeIn from "react-fade-in/lib/FadeIn"

export const PageContainer = ({children, title}) => {
    return <Box sx={{ bgcolor: 'primary.main' }} height={"100%"}>
        <FadeIn>
            <TopNavBar title={title} />
            <Box padding={1} paddingLeft={2}>
                {children}
            </Box>
        </FadeIn>
    </Box>
}
import { Delete, Edit } from '@mui/icons-material';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, TablePagination, Chip, TableSortLabel, useMediaQuery, Box } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Vendor } from '../models/vendor.model';
import { VendorView } from './vendor-view.component';

export const VendorsAsList = (props) => {
    const [t, i18n] = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [selectedVendorIdForView, setSelectedVendorIDForView] = useState<any>()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (property) => {
        const isAsc = sortBy === property && sortOrder === 'asc';
        setSortBy(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
        const sortedVendors = [...props.filteredVendors].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        props.setFilteredVendors(sortedVendors);
    };

    return (<> {props.filteredVendors && props.filteredVendors.length>0 && <>
        <TableContainer component={Paper} sx={{borderRadius: 4}}>
                <Table size="small" >
                    <TableHead>
                        <TableRow  sx={{bgcolor: "primary.light"}}>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Name</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Alias</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Email</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Nb of products</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Nb of spaces</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}>Areas</TableCell>
                            <TableCell sx={{fontWeight: "bolder"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{bgcolor: "primary.light"}}>
                        {props.filteredVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vendor: Vendor, index) => (
                            <Fragment key={index}><TableRow >
                                <TableCell sx={{cursor: "pointer"}} onClick={() => {setSelectedVendorIDForView(vendor._id)}}>
                                    <img src={vendor.config?.logo} width={"30px"} style={{borderRadius: 8}}></img>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.name}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.alias}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.email}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.products.length}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.spaces.length}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="caption" dir='rtl' letterSpacing={-0.5}>{vendor.areas.map(type => <Typography variant="caption" key={type._id}><Chip size='small' label={type.name.replace('_',' ')} sx={{fontSize: "8pt", textTransform: "capitalize"}}/></Typography>)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Edit onClick={() => props.handleEditVendor(vendor)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                    <Delete onClick={() => props.onDelete(vendor)} style={{color: "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)", width: "20px"}}/> 
                                </TableCell>
                            </TableRow>
                        </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedVendorIdForView && <VendorView selectedVendorForViewID={selectedVendorIdForView} setSelectedVendorForView={setSelectedVendorIDForView} />}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.filteredVendors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>}
    </>)
}
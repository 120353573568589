import { Check, Close } from "@mui/icons-material";
import { Alert, Box, Dialog, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as _ from "lodash"
import { EditProduct } from "../../models/edit-product.model";

interface ColorVariantProps {
    editProduct: EditProduct
    open: boolean
    setEditProduct
    onClose
}

export const ColorsVariantDialog = (props: ColorVariantProps) => {
    const { setEditProduct, onClose, open, editProduct } = props;
    const [showNewColorInput, setShowNewColorInput] = useState(false)
    const [newColor, setNewColor] = useState<string>()
    const [warningMessage, setWarningMessage] = useState<string>()
    const [activeColors, setActiveColors] = useState(editProduct.colors)

    useEffect(() => {
        if(activeColors.length === 0){
            setShowNewColorInput(true)
        }
    }, [activeColors])

    const handleClose = () => {
      onClose();
    };
  
    const handleColorAdded = () => {
        if(newColor === ""){
            setWarningMessage("Color can't be blank.")
        }
        else if(activeColors.find(color => color.toLowerCase() === newColor.toLowerCase())){
            setWarningMessage("Color already existing in list.")
        } else {
            setWarningMessage(undefined)
            setActiveColors([...activeColors, newColor])
            setShowNewColorInput(false)
            setNewColor(undefined)
        }
    };

    const handleColorRemoved = (color) => {
        setActiveColors(activeColors.filter(activeColor => activeColor.toLowerCase() !== color.toLowerCase()))
    }

    const handleSave = () => {
        setEditProduct(_.cloneDeep(props.editProduct.setColors(activeColors)))
        handleClose()
    }

    const handleCancel = () => {
        setActiveColors(props.editProduct.colors)
        setNewColor(undefined)
        setShowNewColorInput(false)
        setWarningMessage(undefined)
        handleClose()
    }
  
    return (
      <Dialog onClose={handleClose} open={open} maxWidth={"md"} fullWidth >
            <Typography fontSize={15} color={"primary.text"} letterSpacing={-0.5} fontWeight={"bolder"} textTransform={'capitalize'} padding={2} >Colors</Typography>
            <Divider />
            <Box margin={2} display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                {activeColors.map((color, index) => (
                    <Box key={index} bgcolor={"primary.light"} padding={1} borderRadius={2}  marginRight={1} marginBottom={1} height={"35px"} display={"flex"} alignItems={"center"}>
                        <Typography fontSize={15} color={"primary.text"} paddingInline={4} letterSpacing={-0.5} textTransform={'capitalize'} >{color}</Typography>
                        <Close sx={{cursor: "pointer"}} onClick={() => handleColorRemoved(color)}/>
                    </Box>
                ))}
                {(showNewColorInput ) && 
                    <Box bgcolor={"primary.light"} borderRadius={2} paddingInline={4} padding={1} display={"flex"} alignItems={"center"} height={"35px"} 
                    marginRight={1} marginBottom={1}>
                         <TextField
                            onChange={(event) => setNewColor(event.target.value)}
                            size="small"
                            type="string"
                            focused
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleColorAdded()
                                    event.preventDefault()
                                }
                            }}
                            InputProps={{ sx: {  backgroundColor: "transparent", fontSize: "15px", color: "primary.contrastText", 
                                "& fieldset": { border: 'none' },
                             } }}
                        />
                        <Check sx={{cursor: "pointer"}} onClick={() => handleColorAdded()}/>
                    </Box>
                
               }
                {!showNewColorInput && <Box padding={1} borderRadius={2} paddingInline={2} sx={{cursor: "pointer"}} onClick={() => setShowNewColorInput(true)} 
                    marginRight={1} marginBottom={1}>
                    <Typography fontSize={15} color={"secondary.main"} letterSpacing={-0.5} textTransform={'capitalize'} >+ Add new</Typography>
                </Box>}

            </Box>
            {warningMessage && <Alert severity="error">{warningMessage}</Alert>}

            <Box marginBlock={1} padding={1} paddingRight={4}>

                <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                        <Box display={"flex"} alignItems={"center"} onClick={() => handleCancel()}
                            sx={{marginRight: 1, cursor: "pointer", bgcolor: "primary.main", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content", border: "1px solid black", borderColor: "primary.text"}}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.text"} padding={0}>Cancel</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} onClick={() => handleSave()}
                            sx={{cursor: "pointer", bgcolor: "primary.text", paddingInline: 2, borderRadius: 2, marginTop: 0.5, width: "fit-content"}}>
                            <Typography fontSize={13} letterSpacing={-0.5} fontWeight={"lighter"} marginBlock={1} color={"primary.main"} padding={0}>Save</Typography>
                        </Box>
                    </Box>
            </Box>
      </Dialog>
    );
}
import { routes } from "../../assets/constants";
import { ApiMethod } from "../models/api-types.type";
import { buildQueryParams } from "../utils/utils";
import { useAuthContext } from "./use-auth-context.hook";

export const useAnalyticsApi = () => {
    const { sendAuthGuardedRequest } = useAuthContext();

    const findAllAnalytics = async (
        limit?: number,
        offset?: number,
      ): Promise<any> => {
        const queryString = buildQueryParams([
          { key: "limit", value: limit?.toString()??"0" },
          { key: "offset", value: offset?.toString()??"600" },
        ]);
    
        const response: any = await sendAuthGuardedRequest(
          ApiMethod.GET,
          routes.analytics.findAll + queryString,
        )
        return response
    };

    const queryAnalytics = async (query): Promise<any> => {
      return await sendAuthGuardedRequest(
        ApiMethod.POST, 
        routes.analytics.search,
        query
      )
    };
    
    return { 
      findAllAnalytics,
      queryAnalytics
    };

};
  
import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { JssProvider } from "react-jss";
import { createGenerateClassName, jssPreset } from "@mui/styles";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Custom Material-UI class name generator.
const generateClassName = createGenerateClassName();

function RTL(props) {
  return (
    //@ts-ignore
    <JssProvider jss={jss} generateClassName={generateClassName}>
      {props.children}
    </JssProvider>
  );
}

export default RTL;

import { Lock, ReorderRounded } from "@mui/icons-material";
import { Divider, MenuItem, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { theme as providerTheme } from '../../../theme';

import AddProductIcon from      "../../../assets/icons/add-product-icon.png"
import AddUserIcon from         "../../../assets/icons/superadmin-dashboard-add-user.png"
import ListUsersIcon from       "../../../assets/icons/superadmin-dashboard-users.png"
import CatalogIcon from         "../../../assets/icons/catalog-icon.png"
import DashboardIcon from       "../../../assets/icons/dashboard-icon.png"
import ListSpacesIcon from       "../../../assets/icons/superadmin-dashboard-spaces.png"
import AddSpaceIcon from       "../../../assets/icons/superadmin-dashboard-add-space.png"
import { MenuCustomItem } from "../../../common/Header/MenuCustomItem";
import { MenuSectionTitle } from "../../../common/Header/SideBar";
import { toggleDrawer } from "../../../redux/vendor/ui/uiActions";

export const SuperAdminMenu = ({isDrawerOpen}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const isMobileAndDown = useMediaQuery(providerTheme.breakpoints.down('sm'));
  
    return (<Box sx={{display: "flex", flexDirection: "column", flex: 2}} >
      <Box>
        {isMobileAndDown && <MenuItem sx={{paddingLeft: 1}}>
            <ReorderRounded sx={{color: "black", paddingTop: 1, fontSize: "25pt", marginLeft: 0, paddingLeft: 0}} onClick={() => dispatch(toggleDrawer(!isDrawerOpen))}/>
          </MenuItem>
        }
        {isMobileAndDown && <Divider />}
  
        <MenuCustomItem route={"/dashboard"} title={t('SIDEBAR.DASHBOARD.TITLE')} Icon={DashboardIcon}  />
  
        {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={"Vendors"} />}
        <Box sx={{paddingLeft: isMobileAndDown? 0 : 2}}>
          <MenuCustomItem route={"/vendors"} title={"Vendors"} Icon={CatalogIcon}  />
          <MenuCustomItem route={"/vendors/new"} title={"Create Vendor"} Icon={AddProductIcon}  />
        </Box>

        {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={"Users"} />}
        <Box sx={{paddingLeft: isMobileAndDown? 0 : 2}}>
          <MenuCustomItem route={"/users"} title={"Users"} Icon={ListUsersIcon}  />
          <MenuCustomItem route={"/users/new"} title={"Create user"} Icon={AddUserIcon}  />
        </Box>

        {(!isMobileAndDown || isMobileAndDown && isDrawerOpen) && <MenuSectionTitle title={"Spaces"} />}
        <Box sx={{paddingLeft: isMobileAndDown? 0 : 2}}>
          <MenuCustomItem route={"/spaces"} title={"Spaces"} Icon={ListSpacesIcon}  />
          <MenuCustomItem route={"/spaces/new"} title={"Create space"} Icon={AddSpaceIcon}  />
        </Box>

      </Box>  
    </Box>)
  }
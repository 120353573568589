import {FC, useContext, useEffect, useState} from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginPage from '../pages/common/auth/login.page'
import { AuthContext } from '../providers/AuthProvider';
import { VendorPrivateRoutes } from './vendor.private-routes.router';
import { SuperAdminPrivateRoutes } from './super-admin.private-routes.router';
import RegisterPage from '../pages/common/auth/register.page';

const SubRouting: FC = (props: any) => {
    const { isAuthenticated, isSuperAdmin } = useContext(AuthContext);
    const location = useLocation()
    const [initialLocation, setInitialLocation] = useState("/")
    const navigate = useNavigate()

    useEffect(() => {
        setInitialLocation("/dashboard")
    }, [])

    if(isAuthenticated){
        if(isSuperAdmin) 
            return <Routes>
                <Route path="/login" element={<Navigate to={initialLocation} />} />
                <Route path="/*" element={<SuperAdminPrivateRoutes />} />
            </Routes>
        return <Routes>
            <Route path="/login" element={<Navigate to={initialLocation} />} />
            <Route path="/*" element={<VendorPrivateRoutes />} />
        </Routes>
    } else {
        return <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    }
}

const AuthRouter: FC = (props: any) => {

   return (
        <SubRouting {...props} />
   )
}

export { AuthRouter }
import { Box, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { useTranslation } from "react-i18next";


export const VendorGeneralInformation = ({vendor, setVendor, submitted}) => {
    const [t,] = useTranslation();
    const areas = ["floor", "walls", "Ceiling", "interactive_floor"]
    const [fields, setFields] = useState({
        name: {
            isTouched: false,
            label: "Name*",
            name: "name"
        },
        alias: {
            isTouched: false,
            label: "Alias*",
            name: "alias"
        },
        email: {
            isTouched: false,
            label: "Email*",
            name: "email"
        },
        storeUrl: {
            isTouched: false,
            label: "Store Url*",
            name: "storeUrl"
        },
        areas: {
            isTouched: false,
            label: "Areas*",
            name: "areas"
        }
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setVendor({ ...vendor, [name]: value })
    }

    const handleToggleArea = (area) => {
        if(vendor.areas?.find(va => va.name.toLowerCase() === area.toLowerCase()))
            setVendor({ 
                ...vendor, 
                areas: vendor.areas.filter(va => va.name.toLowerCase() !== area.toLowerCase())
            })
        else 
            setVendor({ 
                ...vendor, 
                areas: [...(vendor.areas??[]), {name: area.toLowerCase()}]
            })
    }

    const isValidInput = (name) => {
        return (submitted && !vendor[name]) || (fields && fields[name].isTouched && !vendor[name])
    }

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>{t("PAGES.NEW_PRODUCT.GENERAL_INFO.TITLE")}</Typography>
    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        <Grid item md={6} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.name.label}</Typography>
            <TextField
                    error={isValidInput(fields.name.name)}
                    name={fields.name.name}
                    value={vendor?.name || ""}
                    onChange={handleChange}
                    fullWidth
                    color="secondary"
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={6} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.alias.label}</Typography>
            <TextField
                    error={isValidInput(fields.alias.name)}
                    name={fields.alias.name}
                    value={vendor?.alias || ""}
                    onChange={handleChange}
                    fullWidth
                    color="secondary"
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.email.label}</Typography>
            <TextField
                    error={isValidInput(fields.email.name)}
                    name={fields.email.name}
                    value={vendor?.email || ""}
                    onChange={handleChange}
                    type="email"
                    fullWidth
                    color="secondary"
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0}>{fields.storeUrl.label}</Typography>
            <TextField
                    error={isValidInput(fields.storeUrl.name)}
                    name={fields.storeUrl.name}
                    value={vendor?.storeUrl || ""}
                    onChange={handleChange}
                    type="url"
                    fullWidth
                    color="secondary"
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.areas.label}</Typography>
                <Box display={"flex"}>
                {areas?.map((area, index) => (<Box key={index} bgcolor={vendor.areas?.find(e => e.name.toLowerCase() === area.toLowerCase()) ? "secondary.main":"primary.main"} paddingInline={2} 
                                    paddingBlock={1} marginInline={1} borderRadius={2} 
                                    onClick={() => handleToggleArea(area)}
                                    sx={{cursor: "pointer"}}>
                        <Typography fontSize={14} textTransform={"capitalize"} color={vendor.areas?.find(e => e.name.toLowerCase() === area.toLowerCase())?"white":"primary.text"} letterSpacing={0} >{area}</Typography>
                    </Box>)
                )}
                </Box>
        </Grid>
    </Grid>
</Box>
}
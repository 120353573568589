import { Settings } from "@mui/icons-material"
import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { ColorsVariantDialog } from "../../dialogs/step-2/colors-variant.dialog"
import { SizeVariantDialog } from "../../dialogs/step-2/sizes-variant.dialog"
import { EditProduct } from "../../models/edit-product.model"
import * as _ from "lodash"
import { useTranslation } from "react-i18next"

interface EditProductProps {
    editProduct: EditProduct
    setEditProduct
}

export const ProductVariants = (props: EditProductProps) => {
    const [t] = useTranslation();
    const [isSizeDialogOpen, setSizeDialogOpen] = useState(false)
    const [isColorDialogOpen, setColorDialogOpen] = useState(false)
    const [hasSizeChecked, setHasSizeChecked] = useState(props.editProduct.hasSizes())
    const [hasColorsChecked, setHasColorsChecked] = useState(props.editProduct.hasColors())

    const handleSizeToggle = () => {
        if(props.editProduct.hasSizes())
            props.setEditProduct(_.cloneDeep(props.editProduct.setSizes([])))
        setHasSizeChecked(!hasSizeChecked)
    }

    const handleColorToggle = () => {
        if(props.editProduct.hasColors())
            props.setEditProduct(_.cloneDeep(props.editProduct.setColors([])))
        setHasColorsChecked(!hasColorsChecked)
    }

    return <><Grid item md={12} xs={12} display={"flex"} alignItems={"center"}>
                <Box height={15} width={15} sx={{cursor: "pointer"}} bgcolor={hasSizeChecked ? "secondary.main" : "primary.main"} 
                    border={"1px solid gray"} onClick={handleSizeToggle}
                    marginRight={2}></Box>
                <Typography fontSize={13} color={"primary.text"} letterSpacing={-0.5} textTransform={'capitalize'} marginRight={2} width={50}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.SIZE')}</Typography>
                {hasSizeChecked && <Settings sx={{fontSize: "12pt", cursor: "pointer"}} onClick={() => setSizeDialogOpen(true)}/>}
                <SizeVariantDialog open={isSizeDialogOpen} onClose={() => setSizeDialogOpen(false)} {...props}/>

            </Grid>  
            
            <Grid item md={12} xs={12} display={"flex"}>
                <Box height={15} width={15} sx={{cursor: "pointer"}} bgcolor={hasColorsChecked ? "secondary.main" : "primary.main"} 
                    border={"1px solid gray"} onClick={handleColorToggle}
                    marginRight={2}></Box>
                <Typography fontSize={13} color={"primary.text"} letterSpacing={-0.5} textTransform={'capitalize'} marginRight={2} width={50}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.FIELDS.COLOR')}</Typography>
                {hasColorsChecked && <Settings sx={{fontSize: "12pt", cursor: "pointer"}} onClick={() => setColorDialogOpen(true)} />}
                <ColorsVariantDialog open={isColorDialogOpen} onClose={() => setColorDialogOpen(false)} {...props}/>
            </Grid></> 
}

import { TOGGLE_DRAWER, TOGGLE_CONFIRM_MODAL, SET_CONFIRM_MODAL_CONTENT, SET_BREADCRUMBS, SET_LOADING } from "./uiActionTypes";

export interface UIState {
    isDrawerOpen: boolean,
    confirmModal: {
        title: string,
        description: string
    }
    isConfirmModalOpen: boolean,
    breadcrumbs: Array<string>,
    isLoading: boolean
}

const initialState: UIState = {
    isDrawerOpen: false,
    isConfirmModalOpen: false,
    confirmModal: {
        title: "",
        description: ""
    },
    breadcrumbs: [],
    isLoading: false
}

const uiReducer = (state = initialState, action): UIState => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                isDrawerOpen: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case TOGGLE_CONFIRM_MODAL:
            return {
                ...state,
                isConfirmModalOpen: !state.isConfirmModalOpen
            }
        case SET_CONFIRM_MODAL_CONTENT:
            return {
                ...state,
                confirmModal: action.payload
            }
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.payload
            }
        default:
            return state
    }
};

export default uiReducer;
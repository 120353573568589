import { Box, Divider, Grid, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { SpacesAsGrid } from "../../../vendor/my-spaces/components/spaces-as-grid.component";
import { Edit } from "@mui/icons-material";
import { VendorSpacesEditModal } from "./vendor-spaces-edit.modal";

export const VendorSpaces = ({vendor, setVendor}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [fields, setFields] = useState({
        maxSpaces: {
            isTouched: false,
            label: "Max spaces",
            name: "maxSpaces"
        }
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({...fields, [name]: {
            ...fields[name],
            isTouched: true
        }})
        setVendor({ ...vendor, [name]: value })
    }

    const isValidInput = (name) => {
        return fields && fields[name].isTouched && !vendor[name]
    }

    return <Box bgcolor={"primary.light"}  padding={2} borderRadius={3} position={"relative"} display={"flex"} flexDirection={"column"} marginTop={1}>
    <Box display={"flex"} justifyContent={"space-between"}>
    <Typography fontSize={14} color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'} marginBottom={2}>Spaces</Typography>
    <Edit sx={{backgroundColor: "primary.main", borderRadius: "50%", padding: 1, marginTop: -1, hover: "pointer"}} onClick={() => setIsModalOpen(true)} />
    </Box>

    <Divider />
    <Grid container columnSpacing={3} rowSpacing={1} marginTop={1}>
        <Grid item md={12} xs={12}>
            <Typography fontSize={14} color={"primary.contrastText"} letterSpacing={0} >{fields.maxSpaces.label}</Typography>
            <TextField
                    error={isValidInput(fields.maxSpaces.name)}
                    name={fields.maxSpaces.name}
                    value={vendor?.maxSpaces??3}
                    onChange={handleChange}
                    type="number"
                    fullWidth
                    required
                    size='small'
                    InputProps={{ sx: {  backgroundColor: "primary.main", fontSize: "10pt", color: "primary.contrastText" } }}
                />
        </Grid>
        <Grid item md={12} xs={12}>
            {vendor.spaces && <SpacesAsGrid   spaces={vendor.spaces}
                        maxSpaces={vendor?.maxSpaces??3}
                        selectedSpaces={[]}
                        setSelectedSpaces={() => {}}
                        filteredSpaces={vendor.spaces} />}
        </Grid>
        {isModalOpen && <VendorSpacesEditModal vendor={vendor} setVendor={setVendor} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
    </Grid>
</Box>
}